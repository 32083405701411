import React,{ useState } from 'react';
import './../../App.css';
import swal from 'sweetalert';
import axios from 'axios';


async function loginUser(credentials) {
  return fetch('https://api-funeling.kidsbjm.com/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

export default function Login() {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const data = {
      username: username,
      password: password
    };
  
    axios.post('https://api-funeling.kidsbjm.com/api/login', data)
      .then(response => {

        swal("Success", "Berhasil Login", "success", {
          buttons: false,
          timer: 2000,
        });
        
        // window.location.reload();

        localStorage.setItem('apiUrl', "https://api-funeling.kidsbjm.com/");
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('id_cabang', response.data.id_cabang);
        localStorage.setItem('person', JSON.stringify(response.data.person));
        localStorage.setItem('rules', JSON.stringify(response.data.rules));
        if (response.data.rules == 'sales') {
          
          window.location.href = "/area/sales/list/datacustomer";

        } else if (response.data.rules == 'spv') {

          window.location.href = "/spvoverview/"+response.data.person.toUpperCase();

        } else if (response.data.rules == 'sa') {

          window.location.href = "/area/sales/list/datacustomer";

        } else if (response.data.rules == 'crc') {
          
          // window.location.href = "/area/crc/ultah";
          window.location.href = "/area/sales/list/datacustomer";

        } else {
          
          // window.location.href = "/dashboard";
          window.location.href = "/area/sales/list/datacustomer";

        }

      })
      .catch(error => {
        swal("Error", "Kombinasi username dan password salah", "error", {
          buttons: false,
          timer: 2000,
        });
      });
  }
  

  return (

    <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full">

      <div className="hidden sm:block">
        <div className="absolute top-[10%] left-[5%] flex flex-col">

          <img src="/images/toyota-light.png" className="w-[20vh] h-full object-cover" />

        </div>
        <img className="w-full h-full object-cover" src="/images/login.jpg" alt="" />
      </div>

      <div className="bg-[#F5F5F5] flex flex-col p-10">
        <h4 className="text-xl text-[#060606] font-semibold mb-10">Klik Input Database by System</h4>
        <div class="shadow p-10 dark:bg-gray-800 dark:border-gray-700"> 
          <form className="max-w-[400px] w-full mx-auto space-y-6" onSubmit={handleSubmit}>
            <img src="/images/logo-toyota-budijaya-garut.png" className="w-60 mb-10 m-0" />
            <div>
              <label for="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
              <input type="text" onChange={e => setUserName(e.target.value)} name="username" id="username" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
            </div>
            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
              <input type="password" onChange={e => setPassword(e.target.value)} name="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
            </div>
            <button type="submit" class="w-full text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login to your account</button>
            <div class="text-sm font-medium text-gray-500 dark:text-gray-300">
              Belum terdaftar? <a href="#" class="text-red-700 hover:underline dark:text-blue-500">Hubungi admin untuk pendaftaran akun</a>
            </div>
          </form>
        </div>

      </div>


    </div>

  );

}