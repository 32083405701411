import React, { useEffect, useState } from "react";
import axios from "axios";
// import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { Container } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';

const drawerWidth = 240;

const token = localStorage.getItem("access_token");
const useStyles = makeStyles((theme) => ({
  headerimage: {
    width: theme.spacing(10),
    height: theme.spacing(5),
  },
}));

function Dashboard(props) {
  const { window } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // Summary API
  const [spvMonth, setSpvMonth] = useState([]);
  const [spvDate, setSpvDate] = useState([]);
  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const getData = async () => {
      const url = `https://api-funeling.kidsbjm.com/api/summary`;
      try {
        const response = await axios.get(url);
        setSpvMonth(response.data.data.permonth);
        setSpvDate(response.data.data.percurdate);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);


  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 2,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
      }}
    >
      <Toolbar />
      <br></br>
        <Container maxWidth="xl" className="bg-white rounded-md p-2">
          <Alert icon={<CheckIcon fontSize="inherit" />}  severity="info"><Typography>Summary Bulan Ini</Typography></Alert>
          <Bar
            datasetIdKey="id"
            data={{
              labels: spvMonth.map((val)=>val.harians.nama_spv),
              datasets: [
                {
                  id: 1,
                  label: "Prospek",
                  data: spvMonth.map((val, idx) => val.harians.total),
                  backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
                {
                  id: 2,
                  label: "SPK",
                  data: spvMonth.map((val, idx) => val.spks.total),
                  backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
                {
                  id: 3,
                  label: "DEC",
                  data: spvMonth.map((val, idx) => val.dos.total),
                  backgroundColor: "rgba(76, 175, 80, 1)",
                },
              ],
            }}
          />
        </Container>
        <br></br>
        <Container maxWidth="xl" className="bg-white rounded-md p-2">
          <Alert icon={<CheckIcon fontSize="inherit" />}  severity="info"><Typography>Summary Hari Ini</Typography></Alert>
          <Bar
            datasetIdKey="id"
            data={{
              labels: spvDate.map((val) => val.harians.nama_spv),
              datasets: [
                {
                  id: 1,
                  label: "Prospek",
                  data: spvDate.map((val) => val.harians.total),
                  backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
                {
                  id: 2,
                  label: "SPK",
                  data: spvDate.map((val) => val.spks.total),
                  backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
                {
                  id: 3,
                  label: "DO",
                  data: spvDate.map((val) => val.dos.total),
                  backgroundColor: "rgba(76, 175, 80, 1)",
                },
              ],
            }}
          />
        </Container>
      
      <br></br>
    </Box>
  );
}

export default Dashboard;
