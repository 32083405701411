import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
}));

function SListDataDo(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    // API List Data Prospek
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [loadingSet, setLoadingData] = useState(false);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            setLoadingData(true);
            const url = `https://api-funeling.kidsbjm.com/api/summary/lstdo?bulan=${bulan}&tahun=${tahun}&page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                    setLoading(false);
                    setLoadingData(false);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, bulan, tahun]);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getAllData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/summary/export/tdo?bulan=${bulan}&tahun=${tahun}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setAllProspek(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getAllData();
    }, [bulan, tahun]);

    const header = [
        "Tanggal",
        "Tanggal DO",
        "No SPK",
        "SPV",
        "Sales",
        "Customer",
        "Alamat",
        "Kecamatan",
        "Kelurahan",
        "Telp",
        "Pekerjaan",
        "Nama STNK",
        "Tipe",
        "Warna",
        "Kategori Sumber",
        "Sumber Prospek",
        "Leasing",
        "Tenor",
        "Asuransi",
        "Tipe Asuransi",
        "Hobby",
        "Keterangan",
        "CR 1",
        "CR 2",
        "Cek 1000",
        "Feedback",
    ];

    const body3 = allProspek.map((data) => [
        data.tgl,
        data.tgl_do,
        data.no_spk,
        data.nama_spv,
        data.nama_sales,
        data.nama_cust,
        data.alamat,
        data.kecamatan,
        data.kelurahan,
        data.telp,
        data.pekerjaan,
        data.nama_stnk,
        data.tipe,
        data.warna,
        data.kategori_sumber,
        data.sumber,
        data.leasing,
        data.tenor,
        data.asuransi,
        data.asuransi_type,
        data.hobby,
        data.ket,
        data.crsatu,
        data.crdua,
        data.cek1000,
        data.feedback,
    ]);

    const mynamefile = "Data DEC_"+bulan+"-"+tahun+".csv";

    function handleDownloadExcel() {
        downloadExcel({
            fileName: "Data T DO",
            sheet: "T DO",
            tablePayload: {
                header,
                // accept two different data structures
                body: body3,
            },
        });
    }

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const columns = [
        { field: "tgl", headerName: "Tgl SPK", headerClassName: "headerbg" },
        { field: "tgl_do", headerName: "Tgl DO" },
        { field: "no_spk", headerName: "No.SPK", minWidth: 200 },
        { field: "nama_spv", headerName: "SPV" },
        { field: "nama_sales", headerName: "Sales", minWidth: 200 },
        { field: "nama_cust", headerName: "Customer", minWidth: 200 },
        { field: "alamat", headerName: "Alamat", minWidth: 300 },
        { field: "kecamatan", headerName: "Kec.", minWidth: 200 },
        { field: "kelurahan", headerName: "Kel.", minWidth: 200 },
        { field: "telp", headerName: "Telp", minWidth: 150 },
        { field: "pekerjaan", headerName: "Pekerjaan", minWidth: 200 },
        { field: "nama_stnk", headerName: "Nama STNK", minWidth: 200 },
        { field: "tipe", headerName: "Tipe", minWidth: 200 },
        { field: "warna", headerName: "Warna", minWidth: 150 },
        {
            field: "kategori_sumber",
            headerName: "Kategori Sumber",
            minWidth: 200,
        },
        { field: "sumber", headerName: "Sumber Prospek", minWidth: 200 },
        { field: "leasing", headerName: "Leasing", minWidth: 150 },
        { field: 'tenor', headerName: "Tenor", minWidth: 200 },
        { field: 'asuransi', headerName: "Asuransi", minWidth: 200 },
        { field: 'asuransi_type', headerName: "Tipe Asuransi", minWidth: 200 },
        { field: "hobby", headerName: "Hobby" },
        { field: "ket", headerName: "Ket.", minWidth: 200 },
        { field: "crsatu", headerName: "CR 1" },
        { field: "crdua", headerName: "CR 2" },
        { field: "cek1000", headerName: "Cek1000" },
        { field: "feedback", headerName: "Feedback" },
    ];
    //const rows = {dataProspek};
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography className="bg-white p-3 rounded-md shadow-lg">
                <b>Summary : List Data DEC</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div className="bg-white p-3 rounded-md shadow-lg mb-2">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Bulan"
                        value={bulan}
                        onChange={handleChange}
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Tahun"
                        value={tahun}
                        onChange={handleChange2}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </div>
                <CSVLink data={body3} headers={header} filename={mynamefile}>
                    <Button
                        className={classes.btnexport}
                        variant="contained"
                        color="secondary"
                    >
                        Export All
                    </Button>
                </CSVLink>
            </Box>
            <br></br>
            <Box style={{ width: "100%" }}>
                {loadingSet === true ? 
                    // <center><CircularProgress size="1.2rem" color="inherit" /></center>
                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <LinearProgress />
                    </Stack>
                :  
                    ""
                }
            </Box>
            <TableContainer style={{ height: 500, width: "100%" }} className="bg-white p-3 rounded-md shadow-lg">
                <DataGrid
                    rows={dataProspek}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>
        </Box>
    );
}

export default SListDataDo;

if (document.getElementById("slistdatado")) {
    ReactDOM.render(<SListDataDo />, document.getElementById("slistdatado"));
}
