import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import swal from 'sweetalert';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import Modal from '@mui/material/Modal';
import SpvCorongSales from "../SPV/SpvCorongSales";

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
    White: "#FFFFFF"
};


const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btntambah: {
        // marginLeft: theme.spacing(125),
        float: 'right',
        marginTop: theme.spacing(3),
    },
    inputText: {
        width: "50%"
    },

}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const rolelist = [
    {
        value: "admin",
        label: "KACAB / ADMIN",
    },
    {
        value: "spv",
        label: "SPV",
    },
    {
        value: "calon_spv",
        label: "Calon SPV",
    },
    {
        value: "pic_booking",
        label: "PIC Booking",
    },
    {
        value: "crc",
        label: "CRC",
    },
    {
        value: "mra",
        label: "MRA",
    },
    {
        value: "sales",
        label: "SALES",
    },
];

const listRole = [
    {
        value: "admin",
        label: "KACAB / ADMIN",
    },
    {
        value: "sales",
        label: "Sales",
    },
    {
        value: "spv",
        label: "SPV",
    },
    {
        value: "calon_spv",
        label: "Calon Spv",
    },
    {
        value: "pic_booking",
        label: "PIC Booking",
    },
    {
        value: "crc",
        label: "CRC",
    },
    {
        value: "mra",
        label: "MRA",
    },
    {
        value: "kabeng",
        label: "KABENG",
    },
    ,
    {
        value: "sa",
        label: "SA",
    }
];

function User(props) {
    //control modal
    const [open, setOpen] = React.useState(false);
    const [openDialogEdit, setOpen2] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleClose2 = () => setOpen2(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [setDtUser, setDataUser] = React.useState([]);

    const handleOpen = (eventValue) => {
        setOpen(true);
    };

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    const isLoading = false;

    // API List Data Prospek
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [optSales, setSales] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [refreshDt, setRefresh] = useState();
    
    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/spv`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    // response.data.data instanceof Object;
                    setSpv(response.data.data);
                // } else {
                    throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    
    //table view
    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/users?page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                // } else {
                    throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, refreshDt]);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const handleDelete = (cellDelete) => {
        //delete data
        del(cellDelete.row.id);
    };

    const columns = [
        { field: "person", headerName: "Nama Lengkap", minWidth: 200 },
        { field: "username", headerName: "Username", minWidth: 150 },
        { field: "rules", headerName: "Role", minWidth: 150 },
        {
            field: "Edit",
            renderCell: (cellEdit) => {
                return (
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={(event) => {
                            handleOpen2(cellEdit.row);
                        }}
                    >
                        Edit
                    </Button>
                );
                
            },
            minWidth: 100,
        },
        {
            field: "Delete",
            renderCell: (cellDelete) => {
                return (
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={(event) => {
                            handleDelete(cellDelete);
                        }}
                    >
                        Delete
                    </Button>
                );
                
            },
            minWidth: 120,
        },
    ];

    function del(params) {
        swal({
            title: "Apakah anda yakin akan menghapus data tersebut ?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios
                    .get(`https://api-funeling.kidsbjm.com/api/users/delete/`+params)
                    .then((response) => {
                        swal("Success", "Data Berhasil dihapus!", "success", {
                            buttons: false,
                            timer: 2000,
                        });
                        setRefresh(response);
                    });
            }
        });
    }


    
    /*update data
    function updatePost(kategori) {
        axios
          .put(`https://api-funeling.kidsbjm.com/api/sales/kategori/update`, {
            dtid : selectedId,
            kategori : kategori
          })
          .then((response) => {
            swal("Success", 'Data Berhasil diupdate!', "success", {
                buttons: false,
                timer: 2000,
              })
          });
      }

    

    /end update data */

    // CREATE DATA
    const [inputs, setInputs] = useState([]);
    const [inputsEdit, setInputsEdit] = useState([]);
    const [roleName, setRole] = React.useState("sales");
    const [spvName, setSpv] = React.useState("");
    const [dataSpv, setDataSpv] = useState([]);
    const [namaLengkap, setNoNamaLengkap] = React.useState("");
    const [userNameSet, setNouserNameSet] = React.useState("");
    const [passwordSet, setNoPassword] = React.useState("");
    const handleChangeInput = (event) => {
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
            ["roleuser"]: roleName,
        }));
    };

    const handleChangeInputEdit = (event) => {
        setInputsEdit((values) => ({
            ...values,
            [event.target.name]: event.target.value,
            ["roleuser"]: roleName,
        }));
    };
    
    const handleOpen2 = (eventValue) => {
        console.log(eventValue);
        setDataUser(eventValue);
        setNoNamaLengkap(eventValue.person);
        setNouserNameSet(eventValue.username);
        setNoPassword(eventValue.password);
        setRole(eventValue.rules);
        setSpv(eventValue.rules);
        setInputsEdit((values) => ({
            ...values,
            ["id"]: eventValue.id,
            ["namalengkap"]: eventValue.person,
            ["username"]: eventValue.username,
            ["password"]: eventValue.password,
            ["roleuser"]: eventValue.rules,
            ["spvname"]: eventValue.spvname,
            
        }));
        setOpen2(true);
    }

    const handleChangeRole = (event) => {
        setRole(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const handleChangeRoleEdit = (event) => {
        setRole(event.target.value);
        setInputsEdit(values => ({...values, [event.target.name]: event.target.value}));
    }

    const handleChangeSpv = (event) => {
        setSpv(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleChangeSpvEdit = (event) => {
        setSpv(event.target.value);
        setInputsEdit(values => ({...values, [event.target.name]: event.target.value}));
    }

    const handleChangeInputNamaLengkap = (event) => {
        setNoNamaLengkap(event.target.value);
        setInputsEdit((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const handleChangeInputuserNameSet = (event) => {
        setNouserNameSet(event.target.value);
        setInputsEdit((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const handleChangeInputPassword = (event) => {
        setNoPassword(event.target.value);
        setInputsEdit((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };


    // Get SPV
    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getDataSpv = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/users/data/getspv`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataSpv(response.data.data);
                // } else {
                    throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getDataSpv();
    }, [roleName]);

    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .post("https://api-funeling.kidsbjm.com/api/users/create", inputs)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                } else {
                    swal("Success", "Data Berhasil disimpan!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    // window.location.reload();
                    setOpen(false);
                    setRefresh(response);
                    setInputs([]);
                }
            });
    };

    const handleSubmitUpdate = (event) => {
        event.preventDefault();
        axios
            .post("https://api-funeling.kidsbjm.com/api/users/update", inputsEdit)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                } else {
                    swal("Success", "Data Berhasil disimpan!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    // window.location.reload();
                    setOpen2(false);
                    setRefresh(response);
                    setInputsEdit([]);
                }
            });
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography className="bg-white p-3 rounded-md shadow-lg mb-2">
                <b>Other : Daftar User</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div className="bg-white p-3 rounded-md shadow-lg mb-2">
                    <Button variant="contained" onClick={handleOpen} color="primary" startIcon={<AddIcon />}> Tambah User </Button> <span> </span>
                    <Button variant="contained" color="secondary"> Export All </Button>
                </div> <br/>
            </Box>
            <Box style={{}}>
                <TableContainer style={{ float: "left", height: 500, width: "100%" }} className="bg-white p-3 rounded-md shadow-lg mb-2">
                    <DataGrid
                        rows={dataProspek}
                        rowCount={rowCountState}
                        loading={isLoading}
                        components={{ Toolbar: GridToolbar }}
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        page={page}
                        pageSize={pageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => setPage(newPage)}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={columns} />
                </TableContainer>
            </Box>

            {/* CREATE DATA USER  */}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    style={{ width: "100%" }}
                >
                    <DialogTitle>{"Create New User"}</DialogTitle><br></br>
                    <DialogContent>
                        <form>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="Nama Lengkap"
                                    name="namalengkap"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Nama Lengkap"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="Username"
                                    name="username"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="username"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="Password"
                                    name="password"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    type="password"
                                    helperText="Password"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    select
                                    variant="outlined"
                                    label="Role"
                                    name="roleuser"
                                    value={roleName}
                                    onChange={handleChangeRole}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Role"
                                >
                                    {listRole.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <br/>
                                <br/>
                                {roleName == 'sales' ? <TextField
                                    id="outlined-basic"
                                    required
                                    select
                                    variant="outlined"
                                    label="SPV"
                                    name="spvname"
                                    value={spvName}
                                    onChange={handleChangeSpv}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="SPV"
                                >
                                    {dataSpv.map((option) => (
                                        <MenuItem
                                            key={option.label}
                                            value={option.label}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                : ""}
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/* EDIT DATA USER  */}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={openDialogEdit}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={handleClose2}
                    aria-describedby="alert-dialog-slide-description"
                    style={{ width: "100%" }}
                >
                    <DialogTitle>{"Create New User"}</DialogTitle><br></br>
                    <DialogContent>
                        <form>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="Nama Lengkap"
                                    name="namalengkap"
                                    value={namaLengkap}
                                    onChange={handleChangeInputNamaLengkap}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Nama Lengkap"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="Username"
                                    value={userNameSet}
                                    name="username"
                                    onChange={handleChangeInputuserNameSet}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="username"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="Password"
                                    name="password"
                                    value={passwordSet}
                                    onChange={handleChangeInputPassword}
                                    className={classes.inputText}
                                    size="small"
                                    type="password"
                                    helperText="Password"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    select
                                    variant="outlined"
                                    label="Role"
                                    name="roleuser"
                                    value={roleName}
                                    onChange={handleChangeRoleEdit}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Role"
                                >
                                    {listRole.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {roleName == 'sales' ? <TextField
                                    id="outlined-basic"
                                    required
                                    select
                                    variant="outlined"
                                    label="Role"
                                    name="spvname"
                                    value={spvName}
                                    onChange={handleChangeSpvEdit}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="SPV"
                                >
                                    {dataSpv.map((option) => (
                                        <MenuItem
                                            key={option.label}
                                            value={option.label}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                : ""}
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmitUpdate}
                        >
                            Submit
                        </Button>
                        <Button onClick={handleClose2}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
        
    );
}

export default User;

if (document.getElementById("user")) {
    ReactDOM.render(
        <User />,
        document.getElementById("user")
    );
}
