import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Links from "@mui/material/Link";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const token = localStorage.getItem("access_token");
const idCab = localStorage.getItem("id_cabang");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const kategoriList = [
    {
        value: "kategori",
        label: "Per Kategori Sumber",
    },
    {
        value: "sumber",
        label: "Per Sumber",
    }
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: bgColors.Yellow,
        color: theme.palette.common.white,
    },
}));
const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: bgColors.Red,
        color: theme.palette.common.white,
    },
}));
const StyledTableCell3 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: bgColors.Green,
        color: theme.palette.common.white,
    },
}));
const StyledTableCell4 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: bgColors.Cyan,
        color: theme.palette.common.white,
    },
}));
const StyledTableCell5 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: bgColors.Blue,
        color: theme.palette.common.white,
    },
}));

function SpvCorongSales(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [kategori, setKategori] = React.useState("kategori");
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();

    const currUrl = window.location.href;
    const segment = currUrl.split("/");
    const spvname = segment[4];

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };
    const handleChange3 = (event) => {
        setKategori(event.target.value);
    };

    // API List Data Prospek
    const [dataPeriode, setPeriode] = useState([]);
    const [dataPeriodeSuspect, setPeriodeSuspect] = useState([]);
    const [dataPeriodeProspek, setPeriodeProspek] = useState([]);
    const [dataPeriodeHot, setPeriodeHot] = useState([]);
    const [dataPeriodeSpk, setPeriodeSpk] = useState([]);
    const [dataPeriodeDo, setPeriodeDo] = useState([]);
    const [dataKategori, setDataKategori] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [optSales, setSales] = useState([]);
    const [reford, setRefOrder] = useState([]);
    const [pykec, setPykec] = useState([]);

    const listSls = optSales;
    const [slsKtg, setOptSales] = React.useState();

    const handleChangeSls = (event) => {
        setOptSales(event.target.value);
    };

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/spv/lscorongsales/${spvname}?bulan=${bulan}&tahun=${tahun}&sales=${slsKtg}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setPeriode(response.data.periode);
                    setPeriodeSuspect(response.data.dt_periode.suspect);
                    setPeriodeProspek(response.data.dt_periode.prospek);
                    setPeriodeHot(response.data.dt_periode.hot);
                    setPeriodeSpk(response.data.dt_periode.spk);
                    setPeriodeDo(response.data.dt_periode.do);
                    setDataKategori(response.data.dt_kategori);
                    setSales(response.data.listSales);
                    setRefOrder(response.data.reforder);
                    setPykec(response.data.pybrkec);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [bulan, tahun, kategori, slsKtg]);

    const header = [
        "Tanggal Booking",
        "Jam",
        "Customer",
        "Alamat",
        "Kecamatan",
        "Telp",
        "Kendaraan",
        "No Polisi",
        "No Rangka",
        "Jenis",
        "Keluhan",
        "Hoby / Aktivitas / Hal yang Disenangi",
        "Makanan Kesukaan",
        "Sales",
        "Feedback",
    ];

    const body3 = allProspek.map((data) => [
        "tgl",
        "jam",
        "customer",
        "alamat",
        "kecamatan",
        "telp",
        "kendaraan",
        "no_pol",
        "no_rangka",
        "jenis",
        "keluhan",
        "aktivitas",
        "makanan",
        "sales",
        "ket",
    ]);

    const mynamefile = "Data List Booking_" + spvname + "-" + bulan + "-" + tahun + ".csv";
    const urlExport = `https://api-funeling.kidsbjm.com/api/spv/export/corongsales/${spvname}?bulan=${bulan}&tahun=${tahun}&kategori=${kategori}&id_cabang=${idCab}&sales=${slsKtg}`;

    function handleDownloadExcel() {
        downloadExcel({
            fileName: "Data T DO",
            sheet: "T DO",
            tablePayload: {
                header,
                // accept two different data structures
                body: body3,
            },
        });
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography className="bg-white p-3 rounded-md shadow-lg">
                <b>SPV Team {decodeURI(spvname)} : Funneling Sales</b>
            </Typography>{" "}
            <br></br>
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div className="bg-white p-3 rounded-md shadow-lg mb-2">
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        defaultValue=''
                        label="Pilih Sales"
                        value={slsKtg}
                        onChange={handleChangeSls}
                        helperText="Pilih Sales"
                    >
                        {listSls.map((option) => (
                            <MenuItem  key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        value={bulan}
                        onChange={handleChange}
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        value={tahun}
                        onChange={handleChange2}
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <Links href={urlExport} underline="none">
                    <Button className={classes.btnexport} size="small" variant="contained" color="secondary" startIcon={<FileDownloadIcon />}>
                        Export Funneling
                    </Button>
                </Links>
            </Box>
            <br></br>
            <br></br>
            <Box style={{ width: "100%" }} className="bg-white p-3 rounded-md shadow-lg mb-2">
                <Typography>
                    <b>Sales : {slsKtg}</b>
                </Typography>{" "}
                <br></br>
                {reford.map((valorder) => (
                    <Typography>{valorder.sumber} : {valorder.jml}</Typography>
                ))}
            </Box>
            <br></br>
            <Box style={{ width: "100%" }} className="bg-white p-3 rounded-md shadow-lg mb-2">
                <b>Funneling Prospek {dataPeriode}</b>
            </Box>
            <Box style={{ width: "100%" }} className="bg-white p-3 rounded-md shadow-lg mb-2">
                <Table sx={{ maxWidth: 300 }}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Suspect</TableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                                {dataPeriodeSuspect.total}
                            </StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Prospek</TableCell>
                            <TableCell></TableCell>
                            <StyledTableCell2></StyledTableCell2>
                            <StyledTableCell2></StyledTableCell2>
                            <StyledTableCell2></StyledTableCell2>
                            <StyledTableCell2 style={{ textAlign: "center" }}>
                                {dataPeriodeProspek.total}
                            </StyledTableCell2>
                            <StyledTableCell2></StyledTableCell2>
                            <StyledTableCell2></StyledTableCell2>
                            <StyledTableCell2></StyledTableCell2>
                            <TableCell></TableCell>
                            <TableCell>{dataPeriodeProspek.persen}%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Hot</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <StyledTableCell3></StyledTableCell3>
                            <StyledTableCell3></StyledTableCell3>
                            <StyledTableCell3 style={{ textAlign: "center" }}>
                                {dataPeriodeHot.total}
                            </StyledTableCell3>
                            <StyledTableCell3></StyledTableCell3>
                            <StyledTableCell3></StyledTableCell3>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>{dataPeriodeHot.persen}%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>SPK</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <StyledTableCell4></StyledTableCell4>
                            <StyledTableCell4 style={{ textAlign: "center" }}>
                                {dataPeriodeSpk.total}
                            </StyledTableCell4>
                            <StyledTableCell4></StyledTableCell4>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>{dataPeriodeSpk.persen}%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>DO</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <StyledTableCell5 style={{ textAlign: "center" }}>
                                {dataPeriodeDo.total}
                            </StyledTableCell5>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>{dataPeriodeDo.persen}%</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
            <Box style={{ width: "100%" }} className="bg-white p-3 rounded-md shadow-lg mb-2">
                <br></br>
                {Object.values(dataKategori).map((item, idx) => (
                    <div key={item.kategori}>
                        <Box style={{ width: "100%" }} className="bg-white p-3 rounded-md shadow-lg mb-2">
                            <b>Funneling Prospek {item.kategori}</b>
                        </Box>
                        <Box style={{ width: "100%" }} className="bg-white p-3 rounded-md shadow-lg mb-2">
                            <Table sx={{ maxWidth: 300 }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Suspect</TableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell
                                            style={{ textAlign: "center" }}
                                        >
                                            {item.suspect.total}
                                        </StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Prospek</TableCell>
                                        <TableCell></TableCell>
                                        <StyledTableCell2></StyledTableCell2>
                                        <StyledTableCell2></StyledTableCell2>
                                        <StyledTableCell2></StyledTableCell2>
                                        <StyledTableCell2
                                            style={{ textAlign: "center" }}
                                        >
                                            {item.prospek.total}
                                        </StyledTableCell2>
                                        <StyledTableCell2></StyledTableCell2>
                                        <StyledTableCell2></StyledTableCell2>
                                        <StyledTableCell2></StyledTableCell2>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            {item.prospek.persen}%
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Hot</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <StyledTableCell3></StyledTableCell3>
                                        <StyledTableCell3></StyledTableCell3>
                                        <StyledTableCell3
                                            style={{ textAlign: "center" }}
                                        >
                                            {item.hot.total}
                                        </StyledTableCell3>
                                        <StyledTableCell3></StyledTableCell3>
                                        <StyledTableCell3></StyledTableCell3>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{item.hot.persen}%</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>SPK</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <StyledTableCell4></StyledTableCell4>
                                        <StyledTableCell4
                                            style={{ textAlign: "center" }}
                                        >
                                            {item.spk.total}
                                        </StyledTableCell4>
                                        <StyledTableCell4></StyledTableCell4>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{item.spk.persen}%</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>DO</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <StyledTableCell5
                                            style={{ textAlign: "center" }}
                                        >
                                            {item.do.total}
                                        </StyledTableCell5>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{item.do.persen}%</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                        <br></br>
                    </div>
                ))}
            </Box>
            <br></br>
            <br></br>
            <Box style={{ width: "100%" }} className="bg-white p-3 rounded-md shadow-lg mb-2">
                <b>Penyebaran Kecamatan</b>
            </Box>
            <Box style={{ width: "100%" }} className="bg-white p-3 rounded-md shadow-lg mb-2">
                <TableContainer style={{ width: "100%" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Nama Kecamatan</TableCell>
                                <TableCell align="center">Jumlah Harian</TableCell>
                                <TableCell align="center">Jumlah SPK</TableCell>
                                <TableCell align="center">Jumlah DO</TableCell>
                                <TableCell align="center">Persentase Jumlah Harian</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {pykec.map((valkec) => (
                            <TableRow key={valkec.id}>
                                <TableCell>{valkec.kecamatan}</TableCell>
                                <TableCell align="center">{valkec.jml}</TableCell>
                                <TableCell align="center">{valkec.jml_spk}</TableCell>
                                <TableCell align="center">{valkec.jml_do}</TableCell>
                                <TableCell align="center">{valkec.persentase}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}

export default SpvCorongSales;

if (document.getElementById("spvcorongsales")) {
    ReactDOM.render(
        <SpvCorongSales />,
        document.getElementById("spvcorongsales")
    );
}
