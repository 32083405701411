import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import swal from "sweetalert";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import { OpenInFullRounded } from "@mui/icons-material";

// Cards
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CakeIcon from '@mui/icons-material/Cake';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CreditScoreIcon from '@mui/icons-material/CreditScore';

const token = localStorage.getItem("access_token");
const person = JSON.parse(localStorage.getItem("person"));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    btndelete: {
        fontSize: theme.spacing(2),
    },
}));

function DataCustomer(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month + 1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [openFu, setOpenFu] = React.useState(false);
    const [openSingleID, setOpenSingleId] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const handleClose = () => setOpenFu(false);
    const [pageSize, setPageSize] = React.useState(25);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [nameCustomer, setNameCustomer] = React.useState();
    const [singleIdNo, setSingleIdNo] = React.useState();
    const [emailCust, setEmailCust] = React.useState();
    const [kecamatanCust, setKecamatanCust] = React.useState();
    const [kendaraanCust, setKendaraanCust] = React.useState();
    const [nopolCust, setNopolCust] = React.useState();
    const [noRangkaCust, setNoRangkaCust] = React.useState();

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    const handleOpenFollUp = (event) => {
        setOpenFu(true);
        setInputs(values => ({ ...values, ["id"]: event.id }));
    }

    const handleOpenSingleID = (event) => {
        setOpenSingleId(true);
        console.log(event);
        setNameCustomer(event.nama_customer);
        setSingleIdNo(event.single_id);
        setEmailCust(event.email);
        setKecamatanCust(event.kecamatan);
        setNoRangkaCust(event.no_rangka);
        setKendaraanCust(event.tipe);
        setNopolCust(event.no_pol);
        getDetailCustomer(event.no_rangka, event.nama_customer);
    }

    const [arrDetailCustomer, setArrCustomer] = React.useState([]);
    const [arrDetailKendaraan, setArrKendaraan] = React.useState([]);
    function getDetailCustomer(single_id,nama_customer) {
        axios
            .get("https://api-funeling.kidsbjm.com/api/area/tcm/find/detailCust?no_rangka="+single_id+"&nama_customer="+nama_customer)
            .then((response) => {
                console.log(response);
                setArrCustomer(response.data.data);
                setArrKendaraan(response.data.listKendaraan);
            });
    }

    // API List Data Prospek
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [refreshDt, setRefresh] = useState();

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/area/sales/datacustomer?page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                setDataProspek(response.data.data);
                setDataProspek2(response.data);
                setLoading(false);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, refreshDt]);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getAllData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/summary/export/tdo?bulan=${bulan}&tahun=${tahun}`;
            try {
                const response = await axios.get(url);
                setAllProspek(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        // getAllData();
    }, [bulan, tahun]);

    const [listUltah, setListUltah] = useState([]);
    const [totalUltah, setTotalUltah] = useState("");

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getUltah = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/list/notif_birthday`;
            try {
                const response = await axios.get(url);
                setTotalUltah(response.data.total);
                setListUltah(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getUltah();
    }, []);

    const [listStnk, setListStnk] = useState([]);
    const [totalStnk, setTotalStnk] = useState("");

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getStnk = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/list/notif_stnk`;
            try {
                const response = await axios.get(url);
                setTotalStnk(response.data.total);
                setListStnk(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getStnk();
    }, []);

    // setTimeout(function() {
    
    // }, 300000);

    const header = [
        "Tanggal",
        "Tanggal DO",
        "No SPK",
        "SPV",
        "Sales",
        "Customer",
        "Alamat",
        "Kecamatan",
        "Kelurahan",
        "Telp",
        "Pekerjaan",
        "Nama STNK",
        "Tipe",
        "Warna",
        "Kategori Sumber",
        "Sumber Prospek",
        "Leasing",
        "Asuransi",
        "Hobby",
        "Keterangan",
        "CR 1",
        "CR 2",
        "Cek 1000",
        "Feedback",
    ];

    const body3 = allProspek.map((data) => [
        data.tgl,
        data.tgl_do,
        data.no_spk,
        data.nama_spv,
        data.nama_sales,
        data.nama_cust,
        data.alamat,
        data.kecamatan,
        data.kelurahan,
        data.telp,
        data.pekerjaan,
        data.nama_stnk,
        data.tipe,
        data.warna,
        data.kategori_sumber,
        data.sumber,
        data.leasing,
        data.asuransi,
        data.hobby,
        data.ket,
        data.crsatu,
        data.crdua,
        data.cek1000,
        data.feedback,
    ]);

    const mynamefile = "List Bengkel_" + bulan + "-" + tahun + ".csv";

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const columns = [
        {
            field: "Kendaraan Dimiliki",
            renderCell: (cellEdit) => {
                return (
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={(event) => {
                            handleOpenSingleID(cellEdit.row);
                        }}
                    >
                        List
                    </Button>
                );
            },
            minWidth: 200,
        },
        { field: "single_id", headerName: "Single ID", minWidth: 250 },
        { field: "no_rangka", headerName: "No Rangka", minWidth: 250 },
        { field: "nama_customer", headerName: "Customer", minWidth: 250 },
        { field: "nama_stnk", headerName: "Nama STNK", minWidth: 250 },
        { field: "alamat", headerName: "Alamat", minWidth: 250 },
        { field: "kecamatan", headerName: "Kecamatan", minWidth: 250 },
        { field: "telp", headerName: "Telepon", minWidth: 250 },
        { field: "email", headerName: "Email", minWidth: 250 },
        { field: "tipe", headerName: "Kendaraan", minWidth: 250 },
        { field: "asuransi", headerName: "Asuransi", minWidth: 250 },
        { field: "asuransi_type", headerName: "Tipe Asuransi", minWidth: 250 },
        { field: "ket", headerName: "Keterangan", minWidth: 250 },
    ];

    const [inputs, setInputs] = useState([]);
    const handleChangeInput = (event) => {
        setInputs(values => ({ ...values, [event.target.name]: event.target.value }));
    }

    const [loadButton, setLoadButton] = React.useState(false);
    const handleSubmit = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios.post('https://api-funeling.kidsbjm.com/api/area/sales/followup', inputs).then(function (response) {
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
                setRefresh(response);
                setOpenFu(false);
            }
        });
    };

    const hariIni = new Date();
    const tanggal = hariIni.getDate();
    const bulanHariIni = hariIni.getMonth() + 1; // Perlu ditambah 1 karena indeks bulan dimulai dari 0
    const tahunHariIni = hariIni.getFullYear();
    const namaBulan = [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
        'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];

    // Format tanggal dengan format "DD/MM/YYYY"
    const tanggalFormat = tanggal + ' ' + namaBulan[hariIni.getMonth()] + ' ' + tahunHariIni;

    const [openListBirth, setOpenListBirth] = useState(false);
    const setOpenReminderBirth = () => {
        setOpenListBirth(true);
    }

    const [openListStnk, setOpenListStnk] = useState(false);
    const setOpenReminderStnk = () => {
        setOpenListStnk(true);
    }
    
    const [openHistoryService, setOpenHistoryService] = useState(false);
    const [openHistoryKendaraan, setOpenHistoryKendaraan] = useState(false);
    const handleHistoryService = (no_single) => {
        setOpenHistoryService(true);
        getHistoryService(no_single)
    }

    const handleHistoryKendaraan = (rangka_no) => {
        setOpenHistoryKendaraan(true);
        getHistoryKendaraan(rangka_no)
    }

    const [arrDetailHistory, setArrHistory] = React.useState([]);
    function getHistoryService(single_id) {
        axios
            .get("https://api-funeling.kidsbjm.com/api/area/tcm/find/history?no_rangka="+single_id)
            .then((response) => {
                console.log(response);
                setArrHistory(response.data.data);
            });
    }

    const [arrDetailHistoryKendaraan, setArrHistoryKendaraan] = React.useState([]);
    const [arrOwner, setArrOwner] = React.useState([]);
    function getHistoryKendaraan(rangka_no) {
        axios
            .get("https://api-funeling.kidsbjm.com/api/area/tcm/find/historyKendaraan?no_rangka="+rangka_no)
            .then((response) => {
                console.log(response);
                setArrHistoryKendaraan(response.data.cust_prev);
                setArrOwner(response.data.curr_cust);
            });
    }

    const handleCloseHistoryService = () => {
        setOpenHistoryService(false);
    }

    const handleCloseHistoryKendaraan = () => {
        setOpenHistoryKendaraan(false);
    }

    const handleCloseSingleID = () => {
        setOpenSingleId(false);
        setOpenHistoryService(false);
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />

            <Box style={{ width: "100%" }}>
                <Card sx={{  float:"left", width: "48%", margin: "0 auto", borderTop: "1px solid #e0e0e0", marginBottom: 2 }} className="shadow-md">
                    <CardHeader
                        avatar={
                        <Avatar sx={{ bgcolor: "#EA2027" }} aria-label="recipe">
                            <CakeIcon />
                        </Avatar>
                        }
                        action={
                            <IconButton aria-label="settings" onClick={setOpenReminderBirth}>
                                <ManageSearchIcon />
                            </IconButton>
                        }
                        title="Reminder Ulang Tahun"
                        subheader={tanggalFormat}
                    />
                    <CardContent>
                        <Typography
                            sx={{ fontSize: 14 }}
                        >
                            <b>{totalUltah + " Customer"}</b>
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{  float:"left", width: "51%", margin: "0 auto", marginLeft: "5px", borderTop: "1px solid #e0e0e0", marginBottom: 2 }}>
                    <CardHeader
                        avatar={
                        <Avatar sx={{ bgcolor: "#1289A7" }} aria-label="recipe">
                            <CreditScoreIcon />
                        </Avatar>
                        }
                        action={
                        <IconButton aria-label="settings" onClick={setOpenReminderStnk}>
                            <ManageSearchIcon />
                        </IconButton>
                        }
                        title="Reminder STNK"
                        subheader={tanggalFormat}
                    />
                    <CardContent>
                        <Typography
                            sx={{ fontSize: 14 }}
                        >
                            <b>{totalStnk + " Customer"}</b>
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
            <br></br>
            <TableContainer style={{ height: 500, width: "100%" }} className="bg-white p-3 rounded-md shadow-lg mb-2">
                <DataGrid
                    rows={dataProspek}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>
            {/* Input Lokasi */}
            <Box style={{ width: "100%" }}>

                <form onSubmit={handleSubmit}>
                    <Dialog
                        open={openFu}
                        TransitionComponent={Transition}
                        keepMounted
                        maxWidth="md"
                        fullWidth={fullWidth}
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        style={{ width: "100%" }}
                    >
                        <DialogTitle
                            style={{
                                backgroundColor: bgColors.Yellow,
                                marginBottom: "10px",
                                color: "#ffffff",
                            }}
                        >
                            {"Follow Up"}
                        </DialogTitle>
                        <DialogContent>
                            <br></br>
                            <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="followup"
                                    label="Follow Up"
                                    onChange={handleChangeInput}
                                    sx={{ width: "100%" }}
                                    size="small"
                                    helperText="Follow Up"
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            {loadButton === true ?
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                >
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                                :
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmit}
                                >
                                    <Typography>Submit</Typography>
                                </Button>
                            }
                            <Button onClick={handleClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </form>

            </Box>
            
            {/* List Kendaraan */}
            <Box>
                <Dialog
                    open={openSingleID}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    style={{ width: "80%", margin: "0 auto" }}
                >
                    <DialogContent style={{
                        borderLeft: "5px solid #c23616",
                        background: "#ecf0f1"
                        // borderRight: "5px solid #c23616",
                    }}>
                        <br></br>
                        <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                            <Chip
                                label={"SINGLE ID: " + singleIdNo}  
                                style={{
                                    fontWeight: "bold",
                                    borderRadius: "0px",
                                    background: "#e74c3c",
                                    color: "#ffffff"
                                }}
                            />
                            <Box
                                style={{
                                    borderRadius: "0px",
                                    background: "#ffffff",
                                    width: "100%",
                                    color: "#34495e",
                                    fontSize: "16px",
                                    marginBottom: "5px"
                                }}
                                className="p-2"
                            >
                                <span style={{ fontWeight: "bold" }}>{nameCustomer}</span><br></br><br></br>
                                <Typography style={{ fontSize: "14px" }}>
                                    {"List Kendaraan yang Dimiliki"}
                                </Typography>
                                {arrDetailKendaraan.map((option) => (
                                    <>
                                        <Chip
                                            key={option.id}
                                            label={option.tipe + " (" + option.no_pol + ") "}
                                            style={{
                                                fontWeight: "bold",
                                                borderRadius: "0px",
                                                marginBottom: "10px",
                                                background: "#00a8ff",
                                                color: "white",
                                                marginLeft: "1px"
                                            }}
                                        />
                                    </>
                                ))}<br></br>
                                <Button variant="outlined" color="primary" size="small" onClick={() => handleHistoryKendaraan(noRangkaCust)}>
                                    History Kendaraan
                                </Button>
                                <Button variant="outlined" color="primary" size="small" style={{ marginLeft: "3px" }} onClick={() => handleHistoryService(noRangkaCust)}>
                                    History Service
                                </Button>
                            </Box>

                            {openHistoryService == true ? (
                                <Box
                                    style={{
                                        borderRadius: "0px",
                                        background: "#ffffff",
                                        width: "100%",
                                        color: "#34495e",
                                        fontSize: "16px",
                                        marginBottom: "5px"
                                    }}
                                    className="p-2"
                                >

                                    <Typography style={{ fontSize: "14px", marginBottom: "5px" }} className="bg-red-600 p-2 text-white">
                                        {"History Services"}
                                    </Typography>

                                    {arrDetailHistory.map((option) => (
                                        <>
                                            <Chip
                                                key={option.id}
                                                label={option.tipe + " (" + option.no_pol + ") "}
                                                style={{
                                                    fontWeight: "bold",
                                                    borderRadius: "0px",
                                                    marginBottom: "10px",
                                                    background: "#00a8ff",
                                                    color: "white",
                                                    marginLeft: "1px"
                                                }}
                                            />
                                            <Chip
                                                key={option.id}
                                                label={"Nama STNK: "+option.nama_stnk}
                                                style={{
                                                    fontWeight: "bold",
                                                    borderRadius: "0px",
                                                    marginBottom: "10px",
                                                    background: "#00a8ff",
                                                    color: "white",
                                                    marginLeft: "1px"
                                                }}
                                            /><br></br>
                                            <table>
                                                <tr>
                                                    <td>{"Nama Pemakai"}</td>
                                                    <td>:</td>
                                                    <td>{option.nama_pemakai_kendaraan}</td>
                                                </tr>
                                                <tr>
                                                    <td>{"Tanggal Service"}</td>
                                                    <td>:</td>
                                                    <td>{option.tgl_service}</td>
                                                </tr>
                                                <tr>
                                                    <td>{"Keterangan Service"}</td>
                                                    <td>:</td>
                                                    <td>{option.keterangan_service}</td>
                                                </tr>
                                            </table>
                                        </>
                                    ))}<br></br>
                                    <Button variant="outlined" color="secondary" size="small" onClick={handleCloseHistoryService}>
                                        Close
                                    </Button>
                                </Box>
                            ) : (
                                <Typography></Typography>
                            )}

                            {openHistoryKendaraan == true ? (
                                <Box
                                    style={{
                                        borderRadius: "0px",
                                        background: "#ffffff",
                                        width: "100%",
                                        color: "#34495e",
                                        fontSize: "16px",
                                        marginBottom: "5px"
                                    }}
                                    className="p-2"
                                >

                                    <Typography style={{ fontSize: "14px", marginBottom: "5px" }} className="bg-red-600 p-2 text-white">
                                        {"History Kendaraan"}
                                    </Typography>

                                    <Typography style={{background: "#e74c3c"}} className="p-3 text-white">
                                        {"Pemilik sebelumnya"}
                                    </Typography>
                                    {arrDetailHistoryKendaraan.map((option) => (
                                        <div key={option.id}>
                                            <Chip
                                                label={"Nama Customer"}
                                                style={{
                                                    fontWeight: "bold",
                                                    borderRadius: "0px",
                                                    marginBottom: "10px",
                                                    background: "#00a8ff",
                                                    color: "white",
                                                    marginLeft: "1px"
                                                }}
                                            />
                                            <Chip
                                                label={option.nama_customer}
                                                style={{
                                                    fontWeight: "bold",
                                                    borderRadius: "0px",
                                                    marginBottom: "10px",
                                                    background: "transparent",
                                                    border: "1px solid #00a8ff",
                                                    color: "black"
                                                }}
                                            />
                                        </div>
                                    ))}<br></br>
                                    <Typography style={{background: "#e74c3c"}} className="p-3 text-white">
                                        {"Pemilik Saat Ini (Owner)"}
                                    </Typography>
                                    {arrOwner.map((option) => (
                                        <div key={option.id}>
                                            <Chip
                                                label={"Nama Customer"}
                                                style={{
                                                    fontWeight: "bold",
                                                    borderRadius: "0px",
                                                    marginBottom: "10px",
                                                    background: "#00a8ff",
                                                    color: "white",
                                                    marginLeft: "1px"
                                                }}
                                            />
                                            <Chip
                                                label={option.nama_customer}
                                                style={{
                                                    fontWeight: "bold",
                                                    borderRadius: "0px",
                                                    marginBottom: "10px",
                                                    background: "transparent",
                                                    border: "1px solid #00a8ff",
                                                    color: "black"
                                                }}
                                            />
                                        </div>
                                    ))}<br></br>
                                    <Button variant="outlined" color="secondary" size="small" onClick={handleCloseHistoryKendaraan}>
                                        Close
                                    </Button>
                                </Box>
                            ) : (
                                <Typography></Typography>
                            )}
                        </Box>
                        <br></br>
                    </DialogContent>
                    <DialogActions style={{
                        borderLeft: "5px solid #c23616",
                        background: "#ecf0f1"
                    }}>
                        <Button onClick={handleCloseSingleID}>OK</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/* List Ulang Tahun */}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={openListBirth}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={setOpenListBirth}
                    aria-describedby="alert-dialog-slide-description"
                    style={{ width: "50%", margin: "0 auto" }}
                >
                    <DialogContent style={{
                        borderLeft: "5px solid #c23616",
                    }}>
                        <br></br>
                        {"Berikut ini merupakan list customer yang berulang tahun hari ini:"}
                        <br></br>
                        <br></br>
                        {listUltah.map((option) => (
                            <>
                                <Box style={{ width: "100%", marginBottom: "5px" }}>
                                    <Chip
                                        label={option.single_id}
                                        // color="success"
                                        style={{
                                            fontWeight: "bold",
                                            borderRadius: "0px",
                                            marginBottom: "10px",
                                            background: "#00a8ff",
                                            color: "white",
                                        }}
                                    />
                                    <Chip
                                        label={option.tgl_ultah}
                                        // color="success"
                                        style={{
                                            fontWeight: "bold",
                                            borderRadius: "0px",
                                            marginBottom: "10px",
                                            background: "transparent",
                                            border: "1px solid #00a8ff",
                                            color: "black",
                                        }}
                                    />
                                    <Chip
                                        label={option.nama_customer + " (" + option.umur + " Tahun)"}
                                        style={{
                                            fontWeight: "bold",
                                            borderRadius: "0px",
                                            background: "transparent",
                                            border: "1px solid #00a8ff",
                                            color: "black",
                                            marginLeft: "2px",
                                            width: "100%",
                                        }}
                                    />
                                    <Chip
                                        label={"No Telepon ("+ option.telp+")"}
                                        style={{
                                            fontWeight: "bold",
                                            borderRadius: "0px",
                                            marginBottom: "10px",
                                            background: "transparent",
                                            border: "1px solid #00a8ff",
                                            color: "black",
                                            marginLeft: "2px",
                                            width: "100%",
                                        }}
                                    />
                                </Box>
                            </>
                        ))}
                    </DialogContent>
                    <DialogActions style={{
                        borderLeft: "5px solid #c23616",
                        // borderRight: "5px solid #c23616",
                    }}>
                        <Button onClick={() => setOpenListBirth(false)}>OK</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/* List STNK */}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={openListStnk}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={setOpenListStnk}
                    aria-describedby="alert-dialog-slide-description"
                    style={{ width: "70%", margin: "0 auto" }}
                >
                    <DialogContent style={{
                        borderLeft: "5px solid #c23616",
                    }}>
                        <br></br>
                        {"Berikut ini merupakan list customer STNK akan habis:"}
                        <br></br>
                        <br></br>
                        {listStnk.map((option) => (
                            <>
                                <div style={{background: "whitesmoke", padding: "10px", marginBottom: "5px"}}>
                                    <Box style={{ width: "100%", marginBottom: "5px" }}>
                                        <Chip
                                            label={option.single_id}
                                            // color="success"
                                            style={{
                                                fontWeight: "bold",
                                                borderRadius: "0px",
                                                marginBottom: "10px",
                                                background: "#EA2027",
                                                color: "white"
                                            }}
                                        />
                                        <Chip
                                            label={option.nama_customer}
                                            // color="success"
                                            style={{
                                                fontWeight: "bold",
                                                borderRadius: "0px",
                                                marginBottom: "10px",
                                                background: "transparent",
                                                border: "1px solid #EA2027",
                                                color: "#130f40",
                                                marginLeft: "2px"
                                            }}
                                        />
                                        <Chip
                                            label={option.telp}
                                            // color="success"
                                            style={{
                                                fontWeight: "bold",
                                                borderRadius: "0px",
                                                marginBottom: "10px",
                                                background: "transparent",
                                                border: "1px solid #EA2027",
                                                color: "#130f40",
                                                marginLeft: "2px"
                                            }}
                                        />
                                    </Box>
                                    <Box style={{ width: "100%", marginBottom: "5px" }}>
                                        <Chip
                                            label={"Nama STNK"}
                                            // color="success"
                                            style={{
                                                fontWeight: "bold",
                                                borderRadius: "0px",
                                                background: "#EA2027",
                                                border: "1px solid #EA2027",
                                                color: "white"
                                            }}
                                        />
                                        <Chip
                                            label={option.nama_stnk}
                                            // color="success"
                                            style={{
                                                fontWeight: "bold",
                                                borderRadius: "0px",
                                                background: "transparent",
                                                border: "1px solid #EA2027",
                                                color: "black"
                                            }}
                                        />
                                        <Chip
                                            label={"Tanggal STNK "+option.tgl_stnk}
                                            // color="success"
                                            style={{
                                                fontWeight: "bold",
                                                borderRadius: "0px",
                                                background: "transparent",
                                                border: "1px solid #EA2027",
                                                color: "black"
                                            }}
                                        />
                                        <Chip
                                            label={option.tipe}
                                            // color="success"
                                            style={{
                                                fontWeight: "bold",
                                                borderRadius: "0px",
                                                background: "transparent",
                                                border: "1px solid #EA2027",
                                                color: "black"
                                            }}
                                        />
                                    </Box>
                                </div>
                            </>
                        ))}
                    </DialogContent>
                    <DialogActions style={{
                        borderLeft: "5px solid #c23616",
                        // borderRight: "5px solid #c23616",
                    }}>
                        <Button onClick={() => setOpenListStnk(false)}>OK</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default DataCustomer;
