import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import InputAdornment from "@mui/material/InputAdornment";

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// Cards
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
  Default: "#81b71a",
  Blue: "#00B1E1",
  Cyan: "#37BC9B",
  Green: "#8CC152",
  Red: "#9e0000",
  Yellow: "#F6BB42",
};

const bulanlist = [
  {
    value: "1",
    label: "Januari",
  },
  {
    value: "2",
    label: "Februari",
  },
  {
    value: "3",
    label: "Maret",
  },
  {
    value: "4",
    label: "April",
  },
  {
    value: "5",
    label: "Mei",
  },
  {
    value: "6",
    label: "Juni",
  },
  {
    value: "7",
    label: "July",
  },
  {
    value: "8",
    label: "Agustus",
  },
  {
    value: "9",
    label: "September",
  },
  {
    value: "10",
    label: "Oktober",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "Desember",
  },
];

// const tahunlist = [
//   {
//     value: "2018",
//     label: "2018",
//   },
//   {
//     value: "2019",
//     label: "2019",
//   },
//   {
//     value: "2020",
//     label: "2020",
//   },
//   {
//     value: "2021",
//     label: "2021",
//   },
//   {
//     value: "2022",
//     label: "2022",
//   },
//   {
//     value: "2023",
//     label: "2023",
//   }
// ];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const useStyles = makeStyles((theme) => ({
  headerimage: {
    width: theme.spacing(10),
    height: theme.spacing(5),
  },
  thead: {
    backgroundColors: bgColors.Cyan,
  },
  btn: {
    marginTop: theme.spacing(2),
  },
  btnexport: {
    // marginLeft: theme.spacing(125),
    float: "right",
    marginBottom: theme.spacing(1),
    backgroundColors: bgColors.Green,
  },
  inputText: {
    width: "50%",
  },
}));

function InputSpk(props) {
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth();
  const [bulan, setBulan] = React.useState(month + 1);
  const [tahun, setTahun] = React.useState(year);
  const [nmCustomer, setCustomer] = React.useState("");
  const [showLoading, setLoading] = React.useState(true);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [fullWidth, setFullWidth] = React.useState(true);

  const handleChange = (event) => {
    setBulan(event.target.value);
  };
  const handleChange2 = (event) => {
    setTahun(event.target.value);
  };
  const handleCustomer = (event, value) => {
    const customername = value != null ? value.label : "";
    setCustomer(customername);
  };

  const queryOptions = React.useMemo(
    () => ({
      page,
      pageSize,
    }),
    [page, pageSize]
  );

  const isLoading = false;

  const [open, setOpen] = React.useState(false);
  const [nameSales, setNameSales] = React.useState();
  const [nameCustomer, setNameCustomer] = React.useState();
  const [hp, setHp] = React.useState();
  const [email, setEmail] = React.useState();
  const [nameIdp, setNameIdp] = React.useState();

  const handleClickOpen = (eventValue) => {
    console.log(eventValue);
    setNameSales(eventValue.nama_sales);
    setNameCustomer(eventValue.nama_cust);
    setHp(eventValue.telp);
    setEmail(eventValue.email);
    setNameIdp(eventValue.idp);
    setInputs((values) => ({
      ...values,
      ["idp"]: eventValue.id,
      ["telp"]: eventValue.telp,
      ["email"]: eventValue.email,
    }));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // API List Data Prospek
  const [dataCustomer, setDataCustomer] = useState([]);
  const [dataProspek, setDataProspek] = useState([]);
  const [dataProspek2, setDataProspek2] = useState([]);
  const [allProspek, setAllProspek] = useState([]);
  const [refreshDt, setRefresh] = useState();

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const getDataCustomer = async () => {
      const url = `https://api-funeling.kidsbjm.com/api/area/sales/list/customer?bulan=${bulan}&tahun=${tahun}`;
      try {
        const response = await axios.get(url);
        // if (response.statusText === "") {
        setDataCustomer(response.data.data);
        // } else {
        // throw new Error("Request failed");
        // }
      } catch (error) {
        console.log(error);
      }
    };
    getDataCustomer();
  }, [bulan, tahun]);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const getData = async () => {
      const url = `https://api-funeling.kidsbjm.com/api/area/sales/list/spk?bulan=${bulan}&tahun=${tahun}&customer=${nmCustomer}&page=${page}&size=${pageSize}`;
      try {
        const response = await axios.get(url);
        // if (response.statusText === "") {
        setDataProspek(response.data.data);
        setDataProspek2(response.data);
        setLoading(false);
        // } else {
        //   throw new Error("Request failed");
        // }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [nmCustomer, page, pageSize, bulan, tahun, refreshDt]);

  const [rowCountState, setRowCountState] = React.useState(
    dataProspek2?.totalAll || 0
  );

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const getAllData = async () => {
      const url = `https://api-funeling.kidsbjm.com/api/summary/export/prospek?bulan=${bulan}&tahun=${tahun}`;
      try {
        const response = await axios.get(url);
        // if (response.statusText === "") {
          setAllProspek(response.data.data);
        // } else {
        //   throw new Error("Request failed");
        // }
      } catch (error) {
        console.log(error);
      }
    };
    // getAllData();
  }, [bulan, tahun]);

  const header = [
    "Jenis",
    "Tanggal",
    "SPV",
    "Sales",
    "Customer",
    "Alamat",
    "Kelurahan",
    "Telp",
    "Minat",
    "Sumber Prospek",
    "Kategori",
    "Planning To Buy",
    "Test Drive",
    "SPK",
  ];

  const body3 = allProspek.map((data) => [
    data.jenis_cust,
    data.tgl,
    data.nama_spv,
    data.nama_sales,
    data.nama_cust,
    data.alamat,
    data.kelurahan,
    data.telp,
    data.kendaraan_minat,
    data.sumber,
    data.kategori,
    data.planning_to_buy,
    data.test_drive,
    data.spk,
  ]);

  const mynamefile = "Data Prospek_" + bulan + "-" + tahun + ".csv";

  function handleDownloadExcel() {
    downloadExcel({
      fileName: "Data Prospek",
      sheet: "Prospek",
      tablePayload: {
        header,
        // accept two different data structures
        body: body3,
      },
    });
  }

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      dataProspek2?.totalAll !== undefined
        ? dataProspek2?.totalAll
        : prevRowCountState
    );
  }, [dataProspek2?.totalAll, setRowCountState]);

  const columns = [
    {
      field: "Proses SPK",
      renderCell: (cellValues) => {
        return cellValues.row.spk == "NO" ? (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={(event) => {
              handleClickOpen(cellValues);
            }}
          >
            Proses
          </Button>
        ) : (
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              <Chip label="Sudah SPK" size="small" color="success" />
            </Stack>
          </Stack>
        );
      },
      minWidth: 150,
    },
    { field: "tgl", headerName: "Tanggal", minWidth: 150 },
    { field: "nama_cust", headerName: "Customer", minWidth: 200 },
    { field: "sumber", headerName: "Sumber Prospek", minWidth: 200 },
    { field: "alamat", headerName: "Alamat", minWidth: 200 },
    { field: "telp", headerName: "Telepon", minWidth: 200 },
  ];

  const handleClick = (values) => {
    console.log(values);
  };

  const [inputs, setInputs] = useState([]);

  //Force Uppercase
  const handleChangeInput = (event) => {
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value.toUpperCase(),
    }));
  };

  //validasi cabang SPK
  var cabang = JSON.parse(localStorage.getItem("id_cabang"));
  if (cabang == 1) {
    cabang = "BUJ-";
  }
  const [loadButton, setLoadButton] = React.useState(false);
  const handleSubmit = (event) => {
    setLoadButton(true);
    event.preventDefault();
    axios
      .post("https://api-funeling.kidsbjm.com/api/spk/create", inputs)
      .then(function (response) {
        if (response.data.error == true) {
          swal("Error", "Data tidak boleh kosong!", "error", {
            buttons: false,
            timer: 2000,
          });
          setLoadButton(false);
        } else {
          swal("Success", "Data Berhasil disimpan!", "success", {
            buttons: false,
            timer: 2000,
          });
          // window.location.reload();
          setLoadButton(false);
          setOpen(false);
          setRefresh(response);
        }
      });
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
      }}
    >
      <Toolbar />
      <Typography className="bg-white p-3 rounded-md shadow-lg">
        <b>Input SPK</b>
      </Typography>{" "}
      <br />
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div className="bg-white p-3 rounded-md shadow-lg">
          <TextField
            id="outlined-select-currency"
            select
            label="Bulan"
            value={bulan}
            onChange={handleChange}
            style={{ float: "left" }}
            helperText="Pilih Bulan"
          >
            {bulanlist.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="outlined-select-currency-native"
            select
            label="Tahun"
            value={tahun}
            style={{ float: "left" }}
            onChange={handleChange2}
            SelectProps={{
              native: true,
            }}
            helperText="Pilih Tahun"
          >
            {tahunlist.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={dataCustomer}
            onChange={handleCustomer}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer"
                helperText="Pilih Customer"
              />
            )}
          />
        </div>
      </Box>
      {/* <TableContainer style={{ height: 500, width: "100%" }}>
                <DataGrid
                    rows={dataProspek}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer> */}
      <br></br>
      {nmCustomer != "" ? (
        <Box sx={{ flexGrow: 1 }}>
          {dataProspek.map((datval) => [
            <Card
              key={datval.id}
              sx={{
                float: "left",
                width: "90%",
                margin: "0 auto",
                borderTop: "1px solid #e0e0e0",
                marginBottom: 2,
              }}
            >
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {datval.nama_cust}
                </Typography>
                <Typography sx={{ fontSize: 12 }}>
                  <b>{datval.jenis_cust}</b>
                  <br></br>
                  No Telepon <b>{datval.telp}</b>
                  <br></br>
                  {datval.kendaraan_dimiliki}
                  <br></br>
                  <b>{datval.kendaraan_minat}</b>
                  <br></br>
                  {datval.tgl}
                  <br></br>
                  {datval.sumber}
                  <br></br>
                  {datval.alamat}
                  <br></br>
                </Typography>
              </CardContent>
              <CardActions>
                {datval.spk == "NO" ? (
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={(event) => {
                      handleClickOpen(datval);
                    }}
                  >
                    Proses SPK
                  </Button>
                ) : (
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Sudah SPK" size="small" color="success" />
                    </Stack>
                  </Stack>
                )}
              </CardActions>
            </Card>,
          ])}
        </Box>
      ) : (
        ""
      )}
      <Box style={{ width: "100%" }}>
        
        <form onSubmit={handleSubmit}>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            fullWidth={fullWidth}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            style={{ width: "100%" }}
          >
            <DialogTitle
              style={{
                backgroundColor: "#EA2027",
                marginBottom: "10px",
                color: "#ffffff",
              }}
            >
              {"Input SPK"}
            </DialogTitle>
            <DialogContent>
              <Chip
                label={"Sales: " + nameSales}
                color="primary"
                variant="outlined"
              />
              <Chip
                label={"Customer: " + nameCustomer}
                color="success"
                variant="outlined"
              />
              <Chip
                label={"No. Handphone: " + hp}
                color="success"
                variant="outlined"
              />
              <Chip
                label={"Email: " + email}
                color="success"
                variant="outlined"
              />

              <br />
              <br />
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  defaultValue={nameIdp}
                  variant="outlined"
                  name="idp"
                  label="Idp"
                  onChange={handleChangeInput}
                  className={classes.inputText}
                  size="small"
                  helperText="IDP"
                  style={{ display: "none" }}
                  
                />
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="customer"
                  label="Customer"
                  onChange={handleChangeInput}
                  className={classes.inputText}
                  size="small"
                  helperText="Nama Fix"
                />
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="tanggal"
                  onChange={handleChangeInput}
                  className={classes.inputText}
                  size="small"
                  type="date"
                  helperText="Tanggal"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                    id="outlined-basic"
                    required
                    variant="outlined"
                    name="nonik"
                    label="No KTP"
                    onChange={handleChangeInput}
                    style={{ width: "100%" }}
                    size="small"
                    helperText="No KTP"
                  />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="nospk"
                  label="No SPK"
                  onChange={handleChangeInput}
                  className={classes.inputText}
                  size="small"
                  helperText="No SPK"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{cabang}</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  label="Nama STNK"
                  name="namastnk"
                  onChange={handleChangeInput}
                  className={classes.inputText}
                  size="small"
                  helperText="nama STNK"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="kendaraan"
                  label="Kendaraan"
                  onChange={handleChangeInput}
                  className={classes.inputText}
                  size="small"
                  helperText="kendaraan"
                />
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  label="Warna"
                  name="warna"
                  onChange={handleChangeInput}
                  className={classes.inputText}
                  size="small"
                  helperText="Warna"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              {loadButton === true ? 
                    <Button
                        size="large"
                        variant="contained"
                        color="secondary"
                        disabled
                    > 
                        <CircularProgress size="1.2rem" color="inherit" />
                    </Button>
                :  
                    <Button
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                    > 
                        <Typography>Submit</Typography>
                    </Button>
                }
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </form>

      </Box>
    </Box>
  );
}

export default InputSpk;

if (document.getElementById("salesinputspk")) {
  ReactDOM.render(<InputSpk />, document.getElementById("salesinputspk"));
}
