import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// Cards
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const listPertanyaan = [
    {
        value: "Ya",
        label: "Ya",
    },
    {
        value: "Tidak",
        label: "Tidak",
    }
];

const listJeniscustomer = [
    {
        value: "NEW CUSTOMER - SUSPECT",
        label: "Suspect",
    },
    {
        value: "NEW CUSTOMER - PROSPEK",
        label: "Prospek",
    },
];

const listKategoriProspek = [
    {
        value: "BELUM PROSPEK",
        label: "BELUM PROSPEK",
    },
    {
        value: "LOW",
        label: "LOW",
    },
    {
        value: "MEDIUM",
        label: "MEDIUM",
    },
    {
        value: "HOT",
        label: "HOT",
    },
];

const listDemand = [
    {
        value: "First Buyer",
        label: "First Buyer",
    },
    {
        value: "Additional",
        label: "Additional",
    },
    {
        value: "Trade In",
        label: "Trade In",
    }
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        // marginLeft: theme.spacing(125),
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    inputText: {
        width: "48%",
        marginLeft: theme.spacing(2)
    },
}));

function LbengkelSales(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [nmCustomer, setCustomer] = React.useState("");
    const classes = useStyles();
    const [kecamatanOpt, setKecamatan] = React.useState([]);
    const [kecamatanList, setKecamatanState] = React.useState("");
    const [kelurahanOpt, setKelurahan] = React.useState([]);
    const [kelurahanList, setKelurahanState] = React.useState("");
    const [jeniscustomer, setJeniscustomer] = React.useState("");
    const [alamatCust, setalamatCust] = React.useState("");
    const [telpCust, setTelpCust] = React.useState("");
    const [kategoriProspekList, setKategoriProspekState] = React.useState("");
    const [demand, setDemand] = React.useState("");
    const [kendaraanMinat, setKendaraanMinat] = React.useState("");
    const [feedbackCust, setFeedback] = React.useState("");

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };
    const handleCustomer = (event, value) => {
        const customername = value != null ? value.label : "";
        setCustomer(customername);
    };

    const isLoading = false;

    const [open, setOpen] = React.useState(false);
    const [dtDo, setDtDo] = React.useState([]);

    const handleClickOpen = (eventValue) => {
        setDtDo(eventValue);
        setalamatCust(eventValue.alamat);
        setTelpCust(eventValue.telp);
        setInputs((values) => ({
            ...values,
            ["dtid"]: eventValue.id,
            ["nama_cust"]: eventValue.nama,
            ["telp"]: eventValue.telp,
            ["alamat"]: eventValue.alamat,
            ["kendaraan_dimiliki"]: eventValue.kendaraan,
            ["sumber"]: "DATABASE BENGKEL",
            ["demand_structure"]: "",
        }));
        setOpen(true);
    };

    const [openCancel, setOpenCancel] = React.useState(false);
    const handleCancelBooking = (eventValue) => {
        setDtDo(eventValue);
        setOpenCancel(true);
        setInputsCancel((values) => ({
            ...values,
            ["id_pil"]: eventValue.id,
            ["cancel"]: "yes",
        }));
    };

    // API List Data Prospek
    const [dataCustomer, setDataCustomer] = useState([]);
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [refreshDt, setRefresh] = useState();

    const allOption = useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios
            .get("https://api-funeling.kidsbjm.com/api/area/sales/select/option")
            .then((response) => {
                setKecamatan(response.data.kecamatan);
            });
    }, []);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getDataCustomer = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/area/sales/bengkel/list/customer`;
            try {
                const response = await axios.get(url);
                if (response.statusText === "") {
                    setDataCustomer(response.data.data);
                } else {
                    throw new Error("Request failed");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getDataCustomer();
    }, []);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/area/sales/bengkel/showdata/bengkel?customer=${nmCustomer}`;
            try {
                const response = await axios.get(url);
                if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                } else {
                    throw new Error("Request failed");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [nmCustomer, refreshDt]);

    const handleClick = (values) => {
        console.log(values);
    };

    const [inputs, setInputs] = useState([]);
    

    const handleClose = () => {
        // setOpenCancel(false);
        setalamatCust("");
        setKendaraanMinat("");
        setFeedback("");
        setOpen(false);
    };

    const handleChangeInput = (event) => {
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    const handleChangeInputAlamat = (event) => {
        setalamatCust(event.target.value);
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    const handleChangeInputTelepon = (event) => {
        setTelpCust(event.target.value);
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    const handleChangeMinat = (event) => {
        setKendaraanMinat(event.target.value);
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    const handleChangeFeedback = (event) => {
        setFeedback(event.target.value);
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const [inputsCancel, setInputsCancel] = useState([]);
    const handleInputCancel = (event) => {
        setInputsCancel((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const handleJcust = (event) => {
        setJeniscustomer(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleKecamatan = (event) => {
        setKecamatanState(event.target.value);
        getKelurahan(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleKelurahan = (event) => {
        setKelurahanState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleKtgProspek = (event) => {
        setKategoriProspekState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleDemand = (event) => {
        setDemand(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    function getKelurahan(kecamatan) {
        axios
            .get("https://api-funeling.kidsbjm.com/api/kelurahan/find/kecamatan?nama_kecamatan="+kecamatan)
            .then((response) => {
                setKelurahan(response.data.data);
            });
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .post("https://api-funeling.kidsbjm.com/api/area/sales/bengkel/proses/bengkel", inputs)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                } else {
                    swal("Success", "Data Berhasil disimpan!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    // window.location.reload();
                    setOpen(false);
                    setRefresh(response);
                    setInputs("");
                }
            });
    };

    const handleSubmitCancel = (event) => {
        event.preventDefault();
        axios
            .put("https://api-funeling.kidsbjm.com/api/booking/action/cancel", inputsCancel)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                } else {
                    swal("Success", "Data Berhasil disimpan!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    // window.location.reload();
                    setOpenCancel(false);
                    setRefresh(response);
                }
            });
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography className="bg-white p-3 rounded-md shadow-lg">
                <b>List Data Bengkel</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div className="bg-white p-3 rounded-md shadow-lg">
                    
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dataCustomer}
                        onChange={handleCustomer}
                        renderInput={(params) => (
                            <TextField {...params} label="Customer" helperText="Pilih Customer" />
                        )}
                    />
                </div>
            </Box>
            <br></br>
            <br></br>
            {nmCustomer != "" ? (
                <Box sx={{ flexGrow: 1 }}>
                    {dataProspek.map((datval) => [
                        <Card key={datval.id} sx={{ width: "90%", margin: "0 auto", borderTop: "1px solid #e0e0e0", marginBottom: 2 }}>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                >
                                    <b>{datval.nama}</b><br></br>
                                </Typography>
                                <Typography
                                    sx={{ fontSize: 12 }}
                                >
                                    {datval.tgl}<br></br>
                                    {datval.telp}<br></br>
                                    {datval.alamat}<br></br>
                                    <b>{datval.kendaraan}</b><br></br>
                                    <b>No Polisi {datval.no_pol}</b><br></br>
                                    <b>{datval.no_rangka}</b><br></br>
                                    Feedback: {datval.feedback != null ? (<b><Chip sx={{ fontSize: 12, height: 20 }} label={datval.feedback} color="info" /></b>) : ""}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    onClick={(event) => {
                                        handleClickOpen(datval);
                                    }}
                                >
                                    Proses
                                </Button>
                            </CardActions>
                        </Card>
                        ,
                    ])}
                </Box>
            ) : (
                ""
            )}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle style={{backgroundColor:bgColors.Yellow, marginBottom:'10px', color:'#ffffff'}}>{"Proses Bengkel"}</DialogTitle>
                    <DialogContent>
                        <Typography>
                            <Chip label={'Nama Sales: '+dtDo.sales} color="primary"  variant="outlined" />
                            <Chip label={'Nama Customer: '+dtDo.nama} color="success"  variant="outlined" />
                            <Chip label={'Kendaraan: '+dtDo.kendaraan} color="success"  variant="outlined" />
                            <Chip label={'No Polisi: '+dtDo.no_pol} color="success"  variant="outlined" /><br />
                        </Typography>{" "}
                        <br />
                        <form>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic jenis_cust"
                                    required
                                    select
                                    variant="outlined"
                                    name="jenis_cust"
                                    onChange={handleJcust}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    helperText="Jenis Customer"
                                >
                                    {listJeniscustomer.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-basic"
                                    disabled
                                    value="DATABASE BENGKEL"
                                    variant="outlined"
                                    name="sumber"
                                    onChange={handleChangeInput}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    aria-readonly
                                    helperText="Sumber"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="tanggal"
                                    onChange={handleChangeInput}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    type="date"
                                    helperText="Tanggal"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    disabled
                                    value={dtDo.nama}
                                    variant="outlined"
                                    name="customer"
                                    onChange={handleChangeInput}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    aria-readonly
                                    helperText="Customer"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    value={alamatCust}
                                    name="alamat"
                                    onChange={handleChangeInputAlamat}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    helperText="Alamat"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    select
                                    variant="outlined"
                                    name="kecamatan"
                                    onChange={handleKecamatan}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    aria-readonly
                                    helperText="Kecamatan"
                                >
                                    {kecamatanOpt.map((option) => (
                                        <MenuItem
                                            key={option.nama_kecamatan}
                                            value={option.nama_kecamatan}
                                        >
                                            {option.nama_kecamatan}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    select
                                    defaultValue=""
                                    label="Pilih Kelurahan"
                                    name="kelurahan"
                                    onChange={handleKelurahan}
                                    sx={{ width: "50%" }}
                                    size= "small"
                                    required
                                    helperText="Pilih Kelurahan"
                                >
                                    {kelurahanOpt.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.kelurahan}
                                        >
                                            {option.kelurahan}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-basic"
                                    required
                                    disabled
                                    variant="outlined"
                                    value={telpCust}
                                    name="telp"
                                    onChange={handleChangeInputTelepon}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    aria-readonly
                                    helperText="No Telepon"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="kendaraan_minati"
                                    value={kendaraanMinat}
                                    onChange={handleChangeMinat}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    helperText="Kendaraan Diminati"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    value={dtDo.kendaraan}
                                    name="kendaraan_dimiliki"
                                    onChange={handleChangeInput}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    aria-readonly
                                    helperText="Kendaraan Dimiliki"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    select
                                    onChange={handleKtgProspek}
                                    label="Kategori"
                                    helperText="Pilih Kategori"
                                    name="kategoriprospek"
                                    variant="outlined"
                                    sx={{ width: "50%" }}
                                    size="small"
                                    required                                    
                                >
                                    {listKategoriProspek.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    value={feedbackCust}
                                    name="feedback"
                                    onChange={handleChangeFeedback}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    aria-readonly
                                    helperText="Feedback"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    select
                                    value={demand}
                                    onChange={handleDemand}
                                    label="Demand Structure"
                                    helperText="Pilih Demand"
                                    name="demand_structure"
                                    variant="outlined"
                                    sx={{ width: "50%" }}
                                    size="small"
                                    style={{display: kategoriProspekList != 'HOT' ? 'none' : '' }}
                                >
                                    {listDemand.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="keterangan"
                                    onChange={handleChangeInput}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    aria-readonly
                                    helperText="Keterangan"
                                />
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default LbengkelSales;

if (document.getElementById("listdatabengkelsales")) {
    ReactDOM.render(<LbengkelSales />, document.getElementById("listdatabengkelsales"));
}
