import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import swal from "sweetalert";
import Typography from "@mui/material/Typography";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { Container } from "@mui/material";
import Alert from '@mui/material/Alert';
import TableContainer from "@mui/material/TableContainer";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CSVLink } from "react-csv";

import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const person = JSON.parse(localStorage.getItem("person"));
const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};


const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    btndelete: {
        fontSize: theme.spacing(2),
    },
}));
function InputOnHand(props) {

    const tipestock = [
        {
            value: "Free",
            label: "Free",
        },
        {
            value: "onprogress",
            label: "On Progress",
        },
        {
            value: "outstanding",
            label: "Outstanding",
        },
        {
            value: "stockout",
            label: "Stock Out",
        },
    ];

    const kategoriStock = [
        {
            value: "onhand",
            label: "ON HAND",
        },
        {
            value: "bartercabang",
            label: "Barter Cabang",
        },
        {
            value: "barterdealer",
            label: "Barter Dealer",
        },
        // {
        //     value: "barterout",
        //     label: "Barter Out",
        // },
        // {
        //     value: "arunit",
        //     label: "AR Unit Penjualan",
        // },
    ];

    const [settipe, setTipeStock] = React.useState([]);
    const [setkategori, setkategoriStock] = React.useState("");
    const [spvName, setSpv] = React.useState("");
    const [inputs, setInputs] = React.useState([]);
    const [inputsImport, setInputsImport] = React.useState([]);
    const [dataSpv, setDataSpv] = useState([]);
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    useEffect(() => {
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/stock/list/spv`;
            try {
                const response = await axios.get(url);
                console.log(response);
                setDataSpv(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);
    
    const handleChangeInput = (event) => {
        setInputs(values => ({...values, ["tipe_stock"]: "free", [event.target.name]: event.target.value}));
    }
    const handleTipeStock = (event) => {
        setTipeStock(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };
    const handleKtgStock = (event) => {
        setkategoriStock(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };
    const handleChangeSpv = (event) => {
        setSpv(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    
    const [loadButton, setLoadButton] = React.useState(false);
    const handleSubmit = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios.post('https://api-funeling.kidsbjm.com/api/stock/createstock', inputs).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
                window.location.href = "/stock/overviewstock";
            }
        });
    };

    const [setKtgImport, setkategoriStockImport] = React.useState("");
    const [tipeStockImport, setTpStockImport] = React.useState("");
    const [fileUpload, setFileUp] = React.useState([]);

    const hChangeInputFile = (event) => {
        setFileUp(event.target.files[0]);
        setInputsImport(values => ({...values, [event.target.name]: fileUpload}));
    }
    const handleTipeStockImport = (event) => {
        setTpStockImport(event.target.value);
        setInputsImport(values => ({...values, [event.target.name]: event.target.value}));
    };
    const handleKtgStockImport = (event) => {
        setkategoriStockImport(event.target.value);
        setInputsImport(values => ({...values, [event.target.name]: event.target.value}));
    };

    const handleSubmitImport = (event) => {
        setLoadButton(true);
        event.preventDefault();
        console.log(fileUpload);
        const formData = new FormData();
        
        formData.append('filestock',fileUpload);
        formData.append('tipe_stock',tipeStockImport);
        formData.append('kategori',setKtgImport);
        axios.post('https://api-funeling.kidsbjm.com/api/stock/import', formData).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
                window.location.href = "/stock/overviewstock";
            }
        });
        
    };

    const [openImport, setOpenImport] = React.useState(false);
    const handleOpenImport = (event)    => {
        setOpenImport(true);
    }
    const handleCloseImport = (event)    => {
        setOpenImport(false);
    }
    
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            
            <Alert>Halo, {person}! Berikut form untuk input stock onhand..</Alert>

            {/* Kategori Onhand */}
            <br></br>
            {openImport != true ?
                <Button variant="contained" size="small" color="success" sx={{ marginBottom: 3 }} onClick={handleOpenImport}>
                    Import Excel
                </Button>
            :
                <Button variant="contained" size="small" color="primary" sx={{ marginBottom: 3 }} onClick={handleCloseImport}>
                    Input Data
                </Button>
            }
            <br></br>
            <Box style={{}}>
                {openImport != true ?

                    <form onSubmit={handleSubmit}>
                        <Card sx={{  float:"left", width: "100%", margin: "0 auto", borderTop: "1px solid #e0e0e0", marginBottom: 2 }}>
                            <CardContent>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <TextField
                                        id="outlined-select-currency-native"
                                        select
                                        defaultValue=""
                                        label="Pilih SPV"
                                        helperText="Pilih SPV (optional)"
                                        onChange={handleChangeSpv}
                                        sx={{width: "50%", display: "none"}}
                                        size= "small"
                                        name="spv"
                                    >
                                        {dataSpv.map((option) => (
                                            <MenuItem
                                                key={option.nama_spv}
                                                value={option.nama_spv}
                                            >
                                                {option.nama_spv}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="RRN"
                                        helperText="RRN"
                                        onChange={handleChangeInput}
                                        sx={{width: "100%"}}
                                        size= "small"
                                        name="rrn"
                                        required
                                    >
                                    </TextField>
                                </Box>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="VIN Code"
                                        helperText="VIN Code"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="vin_code"
                                        required
                                    >
                                    </TextField>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="Model"
                                        helperText="Model"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="model"
                                        required
                                    >
                                    </TextField>
                                </Box>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="Type"
                                        helperText="Type"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="type"
                                        required
                                    >
                                    </TextField>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="No Rangka"
                                        helperText="No Rangka"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="no_rangka"
                                        required
                                    >
                                    </TextField>
                                </Box>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="No Mesin"
                                        helperText="No Mesin"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="no_mesin"
                                        required
                                    >
                                    </TextField>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="Warna"
                                        helperText="Warna"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="warna"
                                        required
                                    >
                                    </TextField>
                                </Box>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="No DO Tam"
                                        helperText="No DO Tam"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="no_do_tam"
                                    >
                                    </TextField>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        helperText="Tanggal Do Tam"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="tgl_do_tam"
                                        type="date"
                                    >
                                    </TextField>
                                </Box>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        helperText="Tanggal Datang"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="tgl_dtg"
                                        type="date"
                                        required
                                    >
                                    </TextField>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="Customer"
                                        helperText="Customer"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="customer"
                                    >
                                    </TextField>
                                </Box>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        helperText="Tanggal Alo"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="tgl_alo"
                                        type="date"
                                    >
                                    </TextField>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="Sales"
                                        helperText="Sales"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="sales"
                                    >
                                    </TextField>
                                </Box>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="No DO"
                                        helperText="No DO"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="no_do"
                                    >
                                    </TextField>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        helperText="Tanggal DO"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="tgl_do"
                                        type="date"
                                    >
                                    </TextField>
                                </Box>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="Umur Alok"
                                        helperText="Umur Alok"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="umur_alok"
                                        required
                                    >
                                    </TextField>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="Umur AR"
                                        helperText="Umur AR"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="umur_ar"
                                        required
                                    >
                                    </TextField>
                                </Box>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label="Keterangan"
                                        helperText="Keterangan"
                                        onChange={handleChangeInput}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="ket"
                                    >
                                    </TextField>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        select
                                        defaultValue=""
                                        label="Pilih Kategori Stock"
                                        helperText="Pilih Kategori Stock"
                                        onChange={handleKtgStock}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="kategori"
                                        required
                                    >
                                        {kategoriStock.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <TextField
                                        id="outlined-select-currency-native"
                                        select
                                        defaultValue=""
                                        label="Tipe Stock"
                                        helperText="Tipe Stock"
                                        onChange={handleTipeStock}
                                        sx={{width: "50%", display: "none"}}
                                        size= "small"
                                        name="tipe_stock"
                                    >
                                        {tipestock.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                            </CardContent>
                            <CardActions>
                                {loadButton === true ? 
                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="secondary"
                                        disabled
                                    > 
                                        <CircularProgress size="1.2rem" color="inherit" />
                                    </Button>
                                :  
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                }
                            </CardActions>
                        </Card>
                    </form>

                :
                    (<>
                    <form>
                        <Card sx={{  float:"left", width: "100%", margin: "0 auto", borderTop: "1px solid #e0e0e0", marginBottom: 2 }}>
                            <CardContent>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <Link sx={{  textDecoration: "none" }} href="https://api-funeling.kidsbjm.com/resources/template/template_stock_do_tam.xlsx"><Button variant="outlined" size="small" color="success" >Download Template Excel</Button></Link>
                                </Box>
                                <Box
                                    style={{ width: "100%" }}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <TextField
                                        id="outlined-select-currency-native"
                                        defaultValue=""
                                        label=""
                                        helperText="Pilih File"
                                        onChange={hChangeInputFile}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="filestock"
                                        type="file"
                                    >
                                    </TextField>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        select
                                        defaultValue=""
                                        label="Tipe Stock"
                                        helperText="Tipe Stock"
                                        onChange={handleTipeStockImport}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="tipe_stockimport"
                                    >
                                        {tipestock.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        select
                                        defaultValue=""
                                        label="Pilih Kategori Stock"
                                        helperText="Pilih Kategori Stock"
                                        onChange={handleKtgStockImport}
                                        sx={{width: "50%"}}
                                        size= "small"
                                        name="kategoriImport"
                                        required
                                    >
                                        {kategoriStock.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                            </CardContent>
                            <CardActions>
                                {loadButton === true ? 
                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="secondary"
                                        disabled
                                    > 
                                        <CircularProgress size="1.2rem" color="inherit" />
                                    </Button>
                                :  
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleSubmitImport}
                                    >
                                        Proses Import
                                    </Button>
                                }
                            </CardActions>
                        </Card>
                    </form></>)          
                }
            </Box>
        </Box>
            
        
    );
}

export default InputOnHand;