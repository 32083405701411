import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import swal from "sweetalert";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from '@mui/material/InputAdornment';

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from '@mui/material/CircularProgress';
import { formLabelClasses } from "@mui/material";
import Alert from '@mui/material/Alert';

const drawerWidth = 240;

const person = JSON.parse(localStorage.getItem("person"));
const token = localStorage.getItem("access_token");

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    inputText: {
        width: "50%",
    },
}));

function InputService(props) {
    // const { window } = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [relasiList, setPlanningState] = React.useState("");
    const [noPol, setNoPol] = React.useState("");
    const [nmPembeli, setNmPembeli] = useState("");
    const [nmStnk, setNmStnk] = useState("");
    const [tglStnk, setTglStnk] = useState("");
    const [noRangkaCust, setnoRangkaCust] = useState("");
    const [kendaraanTipe, setKendaraanTipe] = useState("");

    // API OPTION INPUT
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const [inputs, setInputs] = useState([]);
    const handleChangeInput = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const [loadButton, setLoadButton] = React.useState(false);
    const handleSubmit = (event) => {
        console.log(inputs);
        setLoadButton(true);
        event.preventDefault();
        axios.post('https://api-funeling.kidsbjm.com/api/area/sa/service/create', inputs).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                })    
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                })
                setLoadButton(false);
                window.location.reload();
            }

        });
    };

    const handleRelasi = (event) => {
        setPlanningState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleChangeTglStnk = (event) => {
        setTglStnk(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const handleNoRangka = (event, value) => {
        console.log(value);
        setNoPol(event.target.value);
        getDetailPembeli(value.label);
        const nopol = value != null ? value.label : "";
        setInputs(values => ({...values, ["no_pol"]: nopol == "" ? event.target.value : nopol}));
    };

    function getDetailPembeli(nopol) {
        axios
            .get("https://api-funeling.kidsbjm.com/api/area/sa/find/customerbynopol?nopol="+nopol)
            .then((response) => {
                setNmPembeli(response.data.data.nama_customer);
                setNmStnk(response.data.data.nama_stnk);
                setTglStnk(response.data.data.tgl_stnk == null ? "" : response.data.data.tgl_stnk);
                setnoRangkaCust(response.data.data.no_rangka);
                setKendaraanTipe(response.data.data.tipe);
                setInputs((values) => ({
                    ...values,
                    ["single_id"]: response.data.data.single_id,
                    ["no_rangka"]: response.data.data.no_rangka,
                    ["dec_id"]: response.data.data.dec_id,
                }));
            });
    }

    const listJeniscustomer = [
        {
            value: "Suspect",
            label: "Suspect",
        },
        {
            value: "Prospek",
            label: "Prospek",
        },
    ];

    const listKategoriProspek = [
        {
            value: "BELUM PROSPEK",
            label: "BELUM PROSPEK",
        },
        {
            value: "LOW",
            label: "LOW",
        },
        {
            value: "MEDIUM",
            label: "MEDIUM",
        },
        {
            value: "HOT",
            label: "HOT",
        },
    ];

    const listRelasi = [
        {
            value: "Buyer",
            label: "Buyer",
        },
        {
            value: "User",
            label: "User",
        },
        {
            value: "Owner",
            label: "Owner",
        }
    ];

    const listTestDrive = [
        {
            value: "Ya",
            label: "Ya",
        },
        {
            value: "Tidak",
            label: "Tidak",
        }
    ];

    const listDemand = [
        {
            value: "First Buyer",
            label: "First Buyer",
        },
        {
            value: "Additional",
            label: "Additional",
        },
        {
            value: "Trade In",
            label: "Trade In",
        }
    ];

    const listKategori = [];

    const [kategori, setKategori] = React.useState();

    const handleKategori = (event) => {
        setKategori(event.target.value);
    };

    const [dataNoPol, setDataNoPol] = useState([]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getDataNoPol = async () => {
          const url = `https://api-funeling.kidsbjm.com/api/area/sa/prospek/listnorangka`;
          try {
            const response = await axios.get(url);
                setDataNoPol(response.data.data);

          } catch (error) {
            console.log(error);
          }
        };
        getDataNoPol();
      }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Alert onClose={() => {}}>Halo, {person} Ini Halaman Input Service</Alert>
            <br></br>

            <Box style={{ width: "100%" }}>
                <Card sx={{ minWidth: 300 }}>
                    <form onSubmit={handleSubmit}>
                        <CardContent>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <Autocomplete
                                    disableClearable
                                    freeSolo
                                    id="combo-box-demo"
                                    options={dataNoPol}
                                    onChange={handleNoRangka}
                                    name="no_rangka"
                                    style={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="No Rangka"
                                            name="no_rangka"
                                            value={noPol}
                                            onChange={handleNoRangka}
                                            helperText="Pilih No Rangka"
                                            size="small"
                                            id="outlined-basic"
                                            variant="outlined"
                                            required
                                        />
                                    )}
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                
                                <TextField
                                    id="outlined-basic"
                                    name="tgl_stnk"
                                    helperText="Tanggal STNK"
                                    variant="outlined"
                                    style={{ width: "50%" }}
                                    size="small"
                                    type="date"
                                    value={tglStnk}
                                    onChange={handleChangeTglStnk}
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    label="Pembeli"
                                    helperText="Nama Pembeli Kendaraan"
                                    name="pembeli_kendaraan"
                                    onChange={handleChangeInput}
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    value={nmPembeli}
                                    readonly
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    label="Nama STNK"
                                    helperText="Nama STNK"
                                    name="nama_stnk"
                                    onChange={handleChangeInput}
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    value={nmStnk}
                                    readonly
                                />
                                
                                <TextField
                                    id="outlined-basic"
                                    required
                                    label="Kendaraan Dimiliki"
                                    helperText="Kendaraan Dimiliki"
                                    name="kendaraanmiliki"
                                    onChange={handleChangeInput}
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    value={kendaraanTipe}
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    name="nama_pemakai_kendaraan"
                                    label="Pemakai Kendaraan"
                                    helperText="Pemakai Kendaraan Ketika Service"
                                    variant="outlined"
                                    style={{ width: "50%" }}
                                    size="small"
                                    onChange={handleChangeInput}
                                />
                                
                                <TextField
                                    id="outlined-basic"
                                    select
                                    value={relasiList}
                                    onChange={handleRelasi}
                                    label="Customer Tipe"
                                    helperText="Customer Tipe"
                                    name="customer_type"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                >
                                    {listRelasi.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    label="No Telepon"
                                    helperText="No Telepon Pemakai"
                                    name="no_telepon"
                                    onChange={handleChangeInput}
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    type="number"
                                />
                                <TextField
                                    id="outlined-basic"
                                    name="tgl_service"
                                    type="date"
                                    helperText="Tanggal Service"
                                    variant="outlined"
                                    style={{ width: "50%" }}
                                    size="small"
                                    onChange={handleChangeInput}
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    name="keterangan_service"
                                    label="Keterangan Service"
                                    helperText="Keterangan Service"
                                    variant="outlined"
                                    style={{ width: "50%" }}
                                    size="small"
                                    onChange={handleChangeInput}
                                />
                            </Box>
                        </CardContent>
                        <CardActions>
                            {loadButton === true ? 
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                            :  
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmit}
                                > 
                                    <Typography>Submit</Typography>
                                </Button>
                            }
                        </CardActions>
                    </form>
                </Card>
            </Box>
        </Box>
    );
}

export default InputService;