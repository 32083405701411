import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import swal from "sweetalert";
import Typography from "@mui/material/Typography";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { Container } from "@mui/material";
import Alert from '@mui/material/Alert';
import TableContainer from "@mui/material/TableContainer";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CSVLink } from "react-csv";

import CheckIcon from '@mui/icons-material/Check';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const person = JSON.parse(localStorage.getItem("person"));
const token = localStorage.getItem("access_token");
const rules = JSON.parse(localStorage.getItem("rules"));

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};


const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    btndelete: {
        fontSize: theme.spacing(2),
    },
}));
function Outstanding(props) {

    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [inputs, setInputs] = useState([]);
    const [inputsUpdate, setInputsUpdate] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleCloseUpdate = () => setOpenUpdate(false);
    const [TipeKendaraan, setTipeKendaraan] = useState("");
    const [NoRangka, setNoRangka] = useState("");
    const [Warna, setWarna] = useState("");
    const [refreshDt, setRefresh] = useState();

    const handleOpen = (eventValue) => {
        setOpen(true);
    };

    const handleOpen2 = (event) => {
        console.log(event);
        setOpenUpdate(true);
        setTipeKendaraan(event.tipekendaraan);
        setNoRangka(event.norangka);
        setWarna(event.warna);
        setInputsUpdate(values => ({
            ...values, 
            ["id"]: event.id,
            ["tipekendaraan"]: event.tipekendaraan,
            ["norangka"]: event.norangka,
            ["warna"]: event.warna,
        }));
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const handleChangeInput = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleChangeInputUpdate = (event) => {
        setInputsUpdate(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleChangeTipeKendaraan = (event) => {
        setTipeKendaraan(event.target.value);
        setInputsUpdate(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleChangeNoRangka = (event) => {
        setNoRangka(event.target.value);
        setInputsUpdate(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleChangeWarna = (event) => {
        setWarna(event.target.value);
        setInputsUpdate(values => ({...values, [event.target.name]: event.target.value}));
    }

    const handleClickOpen = (event) => {
        console.log(event);
    }

    //=====  KATEROGI ONHAND  =========/
    const [dataOnhand, setDataOnhand] = useState([]);
    const [dataOnhand2, setDataOnhand2] = useState([]);
    const [pageOnhand, setPageOnhand] = React.useState(0);
    const [pageSizeOnhand, setPageSizeOnhand] = React.useState(25);

    const currUrl = window.location.href;
    const segment = currUrl.split("/");
    const spvname = segment[5];

    const queryOptions = React.useMemo(
        () => ({
            pageOnhand,
            pageSizeOnhand,
        }),
        [pageOnhand, pageSizeOnhand]
    );

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/stock/list/outstanding?page=${pageOnhand}&size=${pageSizeOnhand}`;
            try {
                const response = await axios.get(url);
                setDataOnhand(response.data.onhand);
                setDataOnhand2(response.data);

                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [pageOnhand, pageSizeOnhand, refreshDt]);

    const [rowCountStateOnhand, setRowCountStateOnhand] = React.useState(
        dataOnhand2?.allBarterOut || 0
    );
    
    React.useEffect(() => {
        setRowCountStateOnhand((prevRowCountStateOnhand) =>
            dataOnhand2?.allBarterOut !== undefined
                ? dataOnhand2?.allBarterOut
                : prevRowCountStateOnhand
        );
    }, [dataOnhand2?.allBarterOut, setRowCountStateOnhand]);

    const columns = [
        { field: "rrn", headerName: "RRN", minWidth: 150 },
        { field: "vin_code", headerName: "VIN CODE", minWidth: 100 },
        { field: "model", headerName: "Model", minWidth: 300 },
        { field: "type", headerName: "Type", minWidth: 150 },
        { field: "no_rangka", headerName: "No Rangka", minWidth: 150 },
        { field: "no_mesin", headerName: "No Mesin", minWidth: 150 },
        { field: "warna", headerName: "Warna", minWidth: 150 },
        { field: "no_do_tam", headerName: "No DO TAM", minWidth: 150 },
        { field: "tgl_do_tam", headerName: "Tgl DO TAM", minWidth: 150 },
        { field: "tgl_dtg", headerName: "Tgl Dtg", minWidth: 150 },
        // { field: "lokasi", headerName: "Lokasi", minWidth: 150 },
        {
            field: "lokasi",
            renderCell: (cellValues) => {
                return rules == "pdc" && cellValues.row.lokasi == "" ? (
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={(event) => {
                        handleClickOpen(cellValues);
                    }}
                >
                    Input Lokasi
                </Button>
                ) : (
                    <Typography>{cellValues.row.lokasi}</Typography>
                );
            },
            minWidth: 150,
        },
        { field: "customer", headerName: "Customer", minWidth: 150 },
        { field: "tgl_alo", headerName: "Tgl Alo", minWidth: 150 },
        { field: "sales", headerName: "Sales", minWidth: 150 },
        { field: "no_do", headerName: "No. DO", minWidth: 150 },
        { field: "tgl_do", headerName: "Tgl DO", minWidth: 150 },
        { field: "umur_alok", headerName: "Umur Alok", minWidth: 150 },
        { field: "umur_ar", headerName: "Umur AR", minWidth: 150 },
        { field: "ket", headerName: "Keterangan", minWidth: 150 },
        { field: "tipe_stock", headerName: "Type Stock", minWidth: 150 }, //type stock : 
        {
            field: "Aksi",
            renderCell: (cellEdit) => {
                return 
                    <Button
                        size="small"
                        variant="outlined"
                        color="warning"
                        startIcon={<EditIcon />}
                    >
                        Barter
                    </Button>
            },
            minWidth: 200,
        },
    ];
    
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            
            <Alert>Halo, {person}! Berikut Data Outstanding.</Alert>

            {/* Kategori Onhand */}
            <br></br>
            <Box style={{}}>
                <TableContainer style={{ height: 500, width: "100%" }}>
                    <DataGrid
                        rows={dataOnhand}
                        rowCount={rowCountStateOnhand}
                        loading={isLoading}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        page={pageOnhand}
                        pageSize={pageSizeOnhand}
                        paginationMode="server"
                        onPageChange={(newPage) => setPageOnhand(newPage)}
                        onPageSizeChange={(newPageSize) => setPageSizeOnhand(newPageSize)}
                        columns={columns}
                    />
                </TableContainer>
            </Box>
        </Box>
            
        
    );
}

export default Outstanding;