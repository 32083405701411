import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import Chip from "@mui/material/Chip";
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import swal from "sweetalert";
// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const token = localStorage.getItem("access_token");
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
}));

function Booking1000(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [refreshDt, setRefresh] = useState();
    const [inputs, setInputs] = useState([]);
    const [inputsKonfirmasi, setInputsKonfirmasi] = useState([]);

    const handleClickOpen = (event) => {
        console.log(event);
        setOpen(true);
        setInputs(values => ({...values, ["type"]: "feedback", ["id"]: event.idpil}));
    }

    const handleClickOpenDatang = (event) => {
        console.log(event);
        updatePost(event.idpil);
    }

    const handleClickOpenCancel = (event) => {
        cancelPost(event.idpil);
    }

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    // API List Data Prospek
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/area/mra/booking/seribu`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                    setLoading(false);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [refreshDt]);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const handleChangeInput = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const columns = [
        { field: "tgl", headerName: "Tgl Booking", headerClassName: "headerbg" },
        { field: "nama_cust", headerName: "Customer", minWidth: 200 },
        { field: "alamat", headerName: "Alamat", minWidth: 200 },
        { field: "telp", headerName: "No Telepon", minWidth: 200 },
        { field: "tipe", headerName: "Kendaraan", minWidth: 200 },
        { field: "no_rangka", headerName: "No Rangka", minWidth: 200 },
        { field: "nama_sales", headerName: "Sales", minWidth: 200 },
        { field: "Aksi", renderCell: (cellValues) => {
            return cellValues.row.feedback == null ? (
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={(event) => {
                        handleClickOpen(cellValues.row);
                    } }
                >
                    Feedback
                </Button>
            ) : (
                <Typography
                >
                    {cellValues.row.feedback}
                </Typography>
            );
        }, minWidth: 200, align: "center" },
        { field: "Konfirmasi", renderCell: (cellValues) => {
            return cellValues.row.datang1 != "Datang" ? (
                <Button
                    size="small"
                    variant="outlined"
                    style={{ background: "#26de81", border: "1px solid #26de81", color: "white" }}
                    startIcon={<EditIcon />}
                    onClick={(event) => {
                        handleClickOpenDatang(cellValues.row);
                    } }
                >
                    Datang
                </Button>
            ) : (
                <Button
                    size="small"
                    variant="outlined"
                    style={{ border: "1px solid #fc5c65", background: "#fc5c65", color: "white" }}
                    startIcon={<EditIcon />}
                    onClick={(event) => {
                        handleClickOpenCancel(cellValues.row);
                    } }
                >
                    Cancel
                </Button>
            );
        }, minWidth: 200, align: "center" },
    ];

    const [loadButton, setLoadButton] = React.useState(false);
    const handleSubmit = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios.post('https://api-funeling.kidsbjm.com/api/area/mra/process/cekseribu', inputs).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
                setRefresh(response);
                setOpen(false);
            }
        });
    };

    function updatePost(idpil) {
        setInputsKonfirmasi(values => ({...values,["tipeaksi"]: "datang"}));
        axios.post(`https://api-funeling.kidsbjm.com/api/area/mra/process/cekseribu`, {
            id: idpil,
            tipeaksi: "datang",
            type: ""
        })
        .then((response) => {
            swal("Success", 'Data Berhasil disimpan!', "success", {
                buttons: false,
                timer: 2000,
            });
            setRefresh(response);
        });
    }

    function cancelPost(idpil) {
        setInputsKonfirmasi(values => ({...values,["tipeaksi"]: ""}));
        axios.post(`https://api-funeling.kidsbjm.com/api/area/mra/process/cekseribu`, {
            id: idpil,
            tipeaksi: "",
            type: ""
        })
        .then((response) => {
            swal("Success", 'Data Berhasil disimpan!', "success", {
                buttons: false,
                timer: 2000,
            });
            setRefresh(response);
        });
    }
    //const rows = {dataProspek};
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography className="bg-white p-3 rounded-md shadow-lg">
                <b>Booking Cek 1000</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div className="bg-white p-3 rounded-md shadow-lg">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Bulan"
                        value={bulan}
                        onChange={handleChange}
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Tahun"
                        value={tahun}
                        onChange={handleChange2}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </div>
            </Box>
            <TableContainer style={{ height: 500, width: "100%" }} className="bg-white p-3 rounded-md shadow-lg">
                <DataGrid
                    rows={dataProspek}
                    // rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    // pagination
                    // page={page}
                    // pageSize={pageSize}
                    // paginationMode="server"
                    // onPageChange={(newPage) => setPage(newPage)}
                    // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>

            {/* Form Feedback */}
            <Box style={{ width: "100%" }}>
        
                <form>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        maxWidth="md"
                        fullWidth={fullWidth}
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        style={{ width: "100%" }}
                    >
                        <DialogTitle
                        style={{
                            backgroundColor: bgColors.Yellow,
                            marginBottom: "10px",
                            color: "#ffffff",
                        }}
                        >
                        {"Form Feedback"}
                        </DialogTitle>
                        <br></br>
                        <DialogContent>
                        <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                            <TextField
                                id="outlined-basic"
                                required
                                variant="outlined"
                                name="feedback"
                                label="Feedback"
                                onChange={handleChangeInput}
                                sx={{width: "100%"}}
                                size="small"
                                helperText="Feedback"
                            >
                            </TextField>
                        </Box>
                        </DialogContent>
                        <DialogActions>
                        {loadButton === true ? 
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                            :  
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmit}
                                > 
                                    <Typography>Submit</Typography>
                                </Button>
                            }
                        <Button onClick={handleClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </Box>
        </Box>
    );
}

export default Booking1000;
