import React, { useEffect, useState } from "react";
import './App.css';
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import StreetviewIcon from "@mui/icons-material/Streetview";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import FestivalIcon from "@mui/icons-material/Festival";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SubjectIcon from "@mui/icons-material/Subject";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ShareIcon from '@mui/icons-material/Share';
import LOGO_BUJ_LIGHT from "./images/toyota-1.png";

// LOGIN
import Login from "./components/login/login";
import HomeOverview from "./components/admin/Dashboard";
import SListDataProspek from "./components/admin/SListDataProspek";
import SListDataSPK from "./components/admin/SListDataSPK";
import SListDataDo from "./components/admin/SListDataDo";
import SListDataCr1 from "./components/admin/SListDataCr1";
import SListDataCr2 from "./components/admin/SListDataCr2";
import SLaporanSales from "./components/admin/SLaporanSales";
import SCorongProspek from "./components/admin/SCorongProspek";
import SLaporanSalesSumber from "./components/admin/SLaporanSalesSumber";
import SPenyebaranKecamatan from "./components/admin/SPenyebaranKecamatan";
import SFollupDo from "./components/admin/SFollupDo";
import SListUltah from "./components/admin/SListUltah";

// SPV
import SpvOverview from "./components/SPV/SpvOverview";
import SpvListProspek from "./components/SPV/SpvListProspek";
import SpvListDataSpk from "./components/SPV/SpvListDataSpk";
import SpvListDataDo from "./components/SPV/SpvListDataDo";
import SpvPenyebaranKec from "./components/SPV/SpvPenyebaranKec";
import SpvLaporMonth from "./components/SPV/SpvLaporMonth";
import SpvLaporanSales from "./components/SPV/SpvLaporanSales";
import SpvListBengkel from "./components/SPV/SpvListBengkel"; 
import SpvListProspekSa from "./components/SPV/SpvListProspekSa";
import SpvListBooking from "./components/SPV/SpvListBooking";
import SpvCorongTeams from "./components/SPV/SpvCorongTeams";
import SpvCorongSales from "./components/SPV/SpvCorongSales";

// SALES
import SalesProspek from "./components/sales/SalesProspek";
import SalesCorong from "./components/sales/SalesCorong";
import SalesIndex from "./components/Common/Sales/SalesIndex";
import InputHarian from "./components/Common/Sales/InputHarian";
import InputSpk from "./components/Common/Sales/InputSpk";
import InputDo from "./components/Common/Sales/InputDo";
import InputCr1 from "./components/Common/Sales/InputCr1";
import InputCr1000 from "./components/Common/Sales/InputCr1000";
import InputCr2 from "./components/Common/Sales/InputCr2";
import DataCustomer from "./components/Common/Sales/DataCustomer";

// STOCK
import InputMaster from "./components/Stock/InputMaster";
import RequestStock from "./components/Stock/RequestStock";

// BOOKING
import DataBooking from "./components/Common/Booking/DataBooking";
import InputBooking from "./components/Common/Booking/InputBooking";
import ListBooking from "./components/Common/Booking/ListBooking";

// LIST DATA - AREA SALES
import LharianSales from "./components/Common/Sales/List/LharianSales";
import LspkSales from "./components/Common/Sales/List/LspkSales";
import LdoSales from "./components/Common/Sales/List/LdoSales";
import LbengkelSales from "./components/Common/Sales/List/LbengkelSales";
import LprospeksaSales from "./components/Common/Sales/List/LprospeksaSales";

// OTHER AREA SALES
import RekapSales from "./components/Common/Sales/Other/RekapSales";

// Area MRA
import ImportDataBengkel from "./components/Common/Mra/ImportDataBengkel";
import ListDataDo from "./components/Common/Mra/ListDataDo"; 
import OverviewCustPasif from "./components/Common/Mra/OverviewCustPasif"; 
import InputData from "./components/Common/Mra/InputData"; 
import ListDataHariansa from "./components/Common/Mra/ListDataHariansa"; 
import Corong from "./components/Common/Mra/Corong"; 
import Kecamatan from "./components/Common/Mra/Kecamatan"; 
import Booking1000 from "./components/Common/Mra/Booking1000";
import BookingLain from "./components/Common/Mra/BookingLain";
import BookingCustPasif from "./components/Common/Mra/BookingCustPasif";
import RekapBooking from "./components/Common/Mra/RekapBooking";
import ListLapBooking from "./components/Common/Mra/ListLapBooking";
import ListProspekSaMra from "./components/Common/Mra/ListProspekSaMra";
import CorongProspekSa from "./components/Common/Mra/CorongProspekSa";

// Area Login SA
import ListKendaraan from "./components/Common/Sa/CekService/ListKendaraan";
import InputService from "./components/Common/Sa/CekService/InputService";
import InputDataProspek from "./components/Common/Sa/Prospek/InputDataProspek";
import ListProspek from "./components/Common/Sa/Prospek/ListProspek";

// Area Login CRC
import ListUltah from "./components/Common/Crc/ListUltah";
import ListSeribu from "./components/Common/Crc/ListSeribu";
import ListCr1 from "./components/Common/Crc/ListCr1";
import ListCr2 from "./components/Common/Crc/ListCr2";

// KECAMATAN
import KecProspek from "./components/Kecamatan/KecProspek";
import KecCorong from "./components/Kecamatan/KecCorong";

// BENGKEL
import OverviewBengkel from "./components/Bengkel/Overview";
import ListBengkel from "./components/Bengkel/ListBengkel";

// PAMERAN
import Poverview from "./components/Pameran/Poverview";
import PameranList from "./components/Pameran/PameranList";

// Prospek SA
import SaProspek from "./components/SA/SaProspek";
import SaCorong from "./components/SA/SaCorong";

//Other
import OtherEditKategori from "./components/Other/OtherEditKategori";
import User from "./components/Other/User";

//Stock
import OverviewStock from "./components/Stock/OverviewStock";
import InputOnHand from "./components/Stock/InputOnHand";
import Arunit from "./components/Stock/Arunit";
import Swapping from "./components/Stock/Swapping";
import DoStock from "./components/Stock/DoStock";
import LsDoStock from "./components/Stock/LsDoStock";
import Outstanding from "./components/Stock/Outstanding";
//import InputSupply from "./components/Stock/InputSupply";

//Sharing DB
import ListSharingData from "./components/admin/ListSharingData";
import ImportCustomer from "./components/admin/ImportCustomer";

// Area Markom
import InputPameran from "./components/Common/Markom/InputPameran";
import InputKecamatan from "./components/Common/Markom/InputKecamatan";
import InputKelurahan from "./components/Common/Markom/InputKelurahan";

import { BrowserRouter as Router, Routes, Route, Link, useScrollRestoration } from "react-router-dom";
import SaIndex from "./components/Common/Sa/SaIndex";
import InputDataPasif from "./components/Common/Sa/CustomerPasif/InputDataPasif"; 
import FollowUp from "./components/Common/Sa/CustomerPasif/FollowUp"; 
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CarRentalIcon from '@mui/icons-material/CarRental';
import { LocalShipping } from "@material-ui/icons";
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import BallotIcon from '@mui/icons-material/Ballot';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import InsertChartIcon from '@mui/icons-material/InsertChart';

// let image = require("/images/logoaims.png");
const checkToken = () => {
  window.location.href = "/";
};

const drawerWidth = 240;
var bgColors = {
  Default: "#81b71a",
  Blue: "#00B1E1",
  Cyan: "#37BC9B",
  Green: "#8CC152",
  Red: "#9e0000",
  Yellow: "#F6BB42",
};
const useStyles = makeStyles((theme) => ({
  headerimage: {
    float: "left",
    width: "15%",
    marginTop: "8%",
    marginLeft: "10%",
    marginRight: "10%"
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  toolbarM: {
    backgroundColor: "#ffffff",
    color: "#000000"
  },
}));

function App(props) {
  const { window } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const person = JSON.parse(localStorage.getItem("person"));
  const rules = JSON.parse(localStorage.getItem("rules"));
  var cabang = JSON.parse(localStorage.getItem("id_cabang"));
  if (cabang == 1) {
    cabang = "Ahmad Yani";
  } else if (cabang == 2) {
    cabang = "Dago";
  } else if (cabang == 3) {
    cabang = "Padalarang";
  } else if (cabang == 4) {
    cabang = "Subang";
  } else {
    cabang = "Unidentified";
  }
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [open2, setOpen] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [open7, setOpen7] = React.useState(false);
  const [open8, setOpen8] = React.useState(false);
  const [openSpv, setOpenSpv] = React.useState(false);
  const [openSharing, setOpenSharing] = React.useState(false);
  const [openInputData, setInputData] = React.useState(false);
  const [openInputData2, setInputData2] = React.useState(false);
  const [openInputData3, setInputData3] = React.useState(false);
  const [openInputData4, setInputData4] = React.useState(false);
  const [openSubMenuSa1, setMenuSa1] = React.useState(false);
  const [openSubMenuSa2, setMenuSa2] = React.useState(false);
  const [openSubMenuSa3, setMenuSa3] = React.useState(false);
  const [openstock, setMenuStock] = React.useState(false);
  const [openstockcab, setMenuStockCab] = React.useState(false);
  const [openlistdo, setMenuListDo] = React.useState(false);
  const [opencustomerpasif, setMenuCustomerPasif] = React.useState(false);
  const [openlistbooking, setMenuListBooking] = React.useState(false);
  const [openlaporanbooking, setMenuLaporanBooking] = React.useState(false);
  const [openprospeksamra, setMenuProspeksaMra] = React.useState(false);
  const [opencrc, setMenuCrc] = React.useState(false);
  const [openmarkom, setMenuMarkom] = React.useState(false);
  const [spv, setSpv] = useState([]);
  const [showdrop, setDrop] = React.useState({});

  const token = localStorage.getItem("access_token");
  // let params = useParams();
  const id_cabang = JSON.parse(localStorage.getItem("id_cabang"));
  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const getData = async () => {
      const url =
        "https://api-funeling.kidsbjm.com/api/spv/cabang/" + id_cabang;
      try {
        const response = await axios.get(url);

        setSpv(response.data.data);
        
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  // console.log(spv);

  const handleClick = () => {
    setOpen(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };
  const handleClick4 = () => {
    setOpen4(!open4);
  };
  const handleClick5 = () => {
    setOpen5(!open5);
  };
  const handleClick6 = () => {
    setOpen6(!open6);
  };
  const handleClick7 = () => {
    setOpen7(!open7);
  };
  const handleClick8 = () => {
    setOpen8(!open8);
  };
  const handleClickSpv = () => {
    setOpenSpv(!openSpv);
  };
  const handleClickSharing = () => {
    setOpenSharing(!openSharing);
  };

  const handleClickInputData = () => {
    setInputData(!openInputData);
  };
  const handleClickInputData2 = () => {
    setInputData2(!openInputData2);
  };
  const handleClickInputData3 = () => {
    setInputData3(!openInputData3);
  };
  const handleClickInputData4 = () => {
    setInputData4(!openInputData4);
  };
  const handleMenuSa1 = () => {
    setMenuSa1(!openSubMenuSa1);
  };
  const handleMenuSa2 = () => {
    setMenuSa2(!openSubMenuSa2);
  };
  const handleMenuSa3 = () => {
    setMenuSa3(!openSubMenuSa3);
  };  
  const handleClickStock = () => {
    setMenuStock(!openstock);
  };  
  const handleClickStockCab = () => {
    setMenuStockCab(!openstockcab);
  };  
  const handleClickListDo = () => {
    setMenuListDo(!openlistdo);
  };  
  const handleClickCustomerPasif = () => {
    setMenuCustomerPasif(!opencustomerpasif);
  };  
  const handleClickListBooking = () => {
    setMenuListBooking(!openlistbooking);
  };  
  const handleClickLaporanBooking = () => {
    setMenuLaporanBooking(!openlaporanbooking);
  };  
  const handleClickProspekSaMra = () => {
    setMenuProspeksaMra(!openprospeksamra);
  };  
  const handleClickCrc = () => {
    setMenuCrc(!opencrc);
  };  
  const handleClickMarkom = () => {
    setMenuMarkom(!openmarkom);
  };  
  
  const handleClickCloseMenu = () => {
    setMobileOpen(!mobileOpen);
  };  
  

  // if (token == null) {
  //   checkToken();
  //   return false;
  // }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("person");
    localStorage.removeItem("id_cabang");
    const token = localStorage.getItem("access_token");
    if (token == null) {
      checkToken();
      return false;
    }
    //window.location.href = "/";
    //return false;
  };

  const handleDrop = (id) => () => {
    setDrop((showdrop) => ({
      ...showdrop,
      [id]: !showdrop[id],
    }));
  };

  function changeLocation(placeToGo) {
    // navigate(placeToGo, { replace: true });
    window.location.reload();
  }

  const menusales = () => {};

  const drawer = (
    <div className="bg-red-800 text-white">
      <img
        src={LOGO_BUJ_LIGHT}
        alt="logo"
        className={classes.headerimage}
      />
      <Typography style={{marginBottom: "10%", fontSize: "14px", marginTop: "8%"}}>
        <b>
          <i> {"KIDS"} </i>
        </b>
      </Typography>
      <Divider />
      <List>
        <Link to="/area/sales/list/datacustomer" replace style={{ textDecoration: "none", color: "white" }} onClick={handleClickCloseMenu} >
          <ListItemButton>
            <ListItemIcon>
              <RecentActorsIcon/>
            </ListItemIcon>
            <ListItemText primary="Database Customer" />
          </ListItemButton>
        </Link>
      </List>
      {rules == "admin" ? (
        <List>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Summary" />
            {open2 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/dashboard"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Overview" />
                </ListItemButton>
              </Link>
              <Link
                to="/slistdataprospek"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Prospek" />
                </ListItemButton>
              </Link>
              <Link
                to="/slistdataspk"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data SPK" />
                </ListItemButton>
              </Link>
              <Link
                to="/slistdatado"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data DEC" />
                </ListItemButton>
              </Link>
              <Link
                to="/slistdatacr1"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data CR1" />
                </ListItemButton>
              </Link>
              <Link
                to="/slistdatacr2"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data CR2" />
                </ListItemButton>
              </Link>
              <Link
                to="/scorongprospek"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Funneling Prospek" />
                </ListItemButton>
              </Link>
              <Link
                to="/slaporankategori"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Laporan Sales (Kategori)" />
                </ListItemButton>
              </Link>
              <Link
                to="/slaporansumber"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Laporan Sales (Sumber)" />
                </ListItemButton>
              </Link>
              <Link
                to="/spenyebarankec"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Penyebaran Kecamatan" />
                </ListItemButton>
              </Link>
              <Link
                to="/sfollupdo"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Data Follow Up DEC" />
                </ListItemButton>
              </Link>
              <Link
                to="/slistultah"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Ulang Tahun" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      {/* {rules == "admin" ? (
        <List>
          <ListItemButton onClick={handleClickSharing}>
            <ListItemIcon>
              <ShareIcon />
            </ListItemIcon>
            <ListItemText primary="Sharing Database" />
            {openSharing ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSharing} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/sharing/db/inputdata" 
                replace
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Input Data" />
                </ListItemButton>
              </Link>
              <Link
                to="/sharing/db/listdata" 
                replace
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : ( 
        "" 
      )} */}
      {/* <List>
        <ListItemButton onClick={handleClick3}>
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary={hasilSpv} />
          {open3 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open3} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemText primary="List Data Prospek" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemText primary="Corong Prospek" />
            </ListItemButton>
          </List>
        </Collapse>
      </List> */}
      {rules == "admin"
        ? spv.map((val, idx) => (
            <List key={idx}>
              <ListItemButton onClick={handleDrop(idx)}>
                <ListItemIcon>
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText primary={"Team " + val.nama_spv} />
                {showdrop[idx] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={showdrop[idx]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link
                    to={"/spvoverview/" + val.nama_spv}
                    onClick={() =>
                      changeLocation("/spvoverview/" + val.nama_spv)
                    }
                    replace
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="Overview" />
                    </ListItemButton>
                  </Link>
                  <Link
                    to={"/spvlistprospek/" + val.nama_spv}
                    onClick={() =>
                      changeLocation("/spvlistprospek/" + val.nama_spv)
                    }
                    replace
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="List Data Prospek" />
                    </ListItemButton>
                  </Link>
                  <Link
                    to={"/spvlistdataspk/" + val.nama_spv}
                    onClick={() =>
                      changeLocation("/spvlistdataspk/" + val.nama_spv)
                    }
                    replace
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="List Data SPK" />
                    </ListItemButton>
                  </Link>
                  <Link
                    to={"/spvlistdatado/" + val.nama_spv}
                    onClick={() =>
                      changeLocation("/spvlistdatado/" + val.nama_spv)
                    }
                    replace
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="List Data DEC" />
                    </ListItemButton>
                  </Link>
                  <Link
                    to={"/spvcorongteams/" + val.nama_spv}
                    onClick={() =>
                      changeLocation("/spvcorongteams/" + val.nama_spv)
                    }
                    replace
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="Funneling Prospek Team" />
                    </ListItemButton>
                  </Link>
                  <Link
                    to={"/spvcorongsales/" + val.nama_spv}
                    onClick={() =>
                      changeLocation("/spvcorongsales/" + val.nama_spv)
                    }
                    replace
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="Funneling Prospek Sales" />
                    </ListItemButton>
                  </Link>
                  <Link
                    to={"/spvpenyebarankec/" + val.nama_spv}
                    onClick={() =>
                      changeLocation("/spvpenyebarankec/" + val.nama_spv)
                    }
                    replace
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="Penyebaran Kecamatan" />
                    </ListItemButton>
                  </Link>
                  <Link
                    to={"/spvlapormonth/" + val.nama_spv}
                    onClick={() =>
                      changeLocation("/spvlapormonth/" + val.nama_spv)
                    }
                    replace
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="Laporan Bulanan" />
                    </ListItemButton>
                  </Link>
                  <Link
                    to={"/spvlaporsales/" + val.nama_spv}
                    onClick={() =>
                      changeLocation("/spvlaporsales/" + val.nama_spv)
                    }
                    replace
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="Laporan Sales" />
                    </ListItemButton>
                  </Link>
                  <Link
                    to={"/spvlistbengkel/" + val.nama_spv}
                    onClick={() =>
                      changeLocation("/spvlistbengkel/" + val.nama_spv)
                    }
                    replace
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="List Data Bengkel" />
                    </ListItemButton>
                  </Link>
                  <Link
                    to={"/spvlistprospeksa/" + val.nama_spv}
                    onClick={() =>
                      changeLocation("/spvlistprospeksa/" + val.nama_spv)
                    }
                    replace
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="List Data Prospek SA" />
                    </ListItemButton>
                  </Link>
                  <Link
                    to={"/spvlistbooking/" + val.nama_spv}
                    onClick={() =>
                      changeLocation("/spvlistbooking/" + val.nama_spv)
                    }
                    replace
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="List Booking" />
                    </ListItemButton>
                  </Link>
                </List>
              </Collapse>
            </List>
          ))
        : ""}

      {rules == "admin" ? (
        <List>
          <ListItemButton onClick={handleClick3}>
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText primary="Sales" />
            {open3 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={"/salesprospek/"}
                onClick={() => changeLocation("/salesprospek/")}
                replace
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Prospek" />
                </ListItemButton>
              </Link>
              <Link
                to={"/salescorong/"}
                onClick={() => changeLocation("/salescorong/")}
                replace
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Funneling Prospek" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      {rules == "admin" ? (
        <List>
          <ListItemButton onClick={handleClick4}>
            <ListItemIcon>
              <StreetviewIcon />
            </ListItemIcon>
            <ListItemText primary="Kecamatan" />
            {open4 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open4} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/kecamatanprospek"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Prospek" />
                </ListItemButton>
              </Link>
              <Link
                to="/kecamatancorong"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Funneling Prospek" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      {rules == "admin" ? (
        <List>
          <ListItemButton onClick={handleClick5}>
            <ListItemIcon>
              <PrecisionManufacturingIcon />
            </ListItemIcon>
            <ListItemText primary="Data Bengkel" />
            {open5 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open5} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/overviewbengkel"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Overview" />
                </ListItemButton>
              </Link>
              <Link
                to="/listbengkel"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Bengkel" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      {rules == "admin" ? (
        <List>
          <ListItemButton onClick={handleClick6}>
            <ListItemIcon>
              <FestivalIcon />
            </ListItemIcon>
            <ListItemText primary="Data Pameran" />
            {open6 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open6} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/overviewpameran"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Overview" />
                </ListItemButton>
              </Link>
              <Link
                to="/listpameran"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Pameran" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}
      {rules == "admin" ? (
        <List>
          <ListItemButton onClick={handleClick7}>
            <ListItemIcon>
              <EngineeringIcon />
            </ListItemIcon>
            <ListItemText primary="Prospek SA" />
            {open7 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open7} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/salistprospek"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Prospek" />
                </ListItemButton>
              </Link>
              <Link
                to="/sacorongprospek"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Funneling Data Prospek" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}
      {rules == "admin" ? (
        <List>
          <ListItemButton onClick={handleClick8}>
            <ListItemIcon>
              <SubjectIcon />
            </ListItemIcon>
            <ListItemText primary="Other" />
            {open8 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open8} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/othereditkategori"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Edit Kategori" />
                </ListItemButton>
              </Link>
              <Link
                to="/user"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Tambah / Edit User" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      <Divider />

      {/* {rules == "sales" ? ( */}

        {/* <List>
          <Link to="/area/sales/list/datacustomer" replace style={{ textDecoration: "none", color: "white" }} onClick={handleClickCloseMenu} >
            <ListItemButton>
              <ListItemIcon>
                <RecentActorsIcon/>
              </ListItemIcon>
              <ListItemText primary="Database Customer" />
            </ListItemButton>
          </Link>
        </List> */}

      {/* ) : (
        ""
      )} */}

      {rules == "sales" ? (
        <List>
          <ListItemButton onClick={handleClickInputData}>
            <ListItemIcon>
              <FolderOpenIcon />
            </ListItemIcon>
            <ListItemText primary="Input Data" />
            {openInputData ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openInputData} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/sales/input/harian"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Harian" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sales/input/spk"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="SPK" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sales/input/do"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="DEC" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sales/input/cr1"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="CR 1" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sales/input/cr1000"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Service Pertama" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sales/input/cr2"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="CR 2" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      {rules == "sales" ? (
        <List>
          <ListItemButton onClick={handleClickInputData2}>
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="List Data" />
            {openInputData2 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openInputData2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/othereditkategori"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Edit Kategori" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sales/listdata/harian"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Harian" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sales/listdata/spk"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data SPK" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sales/listdata/do"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data DEC" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sales/listdata/bengkel"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Bengkel" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sales/listdata/prospeksa"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Prospek SA" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      {rules == "sales" ? (
        <List>
          <ListItemButton onClick={handleClickInputData3}>
            <ListItemIcon>
              <BookmarksIcon />
            </ListItemIcon>
            <ListItemText primary="Booking" />
            {openInputData3 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openInputData3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/sales/booking/list"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Data Booking Service Pertama" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sales/booking/input"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Input Booking" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sales/booking/listdata"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Booking" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      {rules == "sales" ? (
        <List>
          <ListItemButton onClick={handleClickInputData4}>
            <ListItemIcon>
              <FolderOpenIcon />
            </ListItemIcon>
            <ListItemText primary="Other" />
            {openInputData4 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openInputData4} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/sales/other/rekapsales"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Rekap Sales" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      {rules == "spv" ? (
        <List>
          <ListItemButton onClick={handleClickSpv}>
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary={"Team " + person} />
            {openSpv ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSpv} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={"/spvoverview/" + person}
                onClick={() =>
                  changeLocation("/spvoverview/" + person)
                }
                replace
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Overview" />
                </ListItemButton>
              </Link>
              <Link
                to={"/spvlistprospek/" + person}
                onClick={() =>
                  changeLocation("/spvlistprospek/" + person)
                }
                replace
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Prospek" />
                </ListItemButton>
              </Link>
              <Link
                to={"/spvlistdataspk/" + person}
                onClick={() =>
                  changeLocation("/spvlistdataspk/" + person)
                }
                replace
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data SPK" />
                </ListItemButton>
              </Link>
              <Link
                to={"/spvlistdatado/" + person}
                onClick={() =>
                  changeLocation("/spvlistdatado/" + person)
                }
                replace
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data DEC" />
                </ListItemButton>
              </Link>
              <Link
                to={"/spvcorongteams/" + person}
                onClick={() =>
                  changeLocation("/spvcorongteams/" + person)
                }
                replace
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Funneling Prospek Team" />
                </ListItemButton>
              </Link>
              <Link
                to={"/spvcorongsales/" + person}
                onClick={() =>
                  changeLocation("/spvcorongsales/" + person)
                }
                replace
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Funneling Prospek Sales" />
                </ListItemButton>
              </Link>
              <Link
                to={"/spvpenyebarankec/" + person}
                onClick={() =>
                  changeLocation("/spvpenyebarankec/" + person)
                }
                replace
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Penyebaran Kecamatan" />
                </ListItemButton>
              </Link>
              <Link
                to={"/spvlapormonth/" + person}
                onClick={() =>
                  changeLocation("/spvlapormonth/" + person)
                }
                replace
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Laporan Bulanan" />
                </ListItemButton>
              </Link>
              <Link
                to={"/spvlaporsales/" + person}
                onClick={() =>
                  changeLocation("/spvlaporsales/" + person)
                }
                replace
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Laporan Sales" />
                </ListItemButton>
              </Link>
              <Link
                to={"/spvlistbengkel/" + person}
                onClick={() =>
                  changeLocation("/spvlistbengkel/" + person)
                }
                replace
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Bengkel" />
                </ListItemButton>
              </Link>
              <Link
                to={"/spvlistprospeksa/" + person}
                onClick={() =>
                  changeLocation("/spvlistprospeksa/" + person)
                }
                replace
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Prospek SA" />
                </ListItemButton>
              </Link>
              <Link
                to={"/spvlistbooking/" + person}
                onClick={() =>
                  changeLocation("/spvlistbooking/" + person)
                }
                replace
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Booking" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      {rules == "sa" ? (
        <List>
          <ListItemButton onClick={handleMenuSa2}>
            <ListItemIcon>
              <CarRentalIcon />
            </ListItemIcon>
            <ListItemText primary="Cek Service" />
            {openSubMenuSa2 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSubMenuSa2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/sa/cekservice/liskendaraan"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Service" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sa/cekservice/inputservice"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Input Service" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      {rules == "sa" ? (
        <List>
          <ListItemButton onClick={handleMenuSa1}>
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="Customer Pasif" />
            {openSubMenuSa1 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSubMenuSa1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/sa/customerpasif/input"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Input Data" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/sa/customerpasif/followupsa"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Follow Up SA" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      {rules == "sa" ? (
        <List>
          <ListItemButton onClick={handleMenuSa3}>
            <ListItemIcon>
              <InsertChartIcon />
            </ListItemIcon>
            <ListItemText primary="Prospek" />
            {openSubMenuSa3 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSubMenuSa3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/sa/prospek/inputprospek"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Input Prospek" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/area/sa/prospek/listprospek"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Prospek" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
      ) : (
        ""
      )}

      {rules == "skeeperho" ? (
        <List>
          <List>
              <Link to="/stock/overviewstock" replace style={{ textDecoration: "none", color: "black" }} onClick={handleClickCloseMenu} >
                <ListItemButton>
                  <ListItemIcon>
                    <AccessTimeFilledIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Stock Hari ini" />
                </ListItemButton>
              </Link>
          </List>
          <List>
              <Link to="/stock/requeststock" replace style={{ textDecoration: "none", color: "black" }} onClick={handleClickCloseMenu} >
                <ListItemButton>
                  <ListItemIcon>
                    <LibraryAddCheckIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Request Stock" />
                </ListItemButton>
              </Link>
          </List>
          <ListItemButton onClick={handleClickStockCab}>
            <ListItemIcon>
              <CarRentalIcon />
            </ListItemIcon>
            <ListItemText primary="Stock Manager" />
            {openstockcab ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openstockcab} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              
              <Link
                to="/stock/inputonhand"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Input on Hand" />
                </ListItemButton>
              </Link>
              <Link
                to="/stock/inputsupply"
                replace
                style={{ textDecoration: "none", color: "black", display: "none" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Input Supply" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/stock/outstanding"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Outstanding" />
                </ListItemButton>
              </Link>
            </List>
            {spv.map((val, idx) => (              
              <List key={idx} component="div" disablePadding>
                <Link
                  to={"/stock/arunitpenjualan/" + val.nama_spv}
                  onClick={() =>
                    changeLocation("/stock/arunitpenjualan/" + val.nama_spv)
                  }
                  replace
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText primary={"AR Unit " + val.nama_spv} />
                  </ListItemButton>
                </Link>
              </List>
            ))}
          </Collapse>
          <List>
              <Link to="/stock/dostock" replace style={{ textDecoration: "none", color: "black" }} onClick={handleClickCloseMenu} >
                <ListItemButton>
                  <ListItemIcon>
                    <LocalShipping/>
                  </ListItemIcon>
                  <ListItemText primary="Delivery Order" />
                </ListItemButton>
              </Link>
          </List>
          <List>
              <Link to="/stock/list/dostock" replace style={{ textDecoration: "none", color: "black" }} onClick={handleClickCloseMenu} >
                <ListItemButton>
                  <ListItemIcon>
                    <BallotIcon/>
                  </ListItemIcon>
                  <ListItemText primary="List Delivery Order" />
                </ListItemButton>
              </Link>
          </List>
          <List>
              <Link to="/stock/swappingunit" replace style={{ textDecoration: "none", color: "black" }} >
                <ListItemButton>
                  <ListItemIcon>
                    <SwapHorizontalCircleIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Swapping Unit" />
                </ListItemButton>
              </Link>
          </List>
        </List>
      ) : (
        ""
      )}

      {rules == "skeepercabang" ? (
        <List>
          <List>
              <Link to="/stock/overviewstock" replace style={{ textDecoration: "none", color: "black" }} onClick={handleClickCloseMenu} >
                <ListItemButton>
                  <ListItemIcon>
                    <AccessTimeFilledIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Stock Hari ini" />
                </ListItemButton>
              </Link>
          </List>
          <ListItemButton onClick={handleClickStockCab}>
            <ListItemIcon>
              <CarRentalIcon />
            </ListItemIcon>
            <ListItemText primary="Stock Manager" />
            {openstockcab ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openstockcab} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              
              <Link
                to="/stock/inputonhand"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Input on Hand" />
                </ListItemButton>
              </Link>
              <Link
                to="/stock/inputsupply"
                replace
                style={{ textDecoration: "none", color: "black", display: "none" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Input Supply" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/stock/outstanding"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Outstanding" />
                </ListItemButton>
              </Link>
            </List>
            {spv.map((val, idx) => (              
              <List key={idx} component="div" disablePadding>
                <Link
                  to={"/stock/arunitpenjualan/" + val.nama_spv}
                  onClick={() =>
                    changeLocation("/stock/arunitpenjualan/" + val.nama_spv)
                  }
                  replace
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText primary={"AR Unit " + val.nama_spv} />
                  </ListItemButton>
                </Link>
              </List>
            ))}
          </Collapse>
          <List>
              <Link to="/stock/dostock" replace style={{ textDecoration: "none", color: "black" }} onClick={handleClickCloseMenu} >
                <ListItemButton>
                  <ListItemIcon>
                    <LocalShipping/>
                  </ListItemIcon>
                  <ListItemText primary="Delivery Order" />
                </ListItemButton>
              </Link>
          </List>
          <List>
              <Link to="/stock/list/dostock" replace style={{ textDecoration: "none", color: "black" }} onClick={handleClickCloseMenu} >
                <ListItemButton>
                  <ListItemIcon>
                    <LocalShipping/>
                  </ListItemIcon>
                  <ListItemText primary="List Delivery Order" />
                </ListItemButton>
              </Link>
          </List>
          <List>
              <Link to="/stock/swappingunit" replace style={{ textDecoration: "none", color: "black" }} onClick={handleClickCloseMenu} >
                <ListItemButton>
                  <ListItemIcon>
                    <SwapHorizontalCircleIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Swapping Unit" />
                </ListItemButton>
              </Link>
          </List>
        </List>
      ) : (
        ""
      )}

      {rules == "pdc" ? (
        <List>
          <List>
              <Link to="/stock/overviewstock" replace style={{ textDecoration: "none", color: "black" }} >
                <ListItemButton>
                  <ListItemIcon>
                    <AccessTimeFilledIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Stock Hari ini" />
                </ListItemButton>
              </Link>
          </List>
          <ListItemButton onClick={handleClickStockCab}>
            <ListItemIcon>
              <CarRentalIcon />
            </ListItemIcon>
            <ListItemText primary="Stock Manager" />
            {openstockcab ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openstockcab} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              
              <Link
                to="/stock/inputonhand"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Input on Hand" />
                </ListItemButton>
              </Link>
              <Link
                to="/stock/inputsupply"
                replace
                style={{ textDecoration: "none", color: "black", display: "none" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Input Supply" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/stock/outstanding"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Outstanding" />
                </ListItemButton>
              </Link>
            </List>
            {spv.map((val, idx) => (              
              <List key={idx} component="div" disablePadding>
                <Link
                  to={"/stock/arunitpenjualan/" + val.nama_spv}
                  onClick={() =>
                    changeLocation("/stock/arunitpenjualan/" + val.nama_spv)
                  }
                  replace
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText primary={"AR Unit " + val.nama_spv} />
                  </ListItemButton>
                </Link>
              </List>
            ))}
          </Collapse>
          <List>
              <Link to="/stock/dostock" replace style={{ textDecoration: "none", color: "black" }} onClick={handleClickCloseMenu} >
                <ListItemButton>
                  <ListItemIcon>
                    <LocalShipping/>
                  </ListItemIcon>
                  <ListItemText primary="Delivery Order" />
                </ListItemButton>
              </Link>
          </List>
          <List>
              <Link to="/stock/swappingunit" replace style={{ textDecoration: "none", color: "black" }} onClick={handleClickCloseMenu} >
                <ListItemButton>
                  <ListItemIcon>
                    <SwapHorizontalCircleIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Swapping Unit" />
                </ListItemButton>
              </Link>
          </List>
        </List>
      ) : (
        ""
      )}

      {rules == "mra" ? (
        <List>
          <ListItemButton onClick={handleClick5}>
            <ListItemIcon>
              <PrecisionManufacturingIcon />
            </ListItemIcon>
            <ListItemText primary="Data Bengkel" />
            {open5 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open5} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/overviewbengkel"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Overview" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/mra/importdatabengkel"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Import Data Bengkel" />
                </ListItemButton>
              </Link>
              <Link
                to="/listbengkel"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Bengkel" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
          <ListItemButton onClick={handleClickListDo}>
            <ListItemIcon>
              <BallotIcon />
            </ListItemIcon>
            <ListItemText primary="DO Sales" />
            {openlistdo ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openlistdo} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/mra/listdatado"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List DEC" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
          <ListItemButton onClick={handleClickCustomerPasif}>
            <ListItemIcon>
              <GroupRemoveIcon />
            </ListItemIcon>
            <ListItemText primary="Customer Pasif" />
            {opencustomerpasif ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={opencustomerpasif} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/mra/customerpasif/overview"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Overview" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/area/mra/customerpasif/inputdata"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Input Data" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/area/mra/customerpasif/listdata"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/area/mra/customerpasif/corong"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Funneling" />
                </ListItemButton>
              </Link>
              <List component="div" disablePadding>
              <Link
                to="/area/mra/customerpasif/kecamatan"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Kecamatan" />
                </ListItemButton>
              </Link>
            </List>
            </List>
          </Collapse>
          <ListItemButton onClick={handleClickListBooking}>
            <ListItemIcon>
              <AssignmentTurnedInIcon />
            </ListItemIcon>
            <ListItemText primary="Booking" />
            {openlistbooking ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openlistbooking} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/mra/booking/cekseribu"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Booking Cek 1000" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/area/mra/booking/lain"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Booking Lain-Lain" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/area/mra/booking/customerpasif"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Booking Customer Pasif" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
          <ListItemButton onClick={handleClickLaporanBooking}>
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="Laporan Booking" />
            {openlaporanbooking ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openlaporanbooking} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/mra/lapbooking/rekap"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Rekap Booking" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/area/mra/lapbooking/list"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Booking" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
          <ListItemButton onClick={handleClickProspekSaMra}>
            <ListItemIcon>
              <EngineeringIcon />
            </ListItemIcon>
            <ListItemText primary="Prospek SA dari Sales" />
            {openprospeksamra ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openprospeksamra} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/mra/prospeksa/list"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data Prospek" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/area/mra/prospeksa/corong"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Funneling Data Prospek" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
        
      ) : (
        ""
      )}

      {rules == "crc" ? (
        <List>
          <ListItemButton onClick={handleClickCrc}>
            <ListItemIcon>
              <PrecisionManufacturingIcon />
            </ListItemIcon>
            <ListItemText primary="CRC" />
            {opencrc ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={opencrc} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/crc/ultah"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Ulang Tahun" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/crc/listseribukm"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List 1000 KM" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/crc/listcr1"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data CR1" />
                </ListItemButton>
              </Link>
              <Link
                to="/area/crc/listcr2"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="List Data CR2" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
        
      ) : (
        ""
      )}

      {rules == "markom" ? (
        <List>
          <ListItemButton onClick={handleClickMarkom}>
            <ListItemIcon>
              <PrecisionManufacturingIcon />
            </ListItemIcon>
            <ListItemText primary="MARKOM" />
            {openmarkom ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openmarkom} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="/area/markom/listpameran"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Pameran" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/area/markom/listkecamatan"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Kecamatan" />
                </ListItemButton>
              </Link>
            </List>
            <List component="div" disablePadding>
              <Link
                to="/area/markom/listkelurahan"
                replace
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClickCloseMenu}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Kelurahan" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </List>
        
      ) : (
        ""
      )}

      <Divider />
      <List>
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  // console.log(token);
  if (!token) {
    return <Login />;
  }

  const handleHideSide = () => {
    setOpen(false);
  }

  return (
    <Router>
      <Box sx={{ display: "flex"}} >
        {/* <CssBaseline /> */}
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` } 
          }}
          className="bg-red-800 text-white"
        >
          <Toolbar className={classes.toolbarM}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon sx={{color: "black"}} />
            </IconButton>
            <Typography noWrap component="div">
              Hi, <b> {person} </b>
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Routes>
          <Route exact path="/dashboard" element={<HomeOverview />}></Route>
          <Route
            path="/slistdataprospek"
            element={<SListDataProspek />}
          ></Route>
          <Route path="/slistdataspk" element={<SListDataSPK />} />
          <Route path="/slistdatado" element={<SListDataDo />} />
          <Route path="/slistdatacr1" element={<SListDataCr1 />} />
          <Route path="/slistdatacr2" element={<SListDataCr2 />} />
          <Route path="/scorongprospek" element={<SCorongProspek />} />
          <Route path="/slaporankategori" element={<SLaporanSales />} />
          <Route path="/slaporansumber" element={<SLaporanSalesSumber />} />
          <Route path="/spenyebarankec" element={<SPenyebaranKecamatan />} />
          <Route path="/sfollupdo" element={<SFollupDo />} />
          <Route path="/slistultah" element={<SListUltah />} />
          <Route path="/spvoverview/:nama_spv" element={<SpvOverview />} />
          <Route
            path="/spvlistprospek/:nama_spv"
            element={<SpvListProspek />}
          />
          <Route
            path="/spvlistdataspk/:nama_spv"
            element={<SpvListDataSpk />}
          />
          <Route path="/spvlistdatado/:nama_spv" element={<SpvListDataDo />} />
          <Route
            path="/spvpenyebarankec/:nama_spv"
            element={<SpvPenyebaranKec />}
          />
          <Route path="/spvlapormonth/:nama_spv" element={<SpvLaporMonth />} />
          <Route
            path="/spvlaporsales/:nama_spv"
            element={<SpvLaporanSales />}
          />
          <Route
            path="/spvlistbengkel/:nama_spv"
            element={<SpvListBengkel />}
          />
          <Route
            path="/spvlistprospeksa/:nama_spv"
            element={<SpvListProspekSa />}
          />
          <Route
            path="/spvlistbooking/:nama_spv"
            element={<SpvListBooking />}
          />
          <Route path="/OtherEditKategori" element={<OtherEditKategori />} />
          <Route
            path="/spvcorongteams/:nama_spv"
            element={<SpvCorongTeams />}
          />
          <Route
            path="/spvcorongsales/:nama_spv"
            element={<SpvCorongSales />}
          />
          <Route path="/salesprospek" element={<SalesProspek />} />
          <Route path="/salescorong" element={<SalesCorong />} />
          <Route path="/kecamatanprospek" element={<KecProspek />} />
          <Route path="/kecamatancorong" element={<KecCorong />} />
          <Route path="/overviewbengkel" element={<OverviewBengkel />} />
          <Route path="/listbengkel" element={<ListBengkel />} />
          <Route path="/user" element={<User />} />
          <Route path="/salistprospek" element={<SaProspek />} />
          <Route path="/sacorongprospek" element={<SaCorong />} />
          <Route path="/overviewpameran" element={<Poverview />} />
          <Route path="/listpameran" element={<PameranList />} />
          <Route path="/area/sales" element={<SalesIndex />} />
          <Route path="/area/sales/input/harian" element={<InputHarian />} />
          <Route path="/area/sales/input/spk" element={<InputSpk />} />
          <Route path="/area/sales/input/do" element={<InputDo />} />
          <Route path="/area/sales/input/cr1" element={<InputCr1 />} />
          <Route path="/area/sales/input/cr1000" element={<InputCr1000 />} />
          <Route path="/area/sales/input/cr2" element={<InputCr2 />} />
          <Route path="/area/sales/booking/list" element={<DataBooking />} />
          <Route path="/area/sales/booking/input" element={<InputBooking />} />
          <Route
            path="/area/sales/booking/listdata"
            element={<ListBooking />}
          />
          <Route
            path="/area/sales/listdata/harian"
            element={<LharianSales />}
          />
          <Route path="/area/sales/listdata/spk" element={<LspkSales />} />
          <Route path="/area/sales/listdata/do" element={<LdoSales />} />
          <Route
            path="/area/sales/listdata/bengkel"
            element={<LbengkelSales />}
          />
          <Route
            path="/area/sales/listdata/prospeksa"
            element={<LprospeksaSales />}
          />
          <Route path="/area/sales/other/rekapsales" element={<RekapSales />} />
          <Route path="/area/sales/list/datacustomer" element={<DataCustomer />} />
          
          {/* AREA SA */}
          <Route path="/area/sa" element={<SaIndex />} />
          <Route path="/area/sa/customerpasif/input" element={<InputDataPasif />} />
          <Route path="/area/sa/customerpasif/followupsa" element={<FollowUp />} />
          <Route path="/area/sa/customerpasif/followupsa" element={<FollowUp />} />

          {/* Area Stock */}
          <Route path="/stock/overviewstock" element={<OverviewStock />} />
          <Route path="/stock/inputonhand" element={<InputOnHand />} />
          <Route path="/stock/requeststock" element={<RequestStock />} />
          <Route path="/stock/arunitpenjualan/:nama_spv" element={<Arunit />} />
          <Route path="/stock/swappingunit" element={<Swapping />} />
          <Route path="/stock/dostock" element={<DoStock />} />
          <Route path="/stock/list/dostock" element={<LsDoStock />} />
          <Route path="/stock/outstanding" element={<Outstanding />} />

          {/* Area MRA */}
          <Route path="/area/mra/importdatabengkel" element={<ImportDataBengkel />} />
          <Route path="/area/mra/listdatado" element={<ListDataDo />} />
          <Route path="/area/mra/customerpasif/overview" element={<OverviewCustPasif />} />
          <Route path="/area/mra/customerpasif/inputdata" element={<InputData />} />
          <Route path="/area/mra/customerpasif/listdata" element={<ListDataHariansa />} />
          <Route path="/area/mra/customerpasif/corong" element={<Corong />} />
          <Route path="/area/mra/customerpasif/kecamatan" element={<Kecamatan />} />
          <Route path="/area/mra/booking/cekseribu" element={<Booking1000 />} />
          <Route path="/area/mra/booking/lain" element={<BookingLain />} />
          <Route path="/area/mra/booking/customerpasif" element={<BookingCustPasif />} />
          <Route path="/area/mra/lapbooking/rekap" element={<RekapBooking />} />
          <Route path="/area/mra/lapbooking/list" element={<ListLapBooking />} />
          <Route path="/area/mra/prospeksa/list" element={<ListProspekSaMra />} />
          <Route path="/area/mra/prospeksa/corong" element={<CorongProspekSa />} />

          {/* Area SA */}
          <Route path="/area/sa/cekservice/liskendaraan" element={<ListKendaraan />} />
          <Route path="/area/sa/cekservice/inputservice" element={<InputService />} />
          <Route path="/area/sa/prospek/inputprospek" element={<InputDataProspek />} />
          <Route path="/area/sa/prospek/listprospek" element={<ListProspek />} />

          {/* Area MRA */}
          <Route path="/area/crc/ultah" element={<ListUltah />} />
          <Route path="/area/crc/listseribukm" element={<ListSeribu />} />
          <Route path="/area/crc/listcr1" element={<ListCr1 />} />
          <Route path="/area/crc/listcr2" element={<ListCr2 />} />

          {/* Area Sharing Database */}
          <Route path="/sharing/db/listdata" element={<ListSharingData />} />
          <Route path="/sharing/db/inputdata" element={<ImportCustomer />} />

          {/* Area Markom */}
          <Route path="/area/markom/listpameran" element={<InputPameran />} />
          <Route path="/area/markom/listkecamatan" element={<InputKecamatan />} />
          <Route path="/area/markom/listkelurahan" element={<InputKelurahan />} />

        </Routes>
      </Box>
    </Router>
  );
}

export default App;
