import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import swal from "sweetalert";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// Cards
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Links from "@mui/material/Link";
import LinearProgress from '@mui/material/LinearProgress';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const listPertanyaan = [
    {
        value: "Ya",
        label: "Ya",
    },
    {
        value: "Tidak",
        label: "Tidak",
    }
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        // marginLeft: theme.spacing(125),
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    inputText: {
        width: "48%",
        marginLeft: theme.spacing(2)
    },
}));

function LharianSales(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [nmCustomer, setCustomer] = React.useState("");
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };
    const handleCustomer = (event, value) => {
        const customername = value != null ? value.label : "";
        setCustomer(customername);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    const isLoading = false;

    const [open, setOpen] = React.useState(false);
    const [dtDo, setDtDo] = React.useState([]);
    const [nameSales, setNameSales] = React.useState();
    const [noSpk, setNoSpk] = React.useState();
    const [nameCustomer, setNameCustomer] = React.useState();
    const [nameIdp, setNameIdp] = React.useState();

    const handleClickOpen = (eventValue) => {
        setDtDo(eventValue);
        setNameSales(eventValue.nama_sales);
        setNameCustomer(eventValue.nama_cust);
        setNameIdp(eventValue.idh);
        setNoSpk(eventValue.no_spk);
        setInputs((values) => ({
            ...values,
            ["id_do"]: eventValue.id,
            ["tgl_do"]: eventValue.tgl_do,
        }));
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setInputs([]);
    };

    // API List Data Prospek
    const [dataCustomer, setDataCustomer] = useState([]);
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [refreshDt, setRefresh] = useState();
    const [loadingSet, setLoadingData] = useState(false);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getDataCustomer = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/harian/list/customer?bulan=${bulan}&tahun=${tahun}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataCustomer(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getDataCustomer();
    }, [bulan, tahun]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            setLoadingData(true);
            const url = `https://api-funeling.kidsbjm.com/api/harian/findby/sales?bulan=${bulan}&tahun=${tahun}&customer=${nmCustomer}&page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                    setLoadingData(false);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, bulan, tahun, nmCustomer, refreshDt]);


    const [listLeasing, setDataLeasing] = useState([]);
    const [optLeasing, setLeasing] = React.useState(year);
    const [openUpdateCust, setOpenUpdateCust] = React.useState(false);

    const handleLeasing = (event) => {
        setLeasing(event.target.value);
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const [valCustName, setValCustomer] = useState([]);

    const hanldeUpdateCust = (value) => {
        console.log(value);
        setOpenUpdateCust(true);
        setValCustomer(value);
        setJeniscustomer(value.cust_jenis);
        setKategoriProspekState(value.kategori);
        setInputs((values) => ({
            ...values,
            ["id"]: value.id
        }));
    }

    // DATA LEASING
    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getLeasing = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/leasing/list/cabang`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataLeasing(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getLeasing();
    }, []);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const columns = [
        {
            field: "",
            renderCell: (cellEdit) => {
                return (
                    <Button
                        size="small"
                        variant="contained"
                        style={{background: "#1289A7", color: "#FFFFFF"}}
                        onClick={(event) => {
                            // handleOpenSingleID(cellEdit.row);
                            hanldeUpdateCust(cellEdit.row);
                        }}
                    >
                        Update Kategori
                    </Button>
                );
            },
            minWidth: 200,
        },
        { field: "tgl", headerName: "Tanggal", minWidth: 150 },
        { field: "nama_cust", headerName: "Customer", minWidth: 200 },
        { field: "cust_jenis", headerName: "Jenis Customer", minWidth: 200 },
        { field: "kategori", headerName: "Kategori", minWidth: 200 },
        { field: "alamat", headerName: "Alamat", minWidth: 200 },
        { field: "kecamatan", headerName: "Kecamatan", minWidth: 200 },
        { field: "telp", headerName: "Telepon", minWidth: 200 },
        { field: "kategori_sumber", headerName: "Kategori Sumber", minWidth: 200 },
        { field: "sumber", headerName: "Sumber Prospek", minWidth: 200 },
        { field: "demand_structure", headerName: "Demand Structure", minWidth: 200 },
        { field: "kendaraan_minat", headerName: "Kendaraan Minat", minWidth: 200 },
        { field: "kendaraan_dimiliki", headerName: "Kendaraan Dimiliki", minWidth: 200 },
        { field: "planning_to_buy", headerName: "Planning to Buy", minWidth: 200 },
        { field: "test_drive", headerName: "Test Drive", minWidth: 200 },
        { field: "ket", headerName: "Keterangan", minWidth: 200 },
        
    ];

    const handleClick = (values) => {
        console.log(values);
    };

    const [inputs, setInputs] = useState([]);
    const handleChangeInput = (event) => {
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    
    const [loadButton, setLoadButton] = React.useState(false);

    const handleSubmit = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios
        .post("https://api-funeling.kidsbjm.com/api/harian/update", inputs)
        .then(function (response) {
            if (response.data.error == true) {
                swal("Error", "Data tidak boleh kosong!", "error", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
            } else {
                swal("Success", "Data Berhasil disimpan!", "success", {
                    buttons: false,
                    timer: 2000,
                });
                // window.location.reload();
                setLoadButton(false);
                setOpenUpdateCust(false);
                setRefresh(response);
            }
        });
    };

    const idCab = localStorage.getItem("id_cabang");
    const salesName = JSON.parse(localStorage.getItem("person"));
    const urlExport = `https://api-funeling.kidsbjm.com/api/export/harian/list?sales=${salesName}&bulan=${bulan}&tahun=${tahun}&id_cabang=${idCab}&customer=${nmCustomer}`;

    const listJeniscustomer = [
        {
            value: "SUSPECT",
            label: "SUSPECT",
        },
        {
            value: "PROSPEK",
            label: "PROSPEK",
        },
    ];

    const listKategoriProspek = [
        {
            value: "BELUM PROSPEK",
            label: "BELUM PROSPEK",
        },
        {
            value: "LOW",
            label: "LOW",
        },
        {
            value: "MEDIUM",
            label: "MEDIUM",
        },
        {
            value: "HOT",
            label: "HOT",
        },
    ];

    const [kategoriProspekList, setKategoriProspekState] = React.useState("");
    const [jeniscustomer, setJeniscustomer] = React.useState("");

    const handleKtgProspek = (event) => {
        setKategoriProspekState(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const handleJcust = (event) => {
        setJeniscustomer(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography className="bg-white p-3 rounded-md shadow-lg">
                <b>LIST DATA HARIAN</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div className="bg-white p-3 rounded-md shadow-lg mb-2">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Bulan"
                        value={bulan}
                        style={{ float: "left" }}
                        onChange={handleChange}
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Tahun"
                        value={tahun}
                        style={{ float: "left" }}
                        onChange={handleChange2}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dataCustomer}
                        onChange={handleCustomer}
                        renderInput={(params) => (
                            <TextField {...params} label="Customer" helperText="Pilih Customer" />
                        )}
                    />
                </div>
            </Box>
            <br></br>
            <Box style={{ width: "100%" }}>
                {loadingSet === true ? 
                    // <center><CircularProgress size="1.2rem" color="inherit" /></center>
                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <LinearProgress />
                    </Stack>
                :  
                    ""
                }
            </Box>
            <Links href={urlExport} underline="none">
                <Button className={classes.btnexport} size="small" variant="contained" color="secondary" startIcon={<FileDownloadIcon />}>
                    Export Excel
                </Button>
            </Links>
            <TableContainer style={{ height: 500, width: "100%" }} className="bg-white p-3 rounded-md shadow-lg">
                <DataGrid
                    rows={dataProspek}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>
            <br></br>
            
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Input CR 1000"}</DialogTitle>
                    <DialogContent>
                        <Typography>
                            No SPK <b>{dtDo.no_spk}</b>
                            <br />
                            Nama Sales <b>{dtDo.nama_sales}</b>
                            <br />
                            Nama Customer <b>{dtDo.nama_cust}</b>
                            <br />
                            Alamat <b>{dtDo.alamat}</b>
                            <br />
                            No Rangka <b>{dtDo.no_rangka}</b>
                            <br />
                        </Typography>{" "}
                        <br />
                        <form>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    defaultValue={nameIdp}
                                    variant="outlined"
                                    name="idp"
                                    label="Idp"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="IDP"
                                    hidden
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="tanggalcek"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    type="date"
                                    helperText="Tanggal Cek"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    sx={{ marginLeft: 2 }}
                                    name="tanggalbooking"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    type="date"
                                    helperText="Tanggal Booking"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="keluhan"
                                    label="Keluhan"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Keluhan"
                                />
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/* Update Customer */}
            <Box>
                <Dialog
                    open={openUpdateCust}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="sm"
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    style={{ width: "50%", margin: "0 auto" }}
                >
                    <DialogContent style={{
                        borderLeft: "5px solid #c23616",
                        // borderRight: "5px solid #c23616",
                    }}>
                        <br></br>
                        <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                            <Chip
                                label={"Customer: " + valCustName.nama_cust}
                                // color="success"
                                style={{
                                    fontWeight: "bold",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                    border: "1px solid #00a8ff",
                                    background: "transparent",
                                    color: "black"
                                }}
                            />
                            <Chip
                                label={"Alamat: " + valCustName.alamat}
                                // color="success"
                                style={{
                                    fontWeight: "bold",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                    border: "1px solid #00a8ff",
                                    background: "transparent",
                                    color: "black",
                                    marginLeft: "5px"
                                }}
                            />
                            <Chip
                                label={"Demand Structure: " + valCustName.demand_structure}
                                // color="success"
                                style={{
                                    fontWeight: "bold",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                    border: "1px solid #00a8ff",
                                    background: "transparent",
                                    color: "black",
                                    marginLeft: "5px"
                                }}
                            />
                        </Box><br></br>
                        <form onSubmit={handleSubmit}>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                {/* <TextField
                                    id="outlined-basic"
                                    select
                                    name="jeniscustomer"
                                    value={jeniscustomer}
                                    onChange={handleJcust}  
                                    label="Pilih Jenis"
                                    helperText="Jenis Customer"
                                    variant="outlined"
                                    className={classes.inputText}
                                    size="small"
                                    required
                                >
                                    {listJeniscustomer.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField> */}
                                <TextField
                                    id="outlined-basic"
                                    select
                                    value={kategoriProspekList}
                                    onChange={handleKtgProspek}
                                    label="Kategori"
                                    helperText="Pilih Kategori"
                                    name="kategoriprospek"
                                    variant="outlined"
                                    // className={classes.inputText}
                                    style={{width:"100%"}}
                                    size="small"
                                    required
                                >
                                    {listKategoriProspek.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </form>
                        <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>

                        </Box>
                    </DialogContent>
                    <DialogActions style={{
                        borderLeft: "5px solid #c23616",
                        // borderRight: "5px solid #c23616",
                    }}>
                        <Button onClick={() => setOpenUpdateCust(false)}>Cancel</Button>
                        {loadButton === true ? 
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                disabled
                            > 
                                <CircularProgress size="1.2rem" color="inherit" />
                            </Button>
                        :  
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmit}
                            > 
                                <Typography>Submit</Typography>
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default LharianSales;

if (document.getElementById("listdatahariansales")) {
    ReactDOM.render(<LharianSales />, document.getElementById("listdatahariansales"));
}
