import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import swal from "sweetalert";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import InputAdornment from "@mui/material/InputAdornment";

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// Cards
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Links from "@mui/material/Link";
import LinearProgress from '@mui/material/LinearProgress';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
  Default: "#81b71a",
  Blue: "#00B1E1",
  Cyan: "#37BC9B",
  Green: "#8CC152",
  Red: "#9e0000",
  Yellow: "#F6BB42",
};

const bulanlist = [
  {
    value: "1",
    label: "Januari",
  },
  {
    value: "2",
    label: "Februari",
  },
  {
    value: "3",
    label: "Maret",
  },
  {
    value: "4",
    label: "April",
  },
  {
    value: "5",
    label: "Mei",
  },
  {
    value: "6",
    label: "Juni",
  },
  {
    value: "7",
    label: "July",
  },
  {
    value: "8",
    label: "Agustus",
  },
  {
    value: "9",
    label: "September",
  },
  {
    value: "10",
    label: "Oktober",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "Desember",
  },
];

// const tahunlist = [
//   {
//     value: "2018",
//     label: "2018",
//   },
//   {
//     value: "2019",
//     label: "2019",
//   },
//   {
//     value: "2020",
//     label: "2020",
//   },
//   {
//     value: "2021",
//     label: "2021",
//   },
//   {
//     value: "2022",
//     label: "2022",
//   },
//   {
//     value: "2023",
//     label: "2023",
//   },
// ];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const useStyles = makeStyles((theme) => ({
  headerimage: {
    width: theme.spacing(10),
    height: theme.spacing(5),
  },
  thead: {
    backgroundColors: bgColors.Cyan,
  },
  btn: {
    marginTop: theme.spacing(2),
  },
  btnexport: {
    // marginLeft: theme.spacing(125),
    float: "right",
    marginBottom: theme.spacing(1),
    backgroundColors: bgColors.Green,
  },
  inputText: {
    width: "50%",
  },
}));

function LspkSales(props) {
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth();
  const [bulan, setBulan] = React.useState(month+1);
  const [tahun, setTahun] = React.useState(year);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [nmCustomer, setCustomer] = React.useState("");
  const [loadingSet, setLoadingData] = useState(false);

  const handleChange = (event) => {
    setBulan(event.target.value);
  };
  const handleChange2 = (event) => {
    setTahun(event.target.value);
  };
  const handleCustomer = (event, value) => {
    const customername = value != null ? value.label : "";
    setCustomer(customername);
  };

  const queryOptions = React.useMemo(
    () => ({
      page,
      pageSize,
    }),
    [page, pageSize]
  );

  const isLoading = false;

  const [open, setOpen] = React.useState(false);
  const [nameSales, setNameSales] = React.useState();
  const [noSpk, setNoSpk] = React.useState("");
  const [noTipe, setNoTipe] = React.useState("");
  const [noWarna, setNoWarna] = React.useState("");
  const [nameCustomer, setNameCustomer] = React.useState();
  const [nameIdp, setNameIdp] = React.useState();
  const [dtDo, setDtDo] = React.useState([]);

  const handleClickOpen = (eventValue) => {
    setDtDo(eventValue);
    setNoSpk(eventValue.no_spk);
    setNoTipe(eventValue.tipe);
    setNoWarna(eventValue.warna);
    setNameIdp(eventValue.idh);
    setInputs((values) => ({
      ...values,
      ["id"]: eventValue.id,
      ["no_spk"]: eventValue.no_spk,
      ["tipe"]: eventValue.tipe,
      ["warna"]: eventValue.warna,
      ["idp"]: eventValue.idh,
    }));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInputs([]);
  };

  // API List Data Prospek
  const [dataCustomer, setDataCustomer] = useState([]);
  const [dataProspek, setDataProspek] = useState([]);
  const [dataProspek2, setDataProspek2] = useState([]);
  const [allProspek, setAllProspek] = useState([]);
  const [refreshDt, setRefresh] = useState();

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const getDataCustomer = async () => {
      const url = `https://api-funeling.kidsbjm.com/api/area/sales/list/do/customer?bulan=${bulan}&tahun=${tahun}`;
      try {
        const response = await axios.get(url);
        // if (response.statusText === "") {
          setDataCustomer(response.data.data);
        // } else {
        //   throw new Error("Request failed");
        // }
      } catch (error) {
        console.log(error);
      }
    };
    getDataCustomer();
  }, [bulan, tahun]);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const getData = async () => {
      setLoadingData(true);
      const url = `https://api-funeling.kidsbjm.com/api/area/sales/list/do?bulan=${bulan}&tahun=${tahun}&customer=${nmCustomer}&page=${page}&size=${pageSize}`;
      try {
        const response = await axios.get(url);
        // if (response.statusText === "") {
          setLoadingData(false);
          setDataProspek(response.data.data);
          setDataProspek2(response.data);
        // } else {
        //   throw new Error("Request failed");
        // }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [page, pageSize, bulan, tahun, nmCustomer, refreshDt]);

  const [listLeasing, setDataLeasing] = useState([]);
  const [optLeasing, setLeasing] = React.useState(year);

  const handleLeasing = (event) => {
    setLeasing(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  // DATA LEASING
  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const getLeasing = async () => {
      const url = `https://api-funeling.kidsbjm.com/api/leasing/list/cabang`;
      try {
        const response = await axios.get(url);
        // if (response.statusText === "") {
          setDataLeasing(response.data.data);
        // } else {
        //   throw new Error("Request failed");
        // }
      } catch (error) {
        console.log(error);
      }
    };
    getLeasing();
  }, []);

  const [rowCountState, setRowCountState] = React.useState(
    dataProspek2?.totalAll || 0
  );

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      dataProspek2?.totalAll !== undefined
        ? dataProspek2?.totalAll
        : prevRowCountState
    );
  }, [dataProspek2?.totalAll, setRowCountState]);

  const columns = [
    // {
    //   field: "Proses DO",
    //   renderCell: (cellValues) => {
    //     return cellValues.row.jml == "0" ? (
    //       <Button
    //         size="small"
    //         variant="outlined"
    //         color="secondary"
    //         onClick={(event) => {
    //           handleClickOpen(cellValues);
    //         }}
    //       >
    //         Proses
    //       </Button>
    //     ) : (
    //       <Stack spacing={1} alignItems="center">
    //         <Stack direction="row" spacing={1}>
    //           <Chip label="Sudah DO" size="small" color="success" />
    //         </Stack>
    //       </Stack>
    //     );
    //   },
    //   minWidth: 150,
    // },
    { field: "tgl", headerName: "Tanggal", minWidth: 150 },
    { field: "no_spk", headerName: "No SPK", minWidth: 200 },
    { field: "nama_spv", headerName: "SPV", minWidth: 200 },
    { field: "nama_sales", headerName: "Sales", minWidth: 200 },
    { field: "nama_cust", headerName: "Customer", minWidth: 200 },
    { field: "nama_stnk", headerName: "Nama STNK", minWidth: 200 },
    { field: "kecamatan", headerName: "Kecamatan", minWidth: 200 },
    { field: "kelurahan", headerName: "Kelurahan", minWidth: 200 },
    { field: "telp", headerName: "Telp", minWidth: 200 },
    { field: "tipe", headerName: "Tipe", minWidth: 200 },
    { field: "email", headerName: "Email", minWidth: 200 },
    { field: "warna", headerName: "Warna", minWidth: 200 },
    { field: "kategori_sumber", headerName: "Kategori Sumber", minWidth: 200 },
    { field: "sumber", headerName: "Sumber Prospek", minWidth: 200 },
    { field: "jml", headerName: "DO", minWidth: 200 },
    
  ];

  const handleClick = (values) => {
    console.log(values);
  };

  const [inputs, setInputs] = useState([]);
  const handleChangeInput = (event) => {
    setNoSpk(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeInputSpk = (event) => {
    setNoSpk(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeInputTipe = (event) => {
    setNoTipe(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeInputWarna = (event) => {
    setNoWarna(event.target.value);
    setInputs((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(
        "https://api-funeling.kidsbjm.com/api/spk/acton/updateData",
        inputs
      )
      .then(function (response) {
        if (response.data.error == true) {
          swal("Error", "Data tidak boleh kosong!", "error", {
            buttons: false,
            timer: 2000,
          });
        } else {
          swal("Success", "Data Berhasil disimpan!", "success", {
            buttons: false,
            timer: 2000,
          });
          // window.location.reload();
          setOpen(false);
          setRefresh(response);
          setInputs([]);
        }
      });
  };

  //validasi cabang SPK
  var cabang = JSON.parse(localStorage.getItem("id_cabang"));
  if (cabang == 1) {
    cabang = "WML-";
  } else if (cabang == 2) {
    cabang = "WLD-";
  } else if (cabang == 3) {
    cabang = "WLP-";
  } else if (cabang == 4) {
    cabang = "WLS";
  } else {
    cabang = "Unidentified";
  }

  const idCab = localStorage.getItem("id_cabang");
  const salesName = JSON.parse(localStorage.getItem("person"));
  const urlExport = `https://api-funeling.kidsbjm.com/api/export/spk/list?sales=${salesName}&bulan=${bulan}&tahun=${tahun}&id_cabang=${idCab}&customer=${nmCustomer}`;

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
      }}
    >
      <Toolbar />
      <Typography className="bg-white p-3 rounded-md shadow-lg">
        <b>List Data SPK</b>
      </Typography>{" "}
      <br />
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div className="bg-white p-3 rounded-md shadow-lg mb-2">
          <TextField
            id="outlined-select-currency"
            select
            label="Bulan"
            value={bulan}
            style={{ float: "left" }}
            onChange={handleChange}
            helperText="Pilih Bulan"
          >
            {bulanlist.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="outlined-select-currency-native"
            select
            label="Tahun"
            value={tahun}
            style={{ float: "left" }}
            onChange={handleChange2}
            SelectProps={{
              native: true,
            }}
            helperText="Pilih Tahun"
          >
            {tahunlist.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={dataCustomer}
            onChange={handleCustomer}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer"
                helperText="Pilih Customer"
              />
            )}
          />
        </div>
      </Box><br></br>
      <Box style={{ width: "100%" }}>
          {loadingSet === true ? 
              // <center><CircularProgress size="1.2rem" color="inherit" /></center>
              <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                  <LinearProgress />
              </Stack>
          :  
              ""
          }
      </Box>
      <Links href={urlExport} underline="none">
          <Button className={classes.btnexport} size="small" variant="contained" color="secondary" startIcon={<FileDownloadIcon />}>
              Export Excel
          </Button>
      </Links>
      <TableContainer style={{ height: 500, width: "100%" }} className="bg-white p-3 rounded-md shadow-lg">
          <DataGrid
              rows={dataProspek}
              rowCount={rowCountState}
              loading={isLoading}
              components={{ Toolbar: GridToolbar }}
              rowsPerPageOptions={[25, 50, 100]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              columns={columns}
          />
      </TableContainer>
      <br></br>
      <br></br>
      {/* {nmCustomer != "" ? (
        <Box sx={{ flexGrow: 1 }}>
          {dataProspek.map((datval) => [
            <Card
              key={datval.id}
              sx={{
                width: "90%",
                margin: "0 auto",
                borderTop: "1px solid #e0e0e0",
                marginBottom: 2,
              }}
            >
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {datval.nama_cust}
                </Typography>
                <Typography sx={{ fontSize: 10 }}>
                  <b>
                    NO SPK {datval.no_spk}
                  </b>
                  <br></br>
                  <b>
                    {datval.tipe}, {datval.warna}
                  </b>
                  <br></br>
                  <b>
                    Nama STNK {datval.nama_stnk}
                  </b>
                  <br></br>
                  {datval.tgl}
                  <br></br>
                  {datval.kategori_sumber}, {datval.sumber}
                  <br></br>
                  {datval.alamat}, {datval.kecamatan}, {datval.kelurahan}
                </Typography>
              </CardContent>
              <CardActions>
                {datval.jml == 0 ? (
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Belum DO " size="small" color="error" />
                    </Stack>
                  </Stack>
                ) : (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={(event) => {
                      handleClickOpen(datval);
                    }}
                  >
                    Edit
                  </Button>
                )}
                {datval.jml == 0 ? (
                  ""
                ) : (
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Sudah DO " size="small" color="success" />
                    </Stack>
                  </Stack>
                )}
              </CardActions>
            </Card>,
          ])}
        </Box>
      ) : (
        ""
      )} */}
      <Box style={{ width: "100%" }}>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          fullWidth={fullWidth}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          style={{ width: "100%" }}
        >
          <DialogTitle
            style={{
              backgroundColor: bgColors.Yellow,
              marginBottom: "10px",
              color: "#ffffff",
            }}
          >{"Edit SPK"}</DialogTitle>
          <DialogContent>
            <Typography>
              <Chip
                label={"Sales: " + dtDo.nama_sales}
                color="primary"
                variant="outlined"
              />
              <Chip
                label={"Sales: " + dtDo.nama_cust}
                color="primary"
                variant="outlined"
              />
              <Chip
                label={"Sales: " + dtDo.telp}
                color="success"
                variant="outlined"
              />
              <Chip
                label={"Sales: " + dtDo.tipe}
                color="success"
                variant="outlined"
              />
              <br />
            </Typography>{" "}
            <br />
            <form>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="no_spk"
                  label="No SPK"
                  value={noSpk}
                  onChange={handleChangeInputSpk}
                  className={classes.inputText}
                  size="small"
                  helperText="No SPK"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{cabang}</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="tipe"
                  label="tipe"
                  value={noTipe}
                  onChange={handleChangeInputTipe}
                  className={classes.inputText}
                  size="small"
                  helperText="Tipe"
                />
              </Box>
              <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  name="warna"
                  label="warna"
                  value={noWarna}
                  onChange={handleChangeInputWarna}
                  className={classes.inputText}
                  size="small"
                  helperText="Warna"
                />
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default LspkSales;

if (document.getElementById("listdataspksales")) {
  ReactDOM.render(<LspkSales />, document.getElementById("listdataspksales"));
}
