import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import swal from "sweetalert";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// Cards
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const listPertanyaan = [
    {
        value: "Ya",
        label: "Ya",
    },
    {
        value: "Tidak",
        label: "Tidak",
    }
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        // marginLeft: theme.spacing(125),
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    inputText: {
        width: "48%",
        marginLeft: theme.spacing(2)
    },
}));

function DataBooking1000(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [nmCustomer, setCustomer] = React.useState("");
    const classes = useStyles();

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };
    const handleCustomer = (event, value) => {
        const customername = value != null ? value.label : "";
        setCustomer(customername);
    };

    const isLoading = false;

    const [open, setOpen] = React.useState(false);
    const [dtDo, setDtDo] = React.useState([]);

    const handleClickOpen = (eventValue) => {
        setDtDo(eventValue);
        setInputs((values) => ({
            ...values,
            ["dtid"]: eventValue.idpil,
            ["tgl_do"]: eventValue.tgl_do,
        }));
        setOpen(true);
    };

    const [openCancel, setOpenCancel] = React.useState(false);
    const handleCancelBooking = (eventValue) => {
        setDtDo(eventValue);
        setOpenCancel(true);
        setInputsCancel((values) => ({
            ...values,
            ["id_pil"]: eventValue.idpil,
            ["cancel"]: "yes",
        }));
    };

    const handleClose = () => {
        setOpen(false);
        setOpenCancel(false);
        setInputs([]);
    };

    // API List Data Prospek
    const [dataCustomer, setDataCustomer] = useState([]);
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [refreshDt, setRefresh] = useState();

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getDataCustomer = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/area/sales/booking/customer?bulan=${bulan}&tahun=${tahun}`;
            try {
                const response = await axios.get(url);
                if (response.statusText === "") {
                    setDataCustomer(response.data.data);
                } else {
                    throw new Error("Request failed");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getDataCustomer();
    }, [bulan, tahun]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/booking/list/cek1000?customer=${nmCustomer}`;
            try {
                const response = await axios.get(url);
                if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                } else {
                    throw new Error("Request failed");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [nmCustomer, refreshDt]);

    const handleClick = (values) => {
        console.log(values);
    };

    const [inputs, setInputs] = useState([]);
    const handleChangeInput = (event) => {
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const [inputsCancel, setInputsCancel] = useState([]);
    const handleInputCancel = (event) => {
        setInputsCancel((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .put("https://api-funeling.kidsbjm.com/api/area/sales/booking/updateTanggal", inputs)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                } else {
                    swal("Success", "Data Berhasil disimpan!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    // window.location.reload();
                    setOpen(false);
                    setRefresh(response);
                }
            });
    };

    const handleSubmitCancel = (event) => {
        event.preventDefault();
        axios
            .put("https://api-funeling.kidsbjm.com/api/area/sales/booking/cancel", inputsCancel)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                } else {
                    swal("Success", "Data Berhasil disimpan!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    // window.location.reload();
                    setOpenCancel(false);
                    setRefresh(response);
                }
            });
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography className="bg-white p-3 rounded-md shadow-lg">
                <b>Data Booking Service Pertama</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div className="bg-white p-3 rounded-md shadow-lg mb-2">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Bulan"
                        value={bulan}
                        style={{ float: "left" }}
                        onChange={handleChange}
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Tahun"
                        value={tahun}
                        style={{ float: "left" }}
                        onChange={handleChange2}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dataCustomer}
                        onChange={handleCustomer}
                        renderInput={(params) => (
                            <TextField {...params} label="Customer" helperText="Pilih Customer" />
                        )}
                    />
                </div>
            </Box>
            <br></br>
            <br></br>
            {nmCustomer != "" ? (
                <Box sx={{ flexGrow: 1 }}>
                    {dataProspek.map((datval) => [
                        <Card key={datval.id} sx={{ width: "90%", margin: "0 auto", borderTop: "1px solid #e0e0e0", marginBottom: 2 }}>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                >
                                    {datval.nama_cust}
                                </Typography>
                                <Typography
                                    sx={{ fontSize: 12 }}
                                >
                                    Tanggal Booking {datval.tgl_booking}<br></br>
                                    {datval.alamat}<br></br>
                                    <b>{datval.tipe}</b><br></br>
                                    <b>{datval.no_rangka}</b><br></br>
                                    {datval.cancel == 'yes' ? <b><i>{"Keterangan : " + datval.alasan_cancel}</i></b> : ""}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {datval.cancel != 'yes' ? (
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={(event) => {
                                            handleClickOpen(datval);
                                        }}
                                    >
                                        Update Tanggal Booking
                                    </Button>
                                ) : (
                                    ""
                                )}

                                {datval.cancel != 'yes' ? (
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={(event) => {
                                            handleCancelBooking(datval);
                                        }}
                                    >
                                        Cancel Booking
                                    </Button>
                                ) : (
                                    <Stack
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                        >
                                            <Chip
                                                label="Sudah Cancel"
                                                size="small"
                                                color="error"
                                            />
                                        </Stack>
                                    </Stack>
                                )}
                            </CardActions>
                        </Card>
                        ,
                    ])}
                </Box>
            ) : (
                ""
            )}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Update Tanggal Booking"}</DialogTitle>
                    <DialogContent>
                        <Typography>
                            No SPK <b>{dtDo.no_spk}</b>
                            <br />
                            Nama Sales <b>{dtDo.nama_sales}</b>
                            <br />
                            Nama Customer <b>{dtDo.nama_cust}</b>
                            <br />
                            Alamat <b>{dtDo.alamat}</b>
                            <br />
                            No Rangka <b>{dtDo.no_rangka}</b>
                            <br />
                        </Typography>{" "}
                        <br />
                        <form>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="tanggalbooking"
                                    onChange={handleChangeInput}
                                    sx={{ width: "100%" }}
                                    size="small"
                                    type="date"
                                    helperText="Tanggal Booking"
                                />
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>

                {/* Cancel Dialog */}
                <Dialog
                    open={openCancel}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Cancel Booking"}</DialogTitle>
                    <DialogContent>
                        <Typography>
                            No SPK <b>{dtDo.no_spk}</b>
                            <br />
                            Nama Sales <b>{dtDo.nama_sales}</b>
                            <br />
                            Nama Customer <b>{dtDo.nama_cust}</b>
                            <br />
                            Alamat <b>{dtDo.alamat}</b>
                            <br />
                            No Rangka <b>{dtDo.no_rangka}</b>
                            <br />
                        </Typography>{" "}
                        <br />
                        <form>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="alasan_cancel"
                                    label="Alasan Cancel"
                                    onChange={handleInputCancel}
                                    sx={{ width: "100%" }}
                                    size="small"
                                    helperText="Alasan Cancel"
                                />
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmitCancel}
                        >
                            Submit
                        </Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default DataBooking1000;

if (document.getElementById("databookingcek1000")) {
    ReactDOM.render(<DataBooking1000 />, document.getElementById("databookingcek1000"));
}
