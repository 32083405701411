import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import Chip from "@mui/material/Chip";
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import swal from "sweetalert";
// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const token = localStorage.getItem("access_token");
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
}));

function ListCr1(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [refreshDt, setRefresh] = useState();

    const handleClickOpen = (event) => {
        console.log(event);
        setOpen(true);
        setInputs(values => ({...values, ["id"]: event.id}));
    }

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    // API List Data Prospek
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/area/crc/listcrsatu?page=${page}&size=${pageSize}&bulan=${bulan}&tahun=${tahun}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                    setLoading(false);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, bulan, tahun, refreshDt]);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getAllData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/area/crc/export/listcrsatu?bulan=${bulan}&tahun=${tahun}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setAllProspek(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getAllData();
    }, [bulan, tahun]);

    const header = [
        "Tanggal", 
        "No SPK", 
        "SPV", 
        "Sales", 
        "Customer", 
        "No Telepon", 
        "Tipe", 
        "Kendaraan Sesuai Harapan", 
        "Ada Penjelasan Bengkel dan Sparepart", 
        "Mengingatkan Kueisoner TAM Berhadiah", 
        "Sudah Menggunakan Kendaraan", 
        "Keluhan", 
    ];

    const body3 = allProspek.map((data) => [
        data.tgl,
        data.no_spk,
        data.nama_spv,
        data.nama_sales,
        data.nama_cust,
        data.telp,
        data.tipe,
        data.pertanyaan_satu,
        data.pertanyaan_dua,
        data.pertanyaan_tiga,
        data.pertanyaan_empat,
        data.keluhan,
    ]);

    const mynamefile = "List Data CR 1_"+bulan+"-"+tahun+".csv";

    function handleDownloadExcel() {
        downloadExcel({
            fileName: "Data T DO",
            sheet: "T DO",
            tablePayload: {
                header,
                // accept two different data structures
                body: body3,
            },
        });
    }

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const [inputs, setInputs] = useState([]);
    const handleChangeInput = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const [openTracking, setOpenTracking] = useState(false);
    const [valTracking, setValTracking] = useState([]);
    const handleOpenTracking = (event) => {
        setOpenTracking(true);
        setValTracking(event);
        setInputs((values) => ({
            ...values,
            ["id_do"]: event.id_do,
            ["no_rangka"]: event.no_rangka,
        }));
        console.log(event);
    }

    const columns = [
        {
            field: "Tracking",
            renderCell: (cellEdit) => {
                return (
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={(event) => {
                            handleOpenTracking(cellEdit.row);
                        }}
                    >
                        Detail
                    </Button>
                );
            },
            minWidth: 150,
        },
        { field: "nama_cust", headerName: "Customer", minWidth: 200 },
        { field: "telp", headerName: "No Telepon", minWidth: 200 },
        { field: "tipe", headerName: "Tipe", minWidth: 200 },
        { field: "tgl", headerName: "Tanggal", minWidth: 200 },
        { field: "no_spk", headerName: "No SPK", minWidth: 200 },
        { field: "nama_spv", headerName: "SPV", minWidth: 100 },
        { field: "nama_sales", headerName: "Sales", minWidth: 200 },
        // { field: "pertanyaan_satu", headerName: "Kendaraan Sesuai Harapan", minWidth: 200 },
        // { field: "pertanyaan_dua", headerName: "Ada Penjelasan Bengkel dan Sparepart", minWidth: 200 },
        // { field: "pertanyaan_tiga", headerName: "Mengingatkan Kueisoner TAM Berhadiah", minWidth: 200 },
        // { field: "pertanyaan_empat", headerName: "Sudah Menggunakan Kendaraan", minWidth: 200 },
        { field: "keluhan", headerName: "Keluhan", minWidth: 200 },
    ];
    //const rows = {dataProspek};
    const [loadButton, setLoadButton] = React.useState(false);
    const handleUpdate = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios
            .post("https://api-funeling.kidsbjm.com/api/area/crc/update/crsatu", inputs)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                    setLoadButton(false);
                } else {
                    swal("Success", "Data Berhasil disimpan!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    // window.location.reload();
                    setLoadButton(false);
                    setOpenTracking(false);
                    setRefresh(response);
                }
            });
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography className="bg-white p-3 rounded-md shadow-lg">
                <b>List Data CR 1</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div className="bg-white p-3 rounded-md shadow-lg mb-2">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Bulan"
                        value={bulan}
                        onChange={handleChange}
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Tahun"
                        value={tahun}
                        onChange={handleChange2}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </div>
                <CSVLink data={body3} headers={header} filename={mynamefile}>
                    <Button
                        className={classes.btnexport}
                        variant="contained"
                        color="secondary"
                        size="small"
                    >
                        Export All
                    </Button>
                </CSVLink>
            </Box>
            <TableContainer style={{ height: 500, width: "100%" }} className="bg-white p-3 rounded-md shadow-lg">
                <DataGrid
                    rows={dataProspek}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>

            <Box>
                <form onSubmit={handleUpdate}>
                    <Dialog
                        open={openTracking}
                        TransitionComponent={Transition}
                        keepMounted
                        maxWidth="xl"
                        fullWidth={fullWidth}
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        style={{ width: "70%", margin: "0 auto", fontSize: "14px" }}
                    >
                        <DialogContent style={{
                            borderLeft: "5px solid #c23616",
                        }}>
                            <br></br>
                            <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                                <Chip
                                    label={"Customer: " + valTracking.nama_cust}
                                    style={{
                                        fontWeight: "bold",
                                        borderRadius: "0px",
                                        marginBottom: "10px",
                                        background: "#00a8ff",
                                        color: "white"
                                    }}
                                />
                                <Chip
                                    label={"Kendaraan: " + valTracking.tipe}
                                    style={{
                                        fontWeight: "bold",
                                        borderRadius: "0px",
                                        marginBottom: "10px",
                                        background: "transparent",
                                        border: "1px solid #00a8ff",
                                        color: "red",
                                        marginLeft: "5px"
                                    }}
                                />
                            </Box>
                            <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                                <table>
                                    <thead>
                                        <tr style={{background:"#EA2027", color:"white"}}>
                                            <th style={{padding: "5px"}}>Tracking</th>
                                            <th style={{padding: "5px"}}></th>
                                            <th style={{padding: "5px"}}></th>
                                            <th style={{padding: "5px"}}>Status</th>
                                            <th style={{padding: "5px"}}></th>
                                            <th style={{padding: "5px"}}>Tracking</th>
                                            <th style={{padding: "5px"}}></th>
                                            <th style={{padding: "5px"}}></th>
                                            <th style={{padding: "5px"}}>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{padding: "5px"}}>{'Kendaraan Sesuai Harapan ?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.pertanyaan_satu}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.pertanyaan_satu != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label="Followup"
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="pertanyaan_satu"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                            <td></td>
                                            <td style={{padding: "5px"}}>{'Ada Penjelasan Bengkel dan Sparepart ?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.pertanyaan_dua}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.pertanyaan_dua != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label="Followup"
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="pertanyaan_dua"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{padding: "5px"}}>{'Mengingatkan Kueisoner TAM Berhadiah ?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.pertanyaan_tiga}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.pertanyaan_tiga != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label="Followup"
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="pertanyaan_tiga"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                            <td></td>
                                            <td style={{padding: "5px"}}>{'Sudah Menggunakan Kendaraan ?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.pertanyaan_empat}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.pertanyaan_empat != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label="Followup"
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="pertanyaan_empat"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{padding: "5px"}}>{'Apakah kendaraan memiliki fitur T-intouch ?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.t_intouch}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.t_intouch != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label=""
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="t_intouch"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                            <td></td>
                                            <td style={{padding: "5px"}}>{'Apakah sudah dijelaskan mengenai fitur T-intouch ?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.fitur_tintouch}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.fitur_tintouch != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label=""
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="fitur_t_intouch"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{padding: "5px"}}>{'Apakah kendaraan termasuk dalam program Tcare?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.tcare}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.tcare != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label=""
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="tcare"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                            <td></td>
                                            <td style={{padding: "5px"}}>{'Apakah sudah dijelaskan mengenai benefit tcare ?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.benefit_tcare}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.benefit_tcare != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label=""
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="benefit_tcare"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{padding: "5px"}}>{'Apakah kendaraan anda memakai aksesoris TCO ?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.tco}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.tco != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label=""
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="tco"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                            <td></td>
                                            <td style={{padding: "5px"}}>{'Apakah kendaraan anda memiliki asuransi ?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.asuransi_have}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.asuransi_have != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label=""
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="asuransi_have"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{padding: "5px"}}>{'Apakah kendaraan anda hybrid anda ?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.hybrid}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.hybrid != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label=""
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="hybrid"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                            <td></td>
                                            <td style={{padding: "5px"}}>{'Apakah sudah dijelaskan mengenai kelebihan hybrid ?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.benefit_hybrid}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.benefit_hybrid != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label=""
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="benefit_hybrid"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{padding: "5px"}}>{'Apakah sudah dijelaskan mengenai TSS ?'}</td>
                                            <td style={{padding: "5px"}}>:</td>
                                            <td style={{padding: "5px"}}>{valTracking.tss}</td>
                                            <td style={{padding: "5px"}}>
                                                {valTracking.tss != null ? (
                                                    <Chip
                                                        label="Sudah Followup "
                                                        size="small"
                                                        color="success"
                                                        style={{fontSize: "10px"}}
                                                    />
                                                ) : (
                                                    <TextField
                                                        id="outlined-select-currency-native"
                                                        select
                                                        defaultValue=""
                                                        label=""
                                                        helperText="Followup"
                                                        onChange={handleChangeInput}
                                                        className={classes.inputText}
                                                        size= "small"
                                                        name="tss"
                                                    >
                                                        <MenuItem
                                                            key={1}
                                                            value={"Ya"}
                                                        >
                                                            {"Ya"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={2}
                                                            value={"Tidak"}
                                                        >
                                                            {"Tidak"}
                                                        </MenuItem>
                                                    </TextField>
                                                )}
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Box>
                        </DialogContent>
                        <DialogActions style={{
                            borderLeft: "5px solid #c23616",
                        }}>
                            {loadButton === true ? 
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                            :  
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleUpdate}
                                > 
                                    <Typography>Submit</Typography>
                                </Button>
                            }
                            <Button size="small" onClick={() => setOpenTracking(false)}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </Box>
        </Box>
    );
}

export default ListCr1;
