import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import swal from 'sweetalert';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");
// 
const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        // marginLeft: theme.spacing(125),
        float: 'right',
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green
    }
}));

function ImportCustomer(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);
    const [fullWidth, setFullWidth] = React.useState(true);

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    const isLoading = false;

    // API List Data Prospek
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [loadingSet, setLoadingData] = useState(false);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            setLoadingData(true);
            const url = `https://api-funeling.kidsbjm.com/api/sharing/list/customer/active?page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                    setLoadingData(false);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, bulan, tahun]);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getAllData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/sharing/list/export`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setAllProspek(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getAllData();
    }, [bulan, tahun]);

    const header = [
        "Jenis",
        "Tanggal",
        "SPV",
        "Sales",
        "Customer",
        "Alamat",
        "Kelurahan",
        "Telp",
        "Minat",
        "Sumber Prospek",
        "Kategori",
        "Planning To Buy",
        "Test Drive",
        "SPK",
    ];

    const body3 = allProspek.map((data) => [
        data.jenis_cust,
        data.tgl,
        data.nama_spv,
        data.nama_sales,
        data.nama_cust,
        data.alamat,
        data.kelurahan,
        data.telp,
        data.kendaraan_minat,
        data.sumber,
        data.kategori,
        data.planning_to_buy,
        data.test_drive,
        data.spk,
    ]);

    const mynamefile = "List Data Customer dari Sales Non-aktif.csv";

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const columns = [
        { field: "jenis_cust", headerName: "Jenis", minWidth: 220 },
        { field: "tgl", headerName: "Tanggal" },
        { field: "nama_spv", headerName: "SPV" },
        { field: "nama_sales", headerName: "Sales", minWidth: 300 },
        { field: "nama_sales_old", headerName: "Sales Sebelumnya", minWidth: 300 },
        { field: "nama_cust", headerName: "Customer", minWidth: 250 },
        { field: "alamat", headerName: "Alamat", minWidth: 300 },
        { field: "kelurahan", headerName: "Kelurahan", minWidth: 200 },
        { field: "telp", headerName: "Telp", minWidth: 150 },
        { field: "kendaraan_minat", headerName: "Minat", minWidth: 200 },
        { field: "sumber", headerName: "Sumber Prospek", minWidth: 200 },
        { field: "kategori", headerName: "Kategori" },
        {
            field: "planning_to_buy",
            headerName: "Planning to Buy",
            minWidth: 200,
        },
        { field: "test_drive", headerName: "Test Drive" },
        { field: "spk", headerName: "SPK" },
    ];
    //const rows = {dataProspek};
    
    const [fileUpload, setFileUp] = React.useState([]);
    const [loadButton, setLoadButton] = React.useState(false);
    const [inputsImport, setInputsImport] = React.useState([]);
    const [openImport, setOpenImport] = React.useState(false);
    const handleOpenImport = (event)    => {
        setOpenImport(true);
    }
    const handleClose = () => {
        setOpenImport(false);
      };

    const hChangeInputFile = (event) => {
        setFileUp(event.target.files[0]);
        setInputsImport(values => ({...values, [event.target.name]: fileUpload}));
    }

    const handleSubmitImport = (event) => {
        setLoadButton(true);
        event.preventDefault();
        console.log(fileUpload);
        const formData = new FormData();
        
        formData.append('fileimport',fileUpload);
        axios.post('https://api-funeling.kidsbjm.com/api/sharing/list/import', formData).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
                window.location.href = "/sharing/db/listdata";
            }
        });
        
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography>
                <b>Sharing Database : Import Data</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <Alert sx={{float: "left", width: "90%"}} severity="info"><Typography>Sales Aktif : List Data Customer</Typography></Alert>
                <Button
                    className={classes.btnexport}
                    variant="contained"
                    color="secondary"
                    // size="small"
                    onClick={handleOpenImport}
                >
                    Import
                </Button>
                
            </Box>
            <br></br>
            <Box style={{ width: "100%" }}>
                {loadingSet === true ? 
                    // <center><CircularProgress size="1.2rem" color="inherit" /></center>
                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <LinearProgress />
                    </Stack>
                :  
                    ""
                }
            </Box>
            <TableContainer style={{ height: 500, width: "100%" }}>
                <DataGrid
                    rows={dataProspek}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>
            
            <Box style={{ width: "100%" }}>
                <form onSubmit={handleSubmitImport}>
                    <Dialog
                        open={openImport}
                        TransitionComponent={Transition}
                        keepMounted
                        maxWidth="md"
                        fullWidth={fullWidth}
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        style={{ width: "100%" }}
                    >
                    <DialogTitle
                        style={{
                            backgroundColor: bgColors.Yellow,
                            marginBottom: "10px",
                            color: "#ffffff",
                        }}
                    >
                    {"Import Data Customer"}
                    </DialogTitle>
                    <DialogContent>
                        <Box
                            style={{ width: "100%" }}
                            sx={{ marginBottom: 2 }}
                        >
                            <TextField
                                id="outlined-select-currency-native"
                                defaultValue=""
                                label=""
                                helperText="Pilih File"
                                onChange={hChangeInputFile}
                                sx={{width: "100%"}}
                                size= "small"
                                name="fileimport"
                                type="file"
                            >
                            </TextField>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {loadButton === true ? 
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                            :  
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmitImport}
                                > 
                                    <Typography>Submit</Typography>
                                </Button>
                            }
                        <Button onClick={handleClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </Box>
        </Box>
    );
}

export default ImportCustomer;
