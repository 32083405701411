import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        // marginLeft: theme.spacing(125),
        float: 'right',
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green
    },
    optkategori: {
        height: theme.spacing(25)
    }
}));

function SLaporansales(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    const isLoading = false;

    // API List Data Prospek
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [optKtg, setKtg] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const ktg = "kategori";

    const listKtg = optKtg;
    const [ktgsum, setKtgSum] = React.useState("DIGITAL LEADS");

    const handleChangeKtg = (event) => {
        setKtgSum(event.target.value);
    };

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/summary/laporan_sales?bulan=${bulan}&tahun=${tahun}&type=${ktg}&kategori_sumber=${ktgsum}&page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setKtg(response.data.option);
                    setDataProspek2(response.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, bulan, tahun, ktgsum]);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getAllData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/summary/export/laporankategori?bulan=${bulan}&tahun=${tahun}&type=${ktg}&kategori_sumber=${ktgsum}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setAllProspek(response.data.listKategori);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getAllData();
    }, [bulan, tahun, ktgsum]);

    const header = [
        "Sales",
        "Suspect",
        "Prospek",
        "Hot Prospek",
        "SPK",
        "DO"
    ];

    const body3 = allProspek.map((data) => [
        data.sales,
        data.suspect,
        data.prospek,
        data.hot,
        data.spk,
        data.do,
    ]);

    const mynamefile = "Data Laporan Sales Kategori_"+ktgsum+"-"+bulan+"-"+tahun+".csv";

    function handleDownloadExcel() {
        downloadExcel({
            fileName: "Data Laporan Sales",
            sheet: "Laporan Sales",
            tablePayload: {
                header,
                // accept two different data structures
                body: body3,
            },
        });
    }

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const columns = [
        { field: "sales", headerName: "Sales", minWidth: 350 },
        { field: "suspect", headerName: "Suspect", minWidth: 150 },
        { field: "prospek", headerName: "Prospek", minWidth: 150 },
        { field: "hot", headerName: "Hot Prospek", minWidth: 150 },
        { field: "spk", headerName: "SPK", minWidth: 150 },
        { field: "do", headerName: "DO", minWidth: 150 },
    ];
    //const rows = {dataProspek};
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography className="bg-white p-3 rounded-md shadow-lg">
                <b>Summary : Laporan Sales (Kategori)</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div className="bg-white p-3 rounded-md shadow-lg mb-2">
                    <TextField
                        id="standard-basic"
                        select
                        value={bulan}
                        onChange={handleChange}
                        variant="standard"
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="standard-basic"
                        select
                        value={tahun}
                        onChange={handleChange2}
                        variant="standard"
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="standard-basic"
                        select
                        value={ktgsum}
                        onChange={handleChangeKtg}
                        variant="standard"
                        helperText="Pilih Kategori"
                    >
                        {listKtg.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <CSVLink data={body3} headers={header} filename={mynamefile}>
                    <Button
                        className={classes.btnexport}
                        variant="contained"
                        color="secondary"
                    >
                        Export All
                    </Button>
                </CSVLink>
            </Box>
            <TableContainer style={{ height: 500, width: "100%" }} className="bg-white p-3 rounded-md shadow-lg">
                <DataGrid
                    rows={dataProspek}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>
        </Box>
    );
}

export default SLaporansales;

if (document.getElementById("slaporansales")) {
    ReactDOM.render(
        <SLaporansales />,
        document.getElementById("slaporansales")
    );
}
