import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import swal from "sweetalert";
import Typography from "@mui/material/Typography";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { Container } from "@mui/material";
import Alert from '@mui/material/Alert';
import TableContainer from "@mui/material/TableContainer";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CSVLink } from "react-csv";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const person = JSON.parse(localStorage.getItem("person"));
const token = localStorage.getItem("access_token");
const rules = JSON.parse(localStorage.getItem("rules"));

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};


const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    btndelete: {
        fontSize: theme.spacing(2),
    },
}));
function OverviewStock(props) {

    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [inputs, setInputs] = useState([]);
    const [inputsDo, setInputsDo] = useState([]);
    const [inputsUpdate, setInputsUpdate] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openBarter, setOpenBarter] = React.useState(false);
    const [openDO, setOpenDO] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleCloseBarter = () => setOpenBarter(false);
    const handleCloseDO = () => setOpenDO(false);
    const [TipeKendaraan, setTipeKendaraan] = useState("");
    const [NoRangka, setNoRangka] = useState("");
    const [Warna, setWarna] = useState("");
    const [refreshDt, setRefresh] = useState();
    const [refreshDtOnhand, setRefreshOnhand] = useState();
    const [setDtBarter, setBarter] = useState([]);
    const [setDtDO, setDO] = useState([]);
    const [spvName, setSpv] = React.useState("");
    const [dataSpv, setDataSpv] = useState([]);

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/stock/list/spv`;
            try {
                const response = await axios.get(url);
                console.log(response);
                setDataSpv(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    const handleOpen = (eventValue) => {
        setOpen(true);
    };

    const handleOpenBarter = (event) => {
        setOpenBarter(true);
        setBarter(event);
        setInputs(values => ({
            ...values, 
            ["id_stock"]: event.id,
            ["kategori"]: event.kategori
        }));
    }

    const handleOpenDO = (event)    => {
        setOpenDO(true);
        setDO(event);
        setInputsDo(values => ({
            ...values, 
            ["stock"]: event.id,
            ["statusstock"]: "Regular",
        }));
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const handleChangeInput = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleChangeInputDo = (event) => {
        setInputsDo(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleChangeSpv = (event) => {
        setSpv(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    const [dtStock, setDtStock] = React.useState([]);
    const handleClickOpen = (event) => {
        console.log(event);
        setDtStock(event.row);
        setInputs(values => ({
            ...values, 
            ["id"]: event.row.id
        }));
        setOpen(true);
    }

    //=====  KATEROGI ONHAND  =========/
    const [dataOnhand, setDataOnhand] = useState([]);
    const [dataOnhand2, setDataOnhand2] = useState([]);
    const [pageOnhand, setPageOnhand] = React.useState(0);
    const [pageSizeOnhand, setPageSizeOnhand] = React.useState(25);

    const queryOptions = React.useMemo(
        () => ({
            pageOnhand,
            pageSizeOnhand,
        }),
        [pageOnhand, pageSizeOnhand]
    );

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/stock/list/onhand?page=${pageOnhand}&size=${pageSizeOnhand}`;
            try {
                const response = await axios.get(url);
                setDataOnhand(response.data.onhand);
                setDataOnhand2(response.data);

                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [pageOnhand, pageSizeOnhand, refreshDtOnhand]);

    const [rowCountStateOnhand, setRowCountStateOnhand] = React.useState(
        dataOnhand2?.allOnhand || 0
    );
    
    React.useEffect(() => {
        setRowCountStateOnhand((prevRowCountStateOnhand) =>
            dataOnhand2?.allOnhand !== undefined
                ? dataOnhand2?.allOnhand
                : prevRowCountStateOnhand
        );
    }, [dataOnhand2?.allOnhand, setRowCountStateOnhand]);

    const columns = [
        { field: "rrn", headerName: "RRN", minWidth: 150 },
        { field: "vin_code", headerName: "VIN CODE", minWidth: 100 },
        { field: "model", headerName: "Model", minWidth: 150 },
        { field: "type", headerName: "Type", minWidth: 150 },
        { field: "no_rangka", headerName: "No Rangka", minWidth: 150 },
        { field: "no_mesin", headerName: "No Mesin", minWidth: 150 },
        { field: "warna", headerName: "Warna", minWidth: 150 },
        { field: "no_do_tam", headerName: "No DO TAM", minWidth: 150 },
        { field: "tgl_do_tam", headerName: "Tgl DO TAM", minWidth: 150 },
        { field: "Umur DO", renderCell: (cellValues) => {
            return (
                <Chip
                    label={cellValues.row.umurDO}
                    color="primary"
                    variant="outlined"
                />
            )
        }, minWidth: 150 },
        { field: "tgl_dtg", headerName: "Tgl Dtg", minWidth: 150 },
        // { field: "lokasi", headerName: "Lokasi", minWidth: 150 },
        {
            field: "Lokasi",
            renderCell: (cellValues) => {
                return rules == "pdc" && cellValues.row.lokasi == null ? (
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={(event) => {
                            handleClickOpen(cellValues);
                        }}
                    >
                        Input Lokasi
                    </Button>
                ) : (
                    <Typography>{cellValues.row.lokasi}</Typography>
                );
            },
            minWidth: 150,
        },
        { field: "customer", headerName: "Customer", minWidth: 150 },
        { field: "tgl_alo", headerName: "Tgl Alo", minWidth: 150 },
        { field: "sales", headerName: "Sales", minWidth: 150 },
        { field: "no_do", headerName: "No. DO", minWidth: 150 },
        { field: "tgl_do", headerName: "Tgl DO", minWidth: 150 },
        { field: "umur_alok", headerName: "Umur Alok", minWidth: 150 },
        { field: "umur_ar", headerName: "Umur AR", minWidth: 150 },
        { field: "ket", headerName: "Keterangan", minWidth: 150 },
        { field: "tipe_stock", headerName: "Type Stock", minWidth: 150 }, //type stock : 
        {
            field: "Aksi",
            renderCell: (cellEdit) => {
                return rules != "pdc" ? (
                    <><Button
                        size="small"
                        variant="outlined"
                        color="warning"
                        startIcon={<EditIcon />}
                        onClick={(event) => {
                            handleOpenBarter(cellEdit.row);
                        } }
                    >
                        Barter
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={(event) => {
                            handleOpenDO(cellEdit.row);
                        } }
                    >
                            DO
                        </Button></>
                ) : "";
            },
            minWidth: 200,
        },
    ];

    //=====  KATEROGI BARTER CABANG  =========/
    const [dataBarterCab, setDataBarterCab] = useState([]);
    const [dataBarterCab2, setDataBarterCab2] = useState([]);
    const [pageBarterCab, setPageBarterCab] = React.useState(0);
    const [pageSizeBarterCab, setPageSizeBarterCab] = React.useState(25);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getDataBarterCabang = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/stock/list/bartercabang?page=${pageBarterCab}&size=${pageSizeBarterCab}`;
            try {
                const response = await axios.get(url);
                setDataBarterCab(response.data.bartercabang);
                setDataBarterCab2(response.data);

                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getDataBarterCabang();
    }, [pageBarterCab, pageSizeBarterCab, refreshDt]);

    const [rowCountStateBarterCab, setRowCountStateBarterCab] = React.useState(
        dataBarterCab2?.allBarterCab || 0
    );
    
    React.useEffect(() => {
        setRowCountStateBarterCab((prevRowCountStateBarterCab) =>
            dataBarterCab2?.allBarterCab !== undefined
                ? dataBarterCab2?.allBarterCab
                : prevRowCountStateBarterCab
        );
    }, [dataBarterCab2?.allBarterCab, setRowCountStateBarterCab]);

    const columnsBarterCabang = [
        { field: "rrn", headerName: "RRN", minWidth: 150 },
        { field: "vin_code", headerName: "VIN CODE", minWidth: 200 },
        { field: "model", headerName: "Model", minWidth: 150 },
        { field: "type", headerName: "Type", minWidth: 150 },
        { field: "no_rangka", headerName: "No Rangka", minWidth: 150 },
        { field: "no_mesin", headerName: "No Mesin", minWidth: 150 },
        { field: "warna", headerName: "Warna", minWidth: 150 },
        { field: "no_do_tam", headerName: "No DO TAM", minWidth: 150 },
        { field: "tgl_do_tam", headerName: "Tgl DO TAM", minWidth: 150 },
        { field: "tgl_dtg", headerName: "Tgl Dtg", minWidth: 150 },
        { field: "lokasi", headerName: "Lokasi", minWidth: 150 },
        { field: "customer", headerName: "Customer", minWidth: 150 },
        { field: "tgl_alo", headerName: "Tgl Alo", minWidth: 150 },
        { field: "sales", headerName: "Sales", minWidth: 150 },
        { field: "no_do", headerName: "No. DO", minWidth: 150 },
        { field: "tgl_do", headerName: "Tgl DO", minWidth: 150 },
        { field: "umur_alok", headerName: "Umur Alok", minWidth: 150 },
        { field: "umur_ar", headerName: "Umur AR", minWidth: 150 },
        { field: "ket", headerName: "Keterangan", minWidth: 150 },
        { field: "tipe_stock", headerName: "Type Stock", minWidth: 150 }, //type stock
    ];

    //=====  KATEROGI BARTER DEALER  =========/
    const [dataBarterDealer, setDataBarterDealer] = useState([]);
    const [dataBarterDealer2, setDataBarterDealer2] = useState([]);
    const [pageBarterDealer, setPageBarterDealer] = React.useState(0);
    const [pageSizeBarterDealer, setPageSizeBarterDealer] = React.useState(25);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getDataBarterDealer = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/stock/list/barterdealer?page=${pageBarterDealer}&size=${pageSizeBarterDealer}`;
            try {
                const response = await axios.get(url);
                setDataBarterDealer(response.data.barterdealer);
                setDataBarterDealer2(response.data);

                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getDataBarterDealer();
    }, [pageBarterDealer, pageSizeBarterDealer, refreshDt]);

    const [rowCountStateBarterDealer, setRowCountStateBarterDealer] = React.useState(
        dataBarterDealer2?.allBarterDealer || 0
    );
    
    React.useEffect(() => {
        setRowCountStateBarterDealer((prevRowCountStateBarterDealer) =>
            dataBarterDealer2?.allBarterDealer !== undefined
                ? dataBarterDealer2?.allBarterDealer
                : prevRowCountStateBarterDealer
        );
    }, [dataBarterDealer2?.allBarterDealer, setRowCountStateBarterDealer]);

    const columnsBarterDealer = [
        { field: "rrn", headerName: "RRN", minWidth: 150 },
        { field: "vin_code", headerName: "VIN CODE", minWidth: 200 },
        { field: "model", headerName: "Model", minWidth: 150 },
        { field: "type", headerName: "Type", minWidth: 150 },
        { field: "no_rangka", headerName: "No Rangka", minWidth: 150 },
        { field: "no_mesin", headerName: "No Mesin", minWidth: 150 },
        { field: "warna", headerName: "Warna", minWidth: 150 },
        { field: "no_do_tam", headerName: "No DO TAM", minWidth: 150 },
        { field: "tgl_do_tam", headerName: "Tgl DO TAM", minWidth: 150 },
        { field: "tgl_dtg", headerName: "Tgl Dtg", minWidth: 150 },
        { field: "lokasi", headerName: "Lokasi", minWidth: 150 },
        { field: "customer", headerName: "Customer", minWidth: 150 },
        { field: "tgl_alo", headerName: "Tgl Alo", minWidth: 150 },
        { field: "sales", headerName: "Sales", minWidth: 150 },
        { field: "no_do", headerName: "No. DO", minWidth: 150 },
        { field: "tgl_do", headerName: "Tgl DO", minWidth: 150 },
        { field: "umur_alok", headerName: "Umur Alok", minWidth: 150 },
        { field: "umur_ar", headerName: "Umur AR", minWidth: 150 },
        { field: "ket", headerName: "Keterangan", minWidth: 150 },
        { field: "tipe_stock", headerName: "Type Stock", minWidth: 150 }, //type stock 
    ];

    // KATEGORI BARTER OUT
    const [dataBarterOut, setDataBarterOut] = useState([]);
    const [dataBarterOut2, setDataBarterOut2] = useState([]);
    const [pageBarterOut, setPageBarterOut] = React.useState(0);
    const [pageSizeBarterOut, setPageSizeBarterOut] = React.useState(25);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getDataBarterOut = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/stock/list/barterout?page=${pageBarterOut}&size=${pageSizeBarterOut}`;
            try {
                const response = await axios.get(url);
                setDataBarterOut(response.data.barterout);
                setDataBarterOut2(response.data);

                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getDataBarterOut();
    }, [pageBarterOut, pageSizeBarterOut, refreshDt]);

    const [rowCountStateBarterOut, setRowCountStateBarterOut] = React.useState(
        dataBarterOut2?.allBarterOut || 0
    );
    
    React.useEffect(() => {
        setRowCountStateBarterOut((prevRowCountStateBarterOut) =>
            dataBarterOut2?.allBarterOut !== undefined
                ? dataBarterOut2?.allBarterOut
                : prevRowCountStateBarterOut
        );
    }, [dataBarterOut2?.allBarterOut, setRowCountStateBarterOut]);

    const columnsBarterOut = [
        { field: "rrn", headerName: "RRN", minWidth: 150 },
        { field: "vin_code", headerName: "VIN CODE", minWidth: 200 },
        { field: "model", headerName: "Model", minWidth: 150 },
        { field: "type", headerName: "Type", minWidth: 150 },
        { field: "no_rangka", headerName: "No Rangka", minWidth: 150 },
        { field: "no_mesin", headerName: "No Mesin", minWidth: 150 },
        { field: "warna", headerName: "Warna", minWidth: 150 },
        { field: "no_do_tam", headerName: "No DO TAM", minWidth: 150 },
        { field: "tgl_do_tam", headerName: "Tgl DO TAM", minWidth: 150 },
        { field: "tgl_dtg", headerName: "Tgl Dtg", minWidth: 150 },
        { field: "lokasi", headerName: "Lokasi", minWidth: 150 },
        { field: "customer", headerName: "Customer", minWidth: 150 },
        { field: "tgl_alo", headerName: "Tgl Alo", minWidth: 150 },
        { field: "sales", headerName: "Sales", minWidth: 150 },
        { field: "no_do", headerName: "No. DO", minWidth: 150 },
        { field: "tgl_do", headerName: "Tgl DO", minWidth: 150 },
        { field: "umur_alok", headerName: "Umur Alok", minWidth: 150 },
        { field: "umur_ar", headerName: "Umur AR", minWidth: 150 },
        { field: "ket", headerName: "Keterangan", minWidth: 150 },
        { field: "tipe_stock", headerName: "Type Stock", minWidth: 150 }, //type stock 
    ];

    const [allProspek, setAllProspek] = useState([]);

    const [loadButton, setLoadButton] = React.useState(false);
    const handleSubmit = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios.post('https://api-funeling.kidsbjm.com/api/stock/update/lokasi', inputs).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
                setRefreshOnhand(response);
                setOpen(false);
            }
        });
    };

    const handleUpdate = (event) => {
        event.preventDefault();
        axios.post('https://api-funeling.kidsbjm.com/api/stock/update', inputsUpdate).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setRefresh(response);
                setOpenBarter(false);
            }
        });
    };

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getAllData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/stock/export`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setAllProspek(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getAllData();
    }, []);

    const header = [
        "Tipe Kendaraan",
        "No Rangka",
        "Warna",
    ];

    const body3 = allProspek.map((data) => [
        data.tipekendaraan,
        data.norangka,
        data.warna,
    ]);

    const mynamefile = "Master Data Stock.csv";

    const kategoriStock = [
        {
            value: "bartercabang",
            label: "Antar Cabang",
        },
        {
            value: "barterdealer",
            label: "Antar Dealer",
        },
    ];

    const lokasiCabang = [
        {
            value: "WLD",
            label: "WLD",
        },
        {
            value: "WML",
            label: "WML",
        },
        {
            value: "WLP",
            label: "WLP",
        },
        {
            value: "WLS",
            label: "WLS",
        },
    ];

    const dataProgress = [
        {
            value: "1",
            label: "KTP",
        },
        {
            value: "2",
            label: "SPK",
        },
        {
            value: "3",
            label: "AJU AFI",
        },
        {
            value: "4",
            label: "REGISTER DO",
        },
        {
            value: "5",
            label: "DP LUNAS",
        },
        {
            value: "6",
            label: "PO KONTRAK ASLI",
        },
        {
            value: "7",
            label: "DONE",
        },
        {
            value: "8",
            label: "CANCEL",
        },
    ];

    const [setkategori, setkategoriStock] = React.useState("");
    const [setLokasi, setLokasiStock] = React.useState("");
    const [listStockKtg, setListStock] = React.useState("");
    const [chooseStock, setChooseStock] = React.useState("");

    const handleKtgStock = (event) => {
        getDataCab(event.target.value);
        setkategoriStock(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };
    const handleChooseStock = (event) => {
        setChooseStock(event.target.value);
        setInputs(values => ({...values, ["id_barter"]: event.target.value}));
    };
    const handleLokasiStock = (event) => {
        setLokasiStock(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    };

    function getDataCab(setkategori) {
        axios
            .get("https://api-funeling.kidsbjm.com/api/stock/list/findbykategori?kategori="+setkategori)
            .then((response) => {
                console.log(response);
                setListStock(response.data.data);
            });
    }

    const handleSubmitBarter = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios.post('https://api-funeling.kidsbjm.com/api/stock/process/barter', inputs).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!, Hubungi HO untuk proses selanjutnya.', "success", {
                    buttons: false,
                    timer: 5000,
                });
                setLoadButton(false);
                setRefreshOnhand(response);
                setRefresh(response);
                setOpenBarter(false);
                window.location.href = "/stock/swappingunit";
            }
        });
    };

    const handleSubmitDO = (event) => {
        setLoadButton(true);
        event.preventDefault();
        console.log(inputsDo)
        axios.post('https://api-funeling.kidsbjm.com/api/stock/deliveryorder/create', inputsDo).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
                setLoadButton(false);
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setLoadButton(false);
                setRefreshOnhand(response);
                setOpenDO(false);
                window.location.href = "/stock/list/dostock";
            }
        });
    };

    const steps = [
        {
            label: 'KTP',
            // description: <TextField
            //                 id="outlined-select-currency-native"
            //                 defaultValue=""
            //                 label=""
            //                 helperText="File KTP"
            //                 sx={{width: "100%"}}
            //                 onChange={handleChangeInput}
            //                 size= "small"
            //                 name="file_ktp"
            //                 type="file"
            //             >
            //             </TextField>,
            description: "",
        },
        {
            label: 'SPK',
            // description: <TextField
            //                 id="outlined-select-currency-native"
            //                 defaultValue=""
            //                 label=""
            //                 helperText="File SPK"
            //                 sx={{width: "100%"}}
            //                 onChange={handleChangeInput}
            //                 size= "small"
            //                 name="file_spk"
            //                 type="file"
            //              >
            //              </TextField>,
            description: "",
        },
        {
            label: 'AJU AFI',
            // description: <TextField
            //                 id="outlined-select-currency-native"
            //                 defaultValue=""
            //                 label=""
            //                 helperText="File AJU AFI"
            //                 sx={{width: "100%"}}
            //                 onChange={handleChangeInput}
            //                 size= "small"
            //                 name="file_aju_afi"
            //                 type="file"
            //              >
            //              </TextField>,
            description: "",
        },
        {
            label: 'REGISTER DO',
            // description: <TextField
            //                 id="outlined-select-currency-native"
            //                 defaultValue=""
            //                 label=""
            //                 helperText="File REGISTER DO"
            //                 sx={{width: "100%"}}
            //                 onChange={handleChangeInput}
            //                 size= "small"
            //                 name="file_register_do"
            //                 type="file"
            //             >
            //             </TextField>,
            description: "",
        },
        {
            label: 'DP Lunas',
            // description: <TextField
            //                 id="outlined-select-currency-native"
            //                 defaultValue=""
            //                 label=""
            //                 helperText="File DP Lunas"
            //                 sx={{width: "100%"}}
            //                 onChange={handleChangeInput}
            //                 size= "small"
            //                 name="file_dp_lunas"
            //                 type="file"
            //             >
            //             </TextField>,
            description: "",
        },
        {
            label: 'PO KONTRAK ASLI',
            // description: <TextField
            //                 id="outlined-select-currency-native"
            //                 defaultValue=""
            //                 label=""
            //                 helperText="File PO Kontrak Asli"
            //                 sx={{width: "100%"}}
            //                 onChange={handleChangeInput}
            //                 size= "small"
            //                 name="file_po_kontrak_asli"
            //                 type="file"
            //             >
            //             </TextField>,
            description: "",
        },
        {
            label: 'Status',
            description: `Done or Cancell`,
        },
    ];

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            
            <Alert>Halo, {person}! Berikut stock Hari ini ya.</Alert>

            {/* Kategori Onhand */}
            <br></br>
            <Box style={{}}>
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success"><Typography>ONHAND</Typography></Alert>
                <TableContainer style={{ height: 500, width: "100%" }}>
                    <DataGrid
                        rows={dataOnhand}
                        rowCount={rowCountStateOnhand}
                        loading={isLoading}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        page={pageOnhand}
                        pageSize={pageSizeOnhand}
                        paginationMode="server"
                        onPageChange={(newPage) => setPageOnhand(newPage)}
                        onPageSizeChange={(newPageSize) => setPageSizeOnhand(newPageSize)}
                        columns={columns}
                    />
                </TableContainer>
            </Box>
            
            {/* Kategori Barter Cabang */}
            <br></br>
            <Box style={{}}>
                <Alert icon={<CheckIcon fontSize="inherit" />}  severity="error"><Typography>FREE STOCK BARTER IN ANTAR CABANG</Typography></Alert>
                <TableContainer style={{ height: 500, width: "100%" }}>
                    <DataGrid
                        rows={dataBarterCab}
                        rowCount={rowCountStateBarterCab}
                        loading={isLoading}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        page={pageBarterCab}
                        pageSize={pageSizeBarterCab}
                        paginationMode="server"
                        onPageChange={(newPage) => setPageBarterCab(newPage)}
                        onPageSizeChange={(newPageSize) => setPageSizeBarterCab(newPageSize)}
                        columns={columnsBarterCabang}
                    />
                </TableContainer>
            </Box>

            {/* Kategori Barter Dealer */}
            <br></br>
            <Box style={{}}>
                <Alert icon={<CheckIcon fontSize="inherit" />}  severity="error"><Typography>FREE STOCK BARTER IN ANTAR DEALER</Typography></Alert>
                <TableContainer style={{ height: 500, width: "100%" }}>
                    <DataGrid
                        rows={dataBarterDealer}
                        rowCount={rowCountStateBarterDealer}
                        loading={isLoading}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        page={pageBarterDealer}
                        pageSize={pageSizeBarterDealer}
                        paginationMode="server"
                        onPageChange={(newPage) => setPageBarterDealer(newPage)}
                        onPageSizeChange={(newPageSize) => setPageSizeBarterDealer(newPageSize)}
                        columns={columnsBarterDealer}
                    />
                </TableContainer>
            </Box>

            {/* Kategori Barter Out */}
            <br></br>
            <Box style={{}}>
                <Alert icon={<CheckIcon fontSize="inherit" />}  severity="info"><Typography>BARTER OUT</Typography></Alert>
                <TableContainer style={{ height: 500, width: "100%" }}>
                    <DataGrid
                        rows={dataBarterOut}
                        rowCount={rowCountStateBarterOut}
                        loading={isLoading}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        page={pageBarterOut}
                        pageSize={pageSizeBarterOut}
                        paginationMode="server"
                        onPageChange={(newPage) => setPageBarterOut(newPage)}
                        onPageSizeChange={(newPageSize) => setPageBarterOut(newPageSize)}
                        columns={columnsBarterOut}
                    />
                </TableContainer>
            </Box>

            {/* Input Lokasi */}
            <Box style={{ width: "100%" }}>
        
                <form onSubmit={handleSubmit}>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        maxWidth="md"
                        fullWidth={fullWidth}
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        style={{ width: "100%" }}
                    >
                        <DialogTitle
                        style={{
                            backgroundColor: bgColors.Yellow,
                            marginBottom: "10px",
                            color: "#ffffff",
                        }}
                        >
                        {"Input Lokasi"}
                        </DialogTitle>
                        <DialogContent>
                        <Chip
                            label={"RRN: " + dtStock.rrn}
                            color="primary"
                            variant="outlined"
                        />
                        <Chip
                            label={"VIN Code: " + dtStock.vin_code}
                            color="primary"
                            variant="outlined"
                        />
                        <Chip
                            label={"Model: " + dtStock.model}
                            color="primary"
                            variant="outlined"
                        />
                        <Chip
                            label={"Type: " + dtStock.type}
                            color="primary"
                            variant="outlined"
                        />
                        <br></br>
                        <br></br>
                        <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                            <TextField
                                id="outlined-basic"
                                select
                                required
                                variant="outlined"
                                name="lokasi"
                                label="Lokasi"
                                onChange={handleLokasiStock}
                                sx={{width: "50%"}}
                                size="small"
                                helperText="Lokasi"
                            >
                                {lokasiCabang.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                name="ket_lokasi"
                                label="Keterangan Lokasi"
                                onChange={handleChangeInput}
                                sx={{width: "50%"}}
                                size="small"
                                helperText="Keterangan Lokasi"
                            />
                        </Box>
                        </DialogContent>
                        <DialogActions>
                        {loadButton === true ? 
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                            :  
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmit}
                                > 
                                    <Typography>Submit</Typography>
                                </Button>
                            }
                        <Button onClick={handleClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </form>

            </Box>

            {/* Form Barter */}
            <Box style={{ width: "100%" }}>
        
                <form onSubmit={handleSubmitBarter}>
                    <Dialog
                        open={openBarter}
                        TransitionComponent={Transition}
                        keepMounted
                        maxWidth="md"
                        fullWidth={fullWidth}
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        style={{ width: "100%" }}
                    >
                        <DialogTitle
                        style={{
                            backgroundColor: bgColors.Yellow,
                            marginBottom: "10px",
                            color: "#ffffff",
                        }}
                        >
                        {"Form Barter"}
                        </DialogTitle>
                        <DialogContent>
                        <Chip
                            label={"RRN: " + setDtBarter.rrn}
                            color="primary"
                            variant="outlined"
                        />
                        <Chip
                            label={"VIN Code: " + setDtBarter.vin_code}
                            color="primary"
                            variant="outlined"
                        />
                        <Chip
                            label={"Model: " + setDtBarter.model}
                            color="primary"
                            variant="outlined"
                        />
                        <Chip
                            label={"Type: " + setDtBarter.type}
                            color="primary"
                            variant="outlined"
                        />
                        <br></br>
                        <br></br>
                        <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                            <TextField
                                id="outlined-basic"
                                required
                                variant="outlined"
                                name="jenis_barter"
                                label="Jenis Barter"
                                onChange={handleKtgStock}
                                sx={{width: "100%"}}
                                size="small"
                                helperText="Jenis Barter"
                                select
                            >
                                {kategoriStock.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Box style={{ width: "100%" }} sx={{ marginBottom: 2 }}>
                            {listStockKtg !== "" ? (
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="Pilih Stock"
                                    label="Pilih Stock"
                                    onChange={handleChooseStock}
                                    sx={{width: "100%"}}
                                    size="small"
                                    helperText="Pilih Stock"
                                    select
                                >
                                {listStockKtg.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.model} - {option.no_rangka} - {option.no_mesin}
                                    </MenuItem>
                                ))}
                                </TextField>
                            ) : ""}
                        </Box>
                        </DialogContent>
                        <DialogActions>
                        {loadButton === true ? 
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" />
                                </Button>
                            :  
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmitBarter}
                                > 
                                    <Typography>Submit</Typography>
                                </Button>
                            }
                        <Button onClick={handleCloseBarter}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </Box>

            {/* Form DO */}
            <Box style={{ width: "100%" }}>
        
                <form onSubmit={handleSubmitDO}>
                    <Dialog
                        open={openDO}
                        TransitionComponent={Transition}
                        keepMounted
                        maxWidth="lg"
                        fullWidth={fullWidth}
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        style={{ width: "100%" }}
                    >
                        <DialogTitle
                            style={{
                                backgroundColor: bgColors.Yellow,
                                marginBottom: "10px",
                                color: "#ffffff",
                            }}
                            >
                            {"Form DO"}
                        </DialogTitle>
                        <DialogContent>
                        <Chip
                            label={"RRN: " + setDtDO.rrn}
                            color="primary"
                            variant="outlined"
                        />
                        <Chip
                            label={"VIN Code: " + setDtDO.vin_code}
                            color="primary"
                            variant="outlined"
                        />
                        <Chip
                            label={"Model: " + setDtDO.model}
                            color="primary"
                            variant="outlined"
                        />
                        <Chip
                            label={"Type: " + setDtDO.type}
                            color="primary"
                            variant="outlined"
                        />
                        <br></br>
                        <br></br>
                        <Box
                            style={{ width: "100%" }}
                            sx={{ marginBottom: 2 }}
                        >
                            <TextField
                                id="outlined-select-currency-native"
                                select
                                defaultValue=""
                                label="Pilih SPV"
                                helperText="Pilih SPV (optional)"
                                onChange={handleChangeSpv}
                                sx={{width: "50%"}}
                                size= "small"
                                name="spv"
                            >
                                {dataSpv.map((option) => (
                                    <MenuItem
                                        key={option.nama_spv}
                                        value={option.nama_spv}
                                    >
                                        {option.nama_spv}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                id="outlined-select-currency-native"
                                defaultValue=""
                                label="No DO"
                                helperText="No DO"
                                onChange={handleChangeInputDo}
                                sx={{width: "50%"}}
                                size= "small"
                                name="no_do"
                            >
                            </TextField>
                        </Box>
                        <Box
                            style={{ width: "100%" }}
                            sx={{ marginBottom: 2 }}
                        >   
                            <TextField
                                id="outlined-select-currency-native"
                                defaultValue=""
                                helperText="Tanggal DO"
                                onChange={handleChangeInputDo}
                                sx={{width: "50%"}}
                                size= "small"
                                name="tgl_do"
                                type="date"
                            >
                            </TextField>
                            <TextField
                                id="outlined-select-currency-native"
                                defaultValue=""
                                label="Customer"
                                helperText="Customer"
                                onChange={handleChangeInputDo}
                                sx={{width: "50%"}}
                                size= "small"
                                name="customer"
                                required
                            >
                            </TextField>
                        </Box>
                        <Box
                            style={{ width: "100%" }}
                            sx={{ marginBottom: 2 }}
                        >   
                            <TextField
                                id="outlined-select-currency-native"
                                defaultValue=""
                                label="Sales"
                                helperText="Sales"
                                onChange={handleChangeInputDo}
                                sx={{width: "50%"}}
                                size= "small"
                                name="sales"
                            ></TextField>
                            <TextField
                                id="outlined-select-currency-native"
                                defaultValue=""
                                label="keterangan"
                                helperText="keterangan"
                                onChange={handleChangeInputDo}
                                sx={{width: "50%"}}
                                size= "small"
                                name="keterangan"
                            >
                            </TextField>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            {/* <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.label}>
                                        <StepLabel
                                            optional={
                                                index === 6 ? (
                                                    <Typography variant="caption">Last step</Typography>
                                                ) : null
                                            }
                                        >
                                            {step.label}
                                        </StepLabel>
                                        <StepContent>
                                            <Typography>{step.description}</Typography>
                                            <Box sx={{ mb: 2 }}>
                                                <div>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                </Button>
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                                </div>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                            {activeStep === steps.length && (
                                <Paper square elevation={0} sx={{ p: 3 }}>
                                <Typography>All steps completed - you&apos;re finished</Typography>
                                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                    Reset
                                </Button>
                                </Paper>
                            )} */}
                            <TextField
                                id="outlined-select-currency-native"
                                defaultValue=""
                                label="Progress"
                                helperText="Pilih Progress"
                                onChange={handleChangeInputDo}
                                sx={{width: "100%"}}
                                size= "small"
                                name="progresstatus"
                                select
                            >
                                {dataProgress.map((option) => (
                                    <MenuItem
                                        key={option.label}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        </DialogContent>
                        <DialogActions>
                        {loadButton === true ? 
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                > 
                                    <CircularProgress size="1.2rem" color="inherit" /> &nbsp; Loading...
                                </Button>
                            :  
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmitDO}
                                > 
                                    <Typography>Submit</Typography>
                                </Button>
                            }
                        <Button onClick={handleCloseDO}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </Box>
        </Box>
            
        
    );
}

export default OverviewStock;