import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import { Container } from '@mui/material';
import { Bar } from "react-chartjs-2";

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
}));

function SpvOverview(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);

    const currUrl = window.location.href;
    const segment = currUrl.split("/");
    const spvname = segment[4];

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    // API List Data Prospek
    const [dataOverview, setDataOverview] = useState([]);
    const [dataOverview2, setDataOverview2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/spv/overview/${spvname}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataOverview(response.data.data.permonth);
                    setDataOverview2(response.data.data.percurdate);
                    setLoading(false);
                // } else {
                    // throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, bulan, tahun]);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography>
                <b>SPV - Overview : Team {decodeURI(spvname)}</b>
            </Typography>{" "}
            <br />
            <Container maxWidth="md">
                <b>Summary Bulan Ini</b>
                <Bar
                    datasetIdKey='id'
                    data={{
                        labels: dataOverview.map((val)=>val.harians.nama_spv),
                        datasets: [
                        {
                            id: 1,
                            label: 'Harians',
                            data: dataOverview.map((val)=>val.harians.total),
                            backgroundColor: 'rgba(255, 99, 132, 0.5)'
                        },
                        {
                            id: 2,
                            label: 'SPK',
                            data: dataOverview.map((val)=>val.spks.total),
                            backgroundColor: 'rgba(53, 162, 235, 0.5)'
                        },
                        {
                            id: 3,
                            label: 'DO',
                            data: dataOverview.map((val)=>val.dos.total),
                            backgroundColor: 'rgba(76, 175, 80, 1)'
                        },
                        ],
                    }}
                />
                <b>Summary Hari Ini</b>
                <Bar
                    datasetIdKey='id'
                    data={{
                        labels: dataOverview2.map((val)=>val.harians.nama_spv),
                        datasets: [
                        {
                            id: 1,
                            label: 'Harians',
                            data: dataOverview2.map((val)=>val.harians.total),
                            backgroundColor: 'rgba(255, 99, 132, 0.5)'
                        },
                        {
                            id: 2,
                            label: 'SPK',
                            data: dataOverview2.map((val)=>val.spks.total),
                            backgroundColor: 'rgba(53, 162, 235, 0.5)'
                        },
                        {
                            id: 3,
                            label: 'DO',
                            data: dataOverview2.map((val)=>val.dos.total),
                            backgroundColor: 'rgba(76, 175, 80, 1)'
                        },
                        ],
                    }}
                />
            </Container>
        </Box>
    );
}

export default SpvOverview;

if (document.getElementById("spvOverview")) {
    ReactDOM.render(<SpvOverview />, document.getElementById("spvOverview"));
}
