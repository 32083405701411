import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import swal from "sweetalert";
import Typography from "@mui/material/Typography";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { Container } from "@mui/material";
import Alert from '@mui/material/Alert';
import TableContainer from "@mui/material/TableContainer";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CSVLink } from "react-csv";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const person = JSON.parse(localStorage.getItem("person"));
const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    btndelete: {
        fontSize: theme.spacing(2),
    },
}));
function InputMasterData(props) {

    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const classes = useStyles();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);
    const [inputs, setInputs] = useState([]);
    const [inputsUpdate, setInputsUpdate] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleCloseUpdate = () => setOpenUpdate(false);
    const [TipeKendaraan, setTipeKendaraan] = useState("");
    const [NoRangka, setNoRangka] = useState("");
    const [Warna, setWarna] = useState("");

    const handleOpen = (eventValue) => {
        setOpen(true);
    };

    const handleOpen2 = (event) => {
        console.log(event);
        setOpenUpdate(true);
        setTipeKendaraan(event.tipekendaraan);
        setNoRangka(event.norangka);
        setWarna(event.warna);
        setInputsUpdate(values => ({
            ...values, 
            ["id"]: event.id,
            ["tipekendaraan"]: event.tipekendaraan,
            ["norangka"]: event.norangka,
            ["warna"]: event.warna,
        }));
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const handleChangeInput = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleChangeInputUpdate = (event) => {
        setInputsUpdate(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleChangeTipeKendaraan = (event) => {
        setTipeKendaraan(event.target.value);
        setInputsUpdate(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleChangeNoRangka = (event) => {
        setNoRangka(event.target.value);
        setInputsUpdate(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleChangeWarna = (event) => {
        setWarna(event.target.value);
        setInputsUpdate(values => ({...values, [event.target.name]: event.target.value}));
    }

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [refreshDt, setRefresh] = useState();

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/stock/alldata?page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") { 
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                    setLoading(false);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, refreshDt]);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );
    
    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const columns = [
        { field: "tipekendaraan", headerName: "Tipe", minWidth: 150 },
        { field: "norangka", headerName: "No Rangka", minWidth: 200 },
        { field: "warna", headerName: "Warna", minWidth: 150 },
        {
            field: "Update Stock",
            renderCell: (cellEdit) => {
                return (
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={(event) => {
                            handleOpen2(cellEdit.row);
                        }}
                    >
                        Update
                    </Button>
                );
                
            },
            minWidth: 150,
        },
    ];
    
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('https://api-funeling.kidsbjm.com/api/stock/create', inputs).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setRefresh(response);
                setOpen(false);
            }
        });
    };

    const handleUpdate = (event) => {
        event.preventDefault();
        axios.post('https://api-funeling.kidsbjm.com/api/stock/update', inputsUpdate).then(function(response){
            if (response.data.error == true) {
                swal("Error", 'Data tidak boleh kosong!', "error", {
                    buttons: false,
                    timer: 2000,
                });   
            } else {
                swal("Success", 'Data Berhasil disimpan!', "success", {
                    buttons: false,
                    timer: 2000,
                });
                setRefresh(response);
                setOpenUpdate(false);
            }
        });
    };

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getAllData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/stock/export`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setAllProspek(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getAllData();
    }, []);

    const header = [
        "Tipe Kendaraan",
        "No Rangka",
        "Warna",
    ];

    const body3 = allProspek.map((data) => [
        data.tipekendaraan,
        data.norangka,
        data.warna,
    ]);

    const mynamefile = "Master Data Stock.csv";

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            
            <Alert>Halo, {person} Ini Halaman Input Master Data Stock</Alert>
            <br></br>
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <Button variant="contained" onClick={handleOpen} color="primary" size="small" startIcon={<AddIcon />}> Tambah Stock </Button> <span> </span>
                <CSVLink data={body3} headers={header} filename={mynamefile}>
                    <Button variant="contained" color="success" size="small" startIcon={<FileDownloadIcon />}> Export Stock </Button> <span> </span>
                </CSVLink>
            </Box>
            <br></br>
            <Box style={{}}>
                <TableContainer style={{ height: 500, width: "100%" }}>
                    <DataGrid
                        rows={dataProspek}
                        rowCount={rowCountState}
                        loading={isLoading}
                        components={{ Toolbar: GridToolbar }}
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        page={page}
                        pageSize={pageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => setPage(newPage)}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={columns}
                    />
                </TableContainer>
            </Box>

            {/* Tambah Data */}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    style={{ width: "100%" }}
                >
                    <DialogTitle style={{backgroundColor:bgColors.Yellow, marginBottom:'5px', color:'#ffffff'}}>{"Input Data Stock"}</DialogTitle><br></br>
                    <DialogContent>
                        <form>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="Tipe Kendaraan"
                                    name="tipekendaraan"
                                    onChange={handleChangeInput}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    helperText="Tipe Kendaraan"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="No Rangka"
                                    name="norangka"
                                    onChange={handleChangeInput}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    helperText="No Rangka"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="Warna"
                                    name="warna"
                                    onChange={handleChangeInput}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    helperText="Warna"
                                />
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/* Update Stock  */}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={openUpdate}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    style={{ width: "100%" }}
                >
                    <DialogTitle style={{backgroundColor:bgColors.Yellow, marginBottom:'5px', color:'#ffffff'}}>{"Update Data Stock"}</DialogTitle><br></br>
                    <DialogContent>
                        <form>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="Tipe Kendaraan"
                                    value={TipeKendaraan}
                                    name="tipekendaraan"
                                    onChange={handleChangeTipeKendaraan}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    helperText="Tipe Kendaraan"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="No Rangka"
                                    name="norangka"
                                    value={NoRangka}
                                    onChange={handleChangeNoRangka}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    helperText="No Rangka"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="Warna"
                                    name="warna"
                                    value={Warna}
                                    onChange={handleChangeWarna}
                                    sx={{ width: "50%" }}
                                    size="small"
                                    helperText="Warna"
                                />
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={handleUpdate}
                        >
                            Update
                        </Button>
                        <Button onClick={handleCloseUpdate}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
        
    );
}

export default InputMasterData;