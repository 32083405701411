import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import Alert from '@mui/material/Alert';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import swal from 'sweetalert';
// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
}));

function InputKecamatan(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const [isLoading, setLoading] = React.useState(true);
    const [listPameran, setPameranState] = React.useState("all");
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);

    const [startdate, setStart] = React.useState(null);
    const [enddate, setEnd] = React.useState(null);
    const [opentambah, setOpenTambah] = React.useState(false);
    const [openedit, setOpenEdit] = React.useState(false);
    const [opendelete, setOpenDelete] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);

    const startpameran = formatDate(startdate);
    const endpameran = formatDate(enddate);
    const [refreshDt, setRefresh] = useState();
    const [inputs, setInputs] = useState([]);
    const handleChangeInput = (event) => {
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    const [inputsEdit, setInputsEdit] = useState([]);
    const handleChangeInputEditNamaKecamatan = (event) => {
        setNamaKecamatanEdit(event.target.value);
        setInputsEdit((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };
    const handleChangeInputEdit = (event) => {
        setInputsEdit((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };

    const handleOpenTambah = (event) => {
        setOpenTambah(true);
    }
    const handleClose = () => setOpenTambah(false);
    const handleCloseEdit = () => setOpenEdit(false);
    const handleCloseDelete = () => setOpenDelete(false);

    const [namaKecamatanEdit, setNamaKecamatanEdit] = React.useState("");
    const [alamat, setAlamat] = React.useState("");
    const [keterangan, setKeterangan] = React.useState("");
    
    const handleOpenEdit = (event) => {
        console.log(event);
        setNamaKecamatanEdit(event.nama_kecamatan);
        setInputsEdit((values) => ({
            ...values,
            ["id"]: event.id,
            ["nama_kecamatan"]: namaKecamatanEdit == "" ? event.nama_kecamatan : namaKecamatanEdit
        }));
        setOpenEdit(true);
    }

    const [inputsDelete, setInputsDelete] = useState([]);
    const handleOpenDelete = (event) => {
        setInputsDelete((values) => ({
            ...values,
            ["id"]: event.id,
        }));
        setOpenDelete(true);
    }

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    // API List Data Prospek
    const [dataOverview, setDataOverview] = useState([]);
    const [dataOverview2, setDataOverview2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [optPameran, setPameran] = useState([]);

    const handleChangePameran = (event) => {
        setPameranState(event.target.value);
    };

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/pameran`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    // response.data.data instanceof Object;
                    setPameran(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/markom/pameran/listkecamatan?page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataOverview(response.data.data);
                    setDataOverview2(response.data);
                    setLoading(false);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, refreshDt]);

    const [rowCountState, setRowCountState] = React.useState(
        dataOverview2?.totalAll || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataOverview2?.totalAll !== undefined
                ? dataOverview2?.totalAll
                : prevRowCountState
        );
    }, [dataOverview2?.totalAll, setRowCountState]);

    const columns = [
        { field: "nama_kecamatan", headerName: "Nama Kecamatan", minWidth: 200 },
        {
            field: "Aksi",
            renderCell: (cellEdit) => { 
                return <><Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={(event) => {
                        handleOpenEdit(cellEdit.row);
                    } }
                >
                    Edit
                </Button>
                <Button
                    size="small"
                    variant="outlined"
                    color="default"
                    startIcon={<DeleteIcon />}
                    onClick={(event) => {
                        handleOpenDelete(cellEdit.row);
                    } }
                >
                        Delete
                </Button></>
            },
            minWidth: 200,
        },
    ];
    const [loadButton, setLoadButton] = React.useState(false);
    const handleSubmit = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios
            .post("https://api-funeling.kidsbjm.com/api/markom/kecamatan/create", inputs)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                } else {
                    swal("Success", "Data Berhasil disimpan!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    window.location.reload();
                    setOpenTambah(false);
                    setRefresh(response);
                    setLoadButton(false);
                }
            });
    };
    const handleSubmitKecamatan = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios
            .post("https://api-funeling.kidsbjm.com/api/markom/kecamatan/update", inputsEdit)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                } else {
                    swal("Success", "Data Berhasil disimpan!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    window.location.reload();
                    setOpenEdit(false);
                    setRefresh(response);
                    setLoadButton(false);
                }
            });
    };
    const handleSubmitDelKecamatan = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios
            .post("https://api-funeling.kidsbjm.com/api/markom/kecamatan/delete", inputsDelete)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                } else {
                    swal("Success", "Data Berhasil dihapus!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    // window.location.reload();
                    setOpenDelete(false);
                    setRefresh(response);
                    setLoadButton(false);
                }
            });
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <Alert>
                    <Typography>
                        List Data Kecamatan
                    </Typography>
                </Alert>
            </Box>
            <br></br>
            <Box className="bg-white p-3 rounded-md shadow-lg mb-2">
                <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    startIcon={<EditIcon />}
                    onClick={handleOpenTambah}
                >
                    Tambah Kecamatan
                </Button>
            </Box>
            <br></br>
            <TableContainer style={{ height: 500, width: "100%" }} className="bg-white p-3 rounded-md shadow-lg mb-2">
                <DataGrid
                    rows={dataOverview}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer>
            {/* CREATE DATA USER  */}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={opentambah}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="sm"
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    style={{ width: "100%" }}
                >
                    <DialogTitle
                        style={{
                            backgroundColor: "#DC2626",
                            marginBottom: "5px",
                            color: "#ffffff",
                        }}
                    >
                        {"Input Kecamatan"}
                    </DialogTitle>
                    <DialogContent>
                        <form>
                            <Box style={{ width: "100%" }} sx={{ marginBottom: 2, marginTop: 2 }}>
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="nama_kecamatan"
                                    // label="Nama Kecamatan"
                                    onChange={handleChangeInput}
                                    style={{width: "100%", marginBottom: "2px"}}
                                    size="small"
                                    helperText="Nama Pameran"
                                />
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        {loadButton === true ? 
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                disabled
                            > 
                                <CircularProgress size="1.2rem" color="inherit" />
                            </Button>
                        :  
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        }
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/* Edit Pameran */}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={openedit}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="sm"
                    fullWidth={fullWidth}
                    onClose={handleCloseEdit}
                    aria-describedby="alert-dialog-slide-description"
                    style={{ width: "100%" }}
                >
                    <DialogTitle
                        style={{
                            backgroundColor: "#DC2626",
                            marginBottom: "5px",
                            color: "#ffffff",
                        }}
                    >
                        {"Update Kecamatan"}
                    </DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleSubmitKecamatan}>
                            <Box style={{ width: "100%" }} sx={{ marginBottom: 2, marginTop: 2 }}>
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="nama_kecamatan"
                                    label="Nama Kecamatan"
                                    value={namaKecamatanEdit}
                                    onChange={handleChangeInputEditNamaKecamatan}
                                    style={{width: "100%", marginBottom: "2px"}}
                                    size="small"
                                    helperText="Nama Kecamatan"
                                />
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        {loadButton === true ? 
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                disabled
                            > 
                                <CircularProgress size="1.2rem" color="inherit" />
                            </Button>
                        :  
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmitKecamatan}
                            >
                                Submit
                            </Button>
                        }
                        <Button onClick={handleCloseEdit}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/* Delete Pameran */}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={opendelete}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="sm"
                    fullWidth={fullWidth}
                    onClose={handleCloseEdit}
                    aria-describedby="alert-dialog-slide-description"
                    style={{ width: "100%" }}
                >
                    <DialogTitle
                        style={{
                            backgroundColor: "#DC2626",
                            marginBottom: "5px",
                            color: "#ffffff",
                        }}
                    >
                        {"Validasi Delete Kecamatan"}
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            {"Apakah anda yakin akan menghapus kecamatan ini ?"}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        {loadButton === true ? 
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                disabled
                            > 
                                <CircularProgress size="1.2rem" color="inherit" />
                            </Button>
                        :  
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmitDelKecamatan}
                            >
                                Ya
                            </Button>
                        }
                        <Button onClick={handleCloseDelete}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default InputKecamatan;
