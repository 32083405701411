import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import swal from "sweetalert";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { downloadExcel } from "react-export-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";

// Modal Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// Cards
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem("access_token");

const drawerWidth = 240;
var bgColors = {
    Default: "#81b71a",
    Blue: "#00B1E1",
    Cyan: "#37BC9B",
    Green: "#8CC152",
    Red: "#9e0000",
    Yellow: "#F6BB42",
};

const bulanlist = [
    {
        value: "1",
        label: "Januari",
    },
    {
        value: "2",
        label: "Februari",
    },
    {
        value: "3",
        label: "Maret",
    },
    {
        value: "4",
        label: "April",
    },
    {
        value: "5",
        label: "Mei",
    },
    {
        value: "6",
        label: "Juni",
    },
    {
        value: "7",
        label: "July",
    },
    {
        value: "8",
        label: "Agustus",
    },
    {
        value: "9",
        label: "September",
    },
    {
        value: "10",
        label: "Oktober",
    },
    {
        value: "11",
        label: "November",
    },
    {
        value: "12",
        label: "Desember",
    },
];

const tahunlist = [];
    
const tahunSaatIni = new Date().getFullYear();

for (let tahun = tahunSaatIni; tahun >= 2017; tahun--) {
    tahunlist.push({
        value: tahun.toString(),
        label: tahun.toString(),
    });
}

const listPaket = [
    {
        value: "EZ DEAL",
        label: "EZ DEAL",
    },
    {
        value: "SPEKTA",
        label: "SPEKTA",
    },
    {
        value: "REGULER",
        label: "REGULER",
    },
    {
        value: "LAIN-LAIN",
        label: "LAIN-LAIN",
    },
];

const listDp = [
    {
        value: "< 20%",
        label: "< 20%",
    },
    {
        value: "20% - <30%",
        label: "20% - <30%",
    },
    {
        value: "30% - <40%",
        label: "30% - <40%",
    },
    {
        value: ">40%",
        label: ">40%",
    },
];

const listPayment = [
    {
        value: "Cash",
        label: "Cash",
    },
    {
        value: "Credit",
        label: "Credit",
    }
];

const useStyles = makeStyles((theme) => ({
    headerimage: {
        width: theme.spacing(10),
        height: theme.spacing(5),
    },
    thead: {
        backgroundColors: bgColors.Cyan,
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    btnexport: {
        // marginLeft: theme.spacing(125),
        float: "right",
        marginBottom: theme.spacing(1),
        backgroundColors: bgColors.Green,
    },
    inputText: {
        width: "50%",
    },
}));

function InputDo(props) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    const [bulan, setBulan] = React.useState(month+1);
    const [tahun, setTahun] = React.useState(year);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(25);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [nmCustomer, setCustomer] = React.useState("");

    const handleChange = (event) => {
        setBulan(event.target.value);
    };
    const handleChange2 = (event) => {
        setTahun(event.target.value);
    };
    const handleCustomer = (event, value) => {
        const customername = value != null ? value.label : "";
        setCustomer(customername);
    };

    const queryOptions = React.useMemo(
        () => ({
            page,
            pageSize,
        }),
        [page, pageSize]
    );

    const isLoading = false;

    const [open, setOpen] = React.useState(false);
    const [nameSales, setNameSales] = React.useState();
    const [noSpk, setNoSpk] = React.useState();
    const [nameCustomer, setNameCustomer] = React.useState();
    const [nameIdp, setNameIdp] = React.useState();

    const handleClickOpen = (eventValue) => {
        setNameSales(eventValue.nama_sales);
        setNameCustomer(eventValue.nama_cust);
        setNameIdp(eventValue.idh);
        setNoSpk(eventValue.no_spk);
        setInputs((values) => ({
            ...values,
            ["idp"]: eventValue.idh,
        }));
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setInputs([]);
    };

    // API List Data Prospek
    const [dataCustomer, setDataCustomer] = useState([]);
    const [dataProspek, setDataProspek] = useState([]);
    const [dataProspek2, setDataProspek2] = useState([]);
    const [allProspek, setAllProspek] = useState([]);
    const [refreshDt, setRefresh] = useState();

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getDataCustomer = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/area/sales/list/do/customer?bulan=${bulan}&tahun=${tahun}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataCustomer(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getDataCustomer();
    }, [bulan, tahun]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getData = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/area/sales/list/do?bulan=${bulan}&tahun=${tahun}&customer=${nmCustomer}&page=${page}&size=${pageSize}`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataProspek(response.data.data);
                    setDataProspek2(response.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [page, pageSize, bulan, tahun, nmCustomer, refreshDt]);


    const [listLeasing, setDataLeasing] = useState([]);
    const [listPaketLeasing, setDataPaketLeasing] = useState([]);
    const [listDpRate, setDpRate] = useState([]);
    const [optLeasing, setLeasing] = React.useState([]);
    const [payment, setPayment] = React.useState([]);

    const handlePayment = (event) => {
        setPayment(event.target.value);
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const handleLeasing = (event) => {
        setLeasing(event.target.value);
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const handlePaketLeasing = (event) => {
        setDataPaketLeasing(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }
    const handleDpRate = (event) => {
        setDpRate(event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}));
    }

    // DATA LEASING
    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const getLeasing = async () => {
            const url = `https://api-funeling.kidsbjm.com/api/leasing/list/cabang`;
            try {
                const response = await axios.get(url);
                // if (response.statusText === "") {
                    setDataLeasing(response.data.data);
                // } else {
                //     throw new Error("Request failed");
                // }
            } catch (error) {
                console.log(error);
            }
        };
        getLeasing();
    }, []);

    const [rowCountState, setRowCountState] = React.useState(
        dataProspek2?.totalAll || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            dataProspek2?.totalAll !== undefined
                ? dataProspek2?.totalAll
                : prevRowCountState
        );
    }, [dataProspek2?.totalAll, setRowCountState]);

    const columns = [
        {
            field: "Proses DO",
            renderCell: (cellValues) => {
                return cellValues.row.jml == "Belum DO" ? (
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={(event) => {
                            handleClickOpen(cellValues);
                        }}
                    >
                        Proses
                    </Button>
                ) : (
                    <Stack spacing={1} alignItems="center">
                        <Stack direction="row" spacing={1}>
                            <Chip label="Sudah DEC" size="small" color="success" />
                        </Stack>
                    </Stack>
                );
            },
            minWidth: 150,
        },
        { field: "tgl", headerName: "Tanggal", minWidth: 150 },
        { field: "nama_cust", headerName: "Customer", minWidth: 200 },
        { field: "sumber", headerName: "Sumber Prospek", minWidth: 200 },
        { field: "alamat", headerName: "Alamat", minWidth: 200 },
        { field: "telp", headerName: "Telepon", minWidth: 200 },
        
    ];

    const handleClick = (values) => {
        console.log(values);
    };

    //Force Uppercase
    const [inputs, setInputs] = useState([]);
    const handleChangeInput = (event) => {
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value.toUpperCase(),
        }));
    };

    //Limit Nor Rangka
    const [noka, setNoka] = useState('');
    const handleChangeInputNoka = event => {
        const limit = 17;
        setNoka(event.target.value.slice(0, limit));
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value.toUpperCase(),
        }));
      };

    const handleChangeInputNoPol = event => {
        setInputs((values) => ({
            ...values,
            [event.target.name]: event.target.value.toUpperCase(),
        }));
    }
      
    
    //
    const [loadButton, setLoadButton] = React.useState(false);
    const handleSubmit = (event) => {
        setLoadButton(true);
        event.preventDefault();
        axios
            .post("https://api-funeling.kidsbjm.com/api/tdos/create", inputs)
            .then(function (response) {
                if (response.data.error == true) {
                    swal("Error", "Data tidak boleh kosong!", "error", {
                        buttons: false,
                        timer: 2000,
                    });
                    setLoadButton(false);
                } else {
                    swal("Success", "Data Berhasil disimpan!", "success", {
                        buttons: false,
                        timer: 2000,
                    });
                    // window.location.reload();
                    setLoadButton(false);
                    setOpen(false);
                    setRefresh(response);
                }
            });
    };

    const [asuransiOpt, setAsuransi] = React.useState([]);
    const [tipeAsuransiOpt, setTipeAsuransi] = React.useState([]);
    const allOption = useEffect(() => {
        axios
            .get("https://api-funeling.kidsbjm.com/api/area/sales/select/option")
            .then((response) => {
                setAsuransi(response.data.asuransi);
                setTipeAsuransi(response.data.tipe_asuransi);
            });
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
        >
            <Toolbar />
            <Typography className="bg-white p-3 rounded-md shadow-lg">
                <b>Input DEC</b>
            </Typography>{" "}
            <br />
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
            >
                <div className="bg-white p-3 rounded-md shadow-lg">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Bulan"
                        value={bulan}
                        style={{ float: "left" }}
                        onChange={handleChange}
                        helperText="Pilih Bulan"
                    >
                        {bulanlist.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Tahun"
                        value={tahun}
                        style={{ float: "left" }}
                        onChange={handleChange2}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Pilih Tahun"
                    >
                        {tahunlist.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dataCustomer}
                        onChange={handleCustomer}
                        renderInput={(params) => (
                            <TextField {...params} label="Customer" helperText="Pilih Customer" />
                        )}
                    />
                </div>
            </Box>
            {/* <TableContainer style={{ height: 500, width: "100%" }}>
                <DataGrid
                    rows={dataProspek}
                    rowCount={rowCountState}
                    loading={isLoading}
                    components={{ Toolbar: GridToolbar }}
                    rowsPerPageOptions={[25, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                />
            </TableContainer> */}
            <br></br>
            {nmCustomer != "" ? (
                <Box sx={{ flexGrow: 1 }}>
                    {dataProspek.map((datval) => [
                        <Card key={datval.id} sx={{  float:"left", width: "90%", margin: "0 auto", borderTop: "1px solid #e0e0e0", marginBottom: 2 }}>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                >
                                    {datval.nama_cust}
                                </Typography>
                                <Typography
                                    sx={{ fontSize: 10 }}
                                >
                                    No Telepon <b>{datval.telp}</b><br></br>
                                    <b>{datval.tipe}</b><br></br>
                                    {datval.tgl}<br></br>
                                    {datval.sumber}<br></br>
                                    {datval.alamat}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {datval.jml == "Belum DO" ? (
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={(event) => {
                                            handleClickOpen(datval);
                                        }}
                                    >
                                        Proses
                                    </Button>
                                ) : (
                                    <Stack
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                        >
                                            <Chip
                                                label="Sudah DEC "
                                                size="small"
                                                color="success"
                                            />
                                        </Stack>
                                    </Stack>
                                )}
                            </CardActions>
                        </Card>
                        ,
                    ])}
                </Box>
            ) : (
                ""
            )}
            <Box style={{ width: "100%" }}>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    style={{ width: "100%" }}
                >
                    <DialogTitle style={{backgroundColor:"#EA2027", marginBottom:'10px', color:'#ffffff'}}>{"Input DEC"}</DialogTitle>
                    <DialogContent>
                    <Chip label={'No.SPK: '+noSpk} color="primary"  variant="outlined" />
                    <Chip label={'Sales: '+nameSales} color="success"  variant="outlined" />
                    <Chip label={'Customer: '+nameCustomer} color="success"  variant="outlined" />

                        <br /><br />
                        <form>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    defaultValue={nameIdp}
                                    variant="outlined"
                                    name="idp"
                                    label="Idp"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="IDP"
                                    style={{ display: "none" }}
                                    hidden
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="tanggal_do"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    type="date"
                                    helperText="Tanggal DO"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="tanggal_ultah"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    type="date"
                                    helperText="Ulang Tahun"
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    label="Pekerjaan"
                                    name="pekerjaan"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Pekerjaan"
                                />
                                <TextField
                                    id="outlined-basic"
                                    required
                                    variant="outlined"
                                    name="no_rangka"
                                    label="Nomor Rangka"
                                    onChange={handleChangeInputNoka}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Nomor Rangka"
                                    value={noka}
                                />
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >   
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    defaultValue=""
                                    label="Pilih Asuransi"
                                    helperText="Pilih asuransi"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size= "small"
                                    name="asuransi"
                                >
                                    {asuransiOpt.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.nama_asuransi}
                                        >
                                            {option.nama_asuransi}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="Tipe Asuransi"
                                    name="tipe_asuransi"
                                    onChange={handleChangeInput}
                                    style={{
                                        width: "50%"
                                    }}
                                    size="small"
                                    helperText="Tipe Asuransi"
                                    select
                                >
                                    {tipeAsuransiOpt.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.tipe_name}
                                        >
                                            {option.tipe_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    defaultValue=""
                                    label="Pilih Leasing"
                                    helperText="Pilih Leasing"
                                    name="leasing"
                                    onChange={handleLeasing}
                                    className={classes.inputText}
                                    size= "small"
                                    required
                                >
                                    {listLeasing.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.nama_leasing}
                                        >
                                            {option.nama_leasing}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    value={listPaketLeasing}
                                    label="Paket Leasing"
                                    helperText="Pilih Paket Leasing"
                                    name="paket_leasing"
                                    onChange={handlePaketLeasing}
                                    className={classes.inputText}
                                    size= "small"
                                    required
                                >
                                    {listPaket.map((option) => (
                                        <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    value={listDpRate}
                                    label="DP Rate"
                                    helperText="Pilih DP Rate"
                                    name="dp_rate"
                                    onChange={handleDpRate}
                                    className={classes.inputText}
                                    size= "small"
                                    required
                                >
                                    {listDp.map((option) => (
                                        <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    value={payment}
                                    label="Tipe Pembayaran"
                                    helperText="Tipe Pembayaran"
                                    name="payment"
                                    onChange={handlePayment}
                                    className={classes.inputText}
                                    size= "small"
                                    required
                                >
                                    {listPayment.map((option) => (
                                        <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ marginBottom: 2 }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="tenor"
                                    label="Tenor"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Tenor"
                                    select
                                >
                                    <MenuItem
                                        key={1}
                                        value={"1 Tahun"}
                                    >
                                        {"1 Tahun"}
                                    </MenuItem>
                                    <MenuItem
                                        key={2}
                                        value={"2 Tahun"}
                                    >
                                        {"2 Tahun"}
                                    </MenuItem>
                                    <MenuItem
                                        key={3}
                                        value={"3 Tahun"}
                                    >
                                        {"3 Tahun"}
                                    </MenuItem>
                                    <MenuItem
                                        key={4}
                                        value={"4 Tahun"}
                                    >
                                        {"4 Tahun"}
                                    </MenuItem>
                                    <MenuItem
                                        key={5}
                                        value={"5 Tahun"}
                                    >
                                        {"5 Tahun"}
                                    </MenuItem>
                                    <MenuItem
                                        key={6}
                                        value={"6 Tahun"}
                                    >
                                        {"6 Tahun"}
                                    </MenuItem>
                                    <MenuItem
                                        key={7}
                                        value={"7 Tahun"}
                                    >
                                        {"7 Tahun"}
                                    </MenuItem>
                                </TextField>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="hobby"
                                    label="Hobby"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Hobby"
                                />
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="Keterangan"
                                    name="keterangan"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="Keterangan"
                                />
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="no_pol"
                                    label="No Polisi"
                                    onChange={handleChangeInput}
                                    className={classes.inputText}
                                    size="small"
                                    helperText="No Polisi"
                                />
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                        >
                            {loadButton === true ? (<CircularProgress size="1.2rem" color="inherit" />) : <Typography>Submit</Typography>}
                        </Button> */}
                        {loadButton === true ? 
                            <Button
                                size="large"
                                variant="contained"
                                color="secondary"
                                disabled
                            > 
                                <CircularProgress size="1.2rem" color="inherit" />
                            </Button>
                        :  
                            <Button
                                size="large"
                                variant="contained"
                                color="secondary"
                                onClick={handleSubmit}
                            > 
                                <Typography>Submit</Typography>
                            </Button>
                        }
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default InputDo;

if (document.getElementById("salesinputdo")) {
    ReactDOM.render(<InputDo />, document.getElementById("salesinputdo"));
}
